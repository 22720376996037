/* eslint-disable import/prefer-default-export */
import moment from 'moment';

export function integrations(
  state = {
    bozzuto: {
      progress: {
        active: false,
      },
      historyLoading: false,
      progressLoading: false,
      loading: false,
      current: {
        total: 0,
        errors: 0,
        updated: 0,
        exec: 0,
      },
      list: [],
      history: [],
      selected: {
        id: 0,
        details: [],
      },
    },
    g5: {
      list: [],
      selected: 0,
      updating: false,
      loading: false,
    },
    entrata: {
      list: [],
      selected: 0,
      updating: false,
      loading: false,
    },
    yardi: {
      list: [],
      credentials: [],
      selected: 0,
      updating: false,
      loading: false,
    },
    elements: {
      list: [],
      credentials: [],
      selected: 0,
      updating: false,
      loading: false,
    },
    realpage: {
      list: [],
      credentials: [],
      selected: 0,
      updating: false,
      loading: false,
    },
    rentsync: {
      list: [],
      credentials: [],
      selected: 0,
      updating: false,
      loading: false,
    },
    basicrentcafe: {
      list: [],
      credentials: [],
      selected: 0,
      updating: false,
      loading: false,
    },
    lead2lease: {
      list: [],
      updating: false,
      loading: false,
    },
    logs: {
      list: [],
      loading: false,
    },
    updating: false,
  },
  action,
) {
  switch (action.type) {
    case 'GET_BOZZUTO_OVERVIEW_SUCCESS':
      if (action.data.last) {
        const current = action.data.last;
        const details = JSON.parse(action.data.last.log);
        const from = moment(current.createdAt);
        const to = moment(current.updatedAt);
        return {
          ...state,
          bozzuto: {
            ...state.bozzuto,
            current: {
              ...state.bozzuto.current,
              id: current.id,
              total: current.total,
              errors: details.errorsCount,
              updated: current.total - details.errorsCount,
              exec: moment(to.diff(from)).format('m[m] s[s]'),
            },
            list: action.data.list,
          },
        };
      }
      return {
        ...state,
      };

    case 'GET_BOZZUTO_PROGRESS_REQUEST':
      return {
        ...state,
        bozzuto: {
          ...state.bozzuto,
          progressLoading: true,
        },
      };

    case 'GET_BOZZUTO_PROGRESS_SUCCESS':
      return {
        ...state,
        bozzuto: {
          ...state.bozzuto,
          progress: {
            ...action.data,
          },
          progressLoading: false,
        },
      };

    case 'GET_BOZZUTO_PROGRESS_FAILURE':
      return {
        ...state,
        bozzuto: {
          ...state.bozzuto,
          progressLoading: false,
        },
      };

    case 'GET_BOZZUTO_HISTORY_REQUEST':
      return {
        ...state,
        bozzuto: {
          ...state.bozzuto,
          historyLoading: true,
        },
      };

    case 'GET_BOZZUTO_HISTORY_SUCCESS':
      return {
        ...state,
        bozzuto: {
          ...state.bozzuto,
          history: action.data,
          historyLoading: false,
        },
      };

    case 'GET_BOZZUTO_HISTORY_FAILURE':
      return {
        ...state,
        bozzuto: {
          ...state.bozzuto,
          historyLoading: false,
        },
      };

    case 'SELECT_BATCH_DETAILS': {
      const batch = state.bozzuto.history.filter(
        (el) => el.batch === action.batch,
      )[0];

      return {
        ...state,
        bozzuto: {
          ...state.bozzuto,
          selected: {
            id: action.batch,
            details: batch.details,
          },
        },
      };
    }

    case 'BOZZUTO_CLOSE_SELECTED':
      return {
        ...state,
        bozzuto: {
          ...state.bozzuto,
          selected: {
            id: 0,
            details: [],
          },
        },
      };

    case 'UPDATE_BOZZUTO_BOTS_REQUEST':
      return {
        ...state,
        bozzuto: {
          ...state.bozzuto,
          loading: true,
        },
      };
    case 'UPDATE_BOZZUTO_BOTS_SUCCESS':
      return {
        ...state,
        bozzuto: {
          ...state.bozzuto,
          loading: false,
        },
      };
    case 'UPDATE_BOZZUTO_BOTS_FAILURE':
      return {
        ...state,
        bozzuto: {
          ...state.bozzuto,
          loading: false,
        },
      };

    case 'GET_INTEGRATION_OVERVIEW_REQUEST': {
      return {
        ...state,
        [action.data.integration]: {
          ...state[action.data.integration],
          loading: true,
        }
      };
    }

    case 'GET_INTEGRATION_OVERVIEW_SUCCESS': {
      return {
        ...state,
        [action.data.integration] : {
          ...state[action.data.integration],
          list: action.data.result,
          loading: false,
        },
      };
    }

    case 'UPDATE_INTEGRATION_BOT_REQUEST': {
      return {
        ...state,
        updating: action.data.tid,
        [action.data.integration]: {
          ...state[action.data.integration],
          updating: action.data.tid,
        },
      };
    }
    case 'UPDATE_INTEGRATION_BOT_SUCCESS': {
      return {
        ...state,
        updating: false,
        [action.data.integration]: {
          ...state[action.data.integration],
          list: state[action.data.integration].list.map((bot) => {
            if (bot.id === action.data.data.id) {
              bot = action.data.data;
            }
            return bot;
          }),
          updating: false,
        },
      };
    }
    case 'UPDATE_INTEGRATION_BOT_FAILURE': {
      return {
        ...state,
        updating: false,
        [action.data.integration]: {
          ...state[action.data.integration],
          updating: false,
        },
      };
    }

    case 'GET_G5_OVERVIEW_REQUEST':
      return {
        ...state,
      };
    case 'GET_G5_OVERVIEW_SUCCESS':
      return {
        ...state,
        g5: {
          ...state.g5,
          list: action.data,
        },
      };
    case 'GET_G5_OVERVIEW_FAILURE':
      return {
        ...state,
      };

    case 'SELECT_G5_COMPANY':
      return {
        ...state,
        g5: {
          ...state.g5,
          selected: state.g5.selected === action.id ? 0 : action.id,
        },
      };
    case 'UPDATE_G5_BOT_REQUEST':
      return {
        ...state,
        updating: action.tid,
        g5: {
          ...state.g5,
          updating: action.tid,
        },
      };

    case 'UPDATE_G5_BOT_SUCCESS':
      return {
        ...state,
        updating: false,
        g5: {
          ...state.g5,
          list: state.g5.list.map((bot) => {
            if (bot.id === action.data.id) {
              bot = action.data;
            }
            return bot;
          }),
          updating: false,
        },
      };
    case 'UPDATE_G5_BOT_FAILURE':
      return {
        ...state,
        g5: {
          ...state.g5,
          list: state.g5.list.map((bot) => {
            if (bot.id === action.data.id) {
              bot = action.data;
            }
            return bot;
          }),
          updating: false,
        },
      };
    case 'GET_ENTRATA_OVERVIEW_REQUEST':
      return {
        ...state,
      };
    case 'GET_ENTRATA_OVERVIEW_SUCCESS':
      return {
        ...state,
        entrata: {
          ...state.entrata,
          list: action.data,
        },
      };
    case 'GET_ENTRATA_OVERVIEW_FAILURE':
      return {
        ...state,
        entrata: {
          ...state.entrata,
          updating: false,
        },
      };
    case 'UPDATE_ENTRATA_BOT_REQUEST':
      return {
        ...state,
        entrata: {
          ...state.entrata,
          updating: action.tid,
        },
      };

    case 'UPDATE_ENTRATA_BOT_SUCCESS':
      return {
        ...state,
        entrata: {
          ...state.entrata,
          list: state.entrata.list.map((el) => {
            if (el.id === action.data.id) {
              return action.data;
            }
            return el;
          }),
          updating: false,
        },
      };
    case 'UPDATE_ENTRATA_BOT_FAILURE':
      return {
        ...state,
        entrata: {
          ...state.entrata,
          updating: false,
        },
      };
    case 'GET_YARDI_OVERVIEW_REQUEST':
      return {
        ...state,
      };
    case 'GET_YARDI_OVERVIEW_SUCCESS':
      return {
        ...state,
        yardi: {
          ...state.yardi,
          list: action.data,
        },
      };
    case 'GET_YARDI_OVERVIEW_FAILURE':
      return {
        ...state,
      };

    case 'GET_YARDI_CREDENTIALS_REQUEST':
      return {
        ...state,
      };
    case 'GET_YARDI_CREDENTIALS_SUCCESS':
      return {
        ...state,
        yardi: {
          ...state.yardi,
          credentials: action.data,
        },
      };
    case 'GET_YARDI_CREDENTIALS_FAILURE':
      return {
        ...state,
      };
    case 'UPDATE_YARDI_BOT_REQUEST':
      return {
        ...state,
        updating: action.tid,
        yardi: {
          ...state.yardi,
          updating: action.tid,
        },
      };

    case 'UPDATE_YARDI_BOT_SUCCESS':
      return {
        ...state,
        updating: false,
        yardi: {
          ...state.yardi,
          list: state.yardi.list.map((bot) => {
            if (bot.id === action.data.id) {
              bot = action.data;
            }
            return bot;
          }),
          updating: false,
        },
      };
    case 'UPDATE_YARDI_BOT_FAILURE':
      return {
        ...state,
        updating: false,
        yardi: {
          ...state.yardi,
          list: state.yardi.list.map((bot) => {
            if (bot.id === action.data.id) {
              bot = action.data;
            }
            return bot;
          }),
          updating: false,
        },
      };

    case 'UPDATE_REALPAGE_BOT_SUCCESS':
      return {
        ...state,
        updating: false,
        realpage: {
          ...state.realpage,
          list: state.realpage.list.map((bot) => {
            if (bot.id === action.data.id) {
              bot = action.data;
            }
            return bot;
          }),
          updating: false,
        },
      };
    case 'UPDATE_REALPAGE_BOT_FAILURE':
      return {
        ...state,
        updating: false,
        realpage: {
          ...state.realpage,
          list: state.realpage.list.map((bot) => {
            if (bot.id === action.data.id) {
              bot = action.data;
            }
            return bot;
          }),
          updating: false,
        },
      };

      case 'GET_REALPAGE_OVERVIEW_REQUEST':
        return {
          ...state,
        };
      case 'GET_REALPAGE_OVERVIEW_SUCCESS':
        return {
          ...state,
          realpage: {
            ...state.realpage,
            list: action.data,
          },
        };
      case 'GET_REALPAGE_OVERVIEW_FAILURE':
        return {
          ...state,
        };
  
      case 'GET_REALPAGE_CREDENTIALS_REQUEST':
        return {
          ...state,
        };
      case 'GET_REALPAGE_CREDENTIALS_SUCCESS':
        return {
          ...state,
          realpage: {
            ...state.realpage,
            credentials: action.data,
          },
        };
      case 'GET_REALPAGE_CREDENTIALS_FAILURE':
        return {
          ...state,
        };
      case 'UPDATE_REALPAGE_BOT_REQUEST':
        return {
          ...state,
          updating: action.tid,
          realpage: {
            ...state.realpage,
            updating: action.tid,
          },
        };
  
      case 'UPDATE_REALPAGE_BOT_SUCCESS':
        return {
          ...state,
          updating: false,
          realpage: {
            ...state.realpage,
            list: state.realpage.list.map((bot) => {
              if (bot.id === action.data.id) {
                bot = action.data;
              }
              return bot;
            }),
            updating: false,
          },
        };
      case 'UPDATE_REALPAGE_BOT_FAILURE':
        return {
          ...state,
          updating: false,
          realpage: {
            ...state.realpage,
            list: state.realpage.list.map((bot) => {
              if (bot.id === action.data.id) {
                bot = action.data;
              }
              return bot;
            }),
            updating: false,
          },
        };

      case 'UPDATE_BASICRENTCAFE_BOT_SUCCESS':
        return {
          ...state,
          updating: false,
          basicrentcafe: {
            ...state.basicrentcafe,
            list: state.basicrentcafe.list.map((bot) => {
              if (bot.id === action.data.id) {
                bot = action.data;
              }
              return bot;
            }),
            updating: false,
          },
        };
      case 'UPDATE_BASICRENTCAFE_BOT_FAILURE':
        return {
          ...state,
          updating: false,
          basicrentcafe: {
            ...state.basicrentcafe,
            list: state.basicrentcafe.list.map((bot) => {
              if (bot.id === action.data.id) {
                bot = action.data;
              }
              return bot;
            }),
            updating: false,
          },
        };
  
        case 'GET_BASICRENTCAFE_OVERVIEW_REQUEST':
          return {
            ...state,
          };
        case 'GET_BASICRENTCAFE_OVERVIEW_SUCCESS':
          return {
            ...state,
            basicrentcafe: {
              ...state.basicrentcafe,
              list: action.data,
            },
          };
        case 'GET_BASICRENTCAFE_OVERVIEW_FAILURE':
          return {
            ...state,
          };
  
        case 'GET_BASICRENTCAFE_CREDENTIALS_REQUEST':
          return {
            ...state,
          };
        case 'GET_BASICRENTCAFE_CREDENTIALS_SUCCESS':
          return {
            ...state,
            basicrentcafe: {
              ...state.basicrentcafe,
              credentials: action.data,
            },
          };
        case 'GET_BASICRENTCAFE_CREDENTIALS_FAILURE':
          return {
            ...state,
          };
        case 'UPDATE_BASICRENTCAFE_BOT_REQUEST':
          return {
            ...state,
            updating: action.tid,
            basicrentcafe: {
              ...state.basicrentcafe,
              updating: action.tid,
            },
          };
  
        case 'UPDATE_BASICRENTCAFE_BOT_SUCCESS':
          return {
            ...state,
            updating: false,
            basicrentcafe: {
              ...state.basicrentcafe,
              list: state.basicrentcafe.list.map((bot) => {
                if (bot.id === action.data.id) {
                  bot = action.data;
                }
                return bot;
              }),
              updating: false,
            },
          };
        case 'UPDATE_BASICRENTCAFE_BOT_FAILURE':
          return {
            ...state,
            updating: false,
            basicrentcafe: {
              ...state.basicrentcafe,
              list: state.basicrentcafe.list.map((bot) => {
                if (bot.id === action.data.id) {
                  bot = action.data;
                }
                return bot;
              }),
              updating: false,
            },
          };

      case 'GET_INTEGRATION_LOGS':
        return {
          ...state,
          logs: {
            ...state.logs,
            loading: true
          }
        }
      case 'GET_INTEGRATION_LOGS_SUCCESS':
        return {
          ...state,
          logs: {
            list: action.data.list,
            loading: false,
          }
        }
      case 'GET_INTEGRATION_LOGS_FAILURE':
        return {
          ...state,
          logs: {
            ...state.logs,
            loading: false,
          }
        }
    default:
      return {
        ...state,
      };
  }
}
