import { sendRequest } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function select(id) {
  const response = await sendRequest('GET', `${API_URL}/bots/${id}`);
  return response;
}

async function update(data) {
  const response = await sendRequest('PUT', `${API_URL}/bots/${data.id}`, data);
  return response;
}

async function info(id) {
  const response = await sendRequest('GET', `${API_URL}/bots/${id}/info`);
  return response;
}

async function watch(id, toggle) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/bots/${id}/watch/${toggle}`,
  );
  return response;
}

async function testEmbedded(bot) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/bots/${bot.id}/testEmbedded`,
  );
  return response;
}

async function login(bot) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/bots/${bot}/login`,
  );
  return response;
}

async function autoupdate(tid, status) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/bots/${tid}/autoupdate`,
    {status}
  )
  return response;
}

export const botService = {
  select,
  update,
  info,
  watch,
  testEmbedded,
  login,
  autoupdate
};
