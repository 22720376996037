import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  Button,
  FormGroup,
  Input,
  FormFeedback,
} from 'reactstrap';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import { history } from '../../_helpers/history';
import bg1 from '../../assets/utils/images/dropdown-header/abstract1.jpg';
import { authActions } from '../../_actions';

function GoogleAuthentication({
  qr,
  secret,
  verify,
  verified,
  user,
  logout,
  loading,
  loadingVerification,
  generate,
}) {
  const [token, setToken] = useState('');

  const proceed = () => {
    if (verified) {
      let userData = user;
      userData.gaToken = true;
      userData = JSON.stringify(user);
      localStorage.setItem('user', userData);
      history.push(`/${user.homepage}` || '/');
    }
  };

  return (
    <div>
      <Row style={{ marginLeft: 0, marginRight: 0, marginTop: 20 }}>
        <Col md={{ size: 5, offset: 4 }} lg={{ size: 4, offset: 4 }}>
          <Card className="card-shadow-primary profile-responsive card-border mb-3">
            <div className="dropdown-menu-header">
              <div className="dropdown-menu-header-inner bg-info">
                <div
                  className="menu-header-image opacity-2"
                  style={{
                    backgroundImage: `url(${bg1})`,
                  }}
                />
                <div className="menu-header-content btn-pane-right">
                  <div>
                    <h5 className="menu-header-title">Hello, {user.name}</h5>
                  </div>
                </div>
              </div>
            </div>
            <ListGroup flush>
              <ListGroupItem>
                <div className="widget-content">
                  <div className="text-center">
                    <h6>
                      <span className="pr-2">
                        To access this dashboard you need to activate 2fa with{' '}
                        <span>
                          <b className="text-success"> Google Authenticator</b>
                        </span>
                      </span>
                    </h6>
                    {!qr && !verified && (
                      <h6 className="widget-heading opacity-4">
                        To begin press Generate QR
                      </h6>
                    )}
                    {qr && !verified && (
                      <div>
                        <img alt="qr code" src={qr} />
                      </div>
                    )}
                    <LaddaButton
                      className="btn btn-primary btn-sm"
                      loading={loading}
                      onClick={generate}
                      data-style={ZOOM_OUT}
                    >
                      {!qr ? 'Generate ' : 'Refresh '}
                      QR Code
                    </LaddaButton>
                    <br />
                    <br />
                    {qr && !verified && (
                      <>
                        <FormGroup className="position-relative form-group">
                          <Input
                            type="text"
                            name="code"
                            className="form-control form-style"
                            autoComplete="off"
                            placeholder="Google Authenticator code here..."
                            invalid={false}
                            onChange={(e) => setToken(e.target.value)}
                          />
                          <FormFeedback>errors</FormFeedback>
                        </FormGroup>

                        <LaddaButton
                          className="btn btn-primary btn-sm"
                          loading={loadingVerification}
                          onClick={() => verify(secret, token)}
                          data-style={ZOOM_OUT}
                        >
                          Verify authenticator
                        </LaddaButton>
                      </>
                    )}

                    {verified && 'You can proceed to your homepage.'}
                    <br />
                  </div>
                </div>
              </ListGroupItem>
              <ListGroupItem className="p-0">
                <div className="grid-menu grid-menu-2col">
                  <Row className="no-gutters">
                    <Col sm="6">
                      <Button
                        className="btn-icon-vertical btn-square btn-transition"
                        outline
                        color="danger"
                        onClick={logout}
                      >
                        <i className="pe-7s-power btn-icon-wrapper btn-icon-lg mb-3">
                          {' '}
                        </i>
                        Logout
                      </Button>
                    </Col>
                    <Col sm="6">
                      <Button
                        className="btn-icon-vertical btn-square btn-transition"
                        outline
                        disabled={!verified}
                        color="primary"
                        onClick={proceed}
                      >
                        <i className="pe-7s-angle-right-circle btn-icon-wrapper btn-icon-lg mb-3">
                          {' '}
                        </i>
                        Proceed to homepage
                      </Button>
                    </Col>
                  </Row>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  qr: state.authentication.qrcode,
  secret: state.authentication.secret,
  verified: state.authentication.verified,
  user: state.authentication.user,
  loading: state.authentication.qrLoading,
  loadingVerification: state.authentication.qrLoading,
});

const actionCreators = {
  logout: authActions.logout,
  generate: authActions.generateQrCode,
  verify: authActions.verifyQrCode,
};

const ConnectedPage = connect(
  mapStateToProps,
  actionCreators,
)(GoogleAuthentication);

export default ConnectedPage;
