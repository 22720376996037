import { authService } from '../_services';
import { history } from '../_helpers/history';

export const authActions = {
  login,
  register,
  setRegisterError,
  setLoginErrors,
  generateQrCode,
  verifyQrCode,
  logout,
};

function login(email, password, remember_me, otp) {
  return (dispatch) => {
    dispatch({ type: 'LOGIN_REQUEST' });

    authService.login(email, password, remember_me, otp).then(
      (data) => {
        dispatch({ type: 'LOGIN_SUCCESS', user: data.user });
        localStorage.setItem('user', JSON.stringify(data.user));
        localStorage.setItem('accessToken', data.accessToken);
        if (data.rememberToken) {
          localStorage.setItem('rememberToken', data.rememberToken);
        }
        history.push(`/${data.user.homepage}` || '/');
      },
      (error) => {
        dispatch({ type: 'LOGIN_FAILURE', error });
      },
    );
  };
}

function register(newUser) {
  return (dispatch) => {
    dispatch({ type: 'REGISTER_REQUEST' });
    authService.register(newUser).then(
      (response) => {
        dispatch({ type: 'REGISTER_SUCCESS', message: response });
      },
      (error) => {
        dispatch({ type: 'REGISTER_FAILURE', error });
      },
    );
  };
}

function setRegisterError(target) {
  return (dispatch) => {
    dispatch({ type: 'SET_REGISTER_ERROR', data: target });
  };
}

function setLoginErrors(errors) {
  return (dispatch) => {
    dispatch({ type: 'SET_LOGIN_ERROR', data: errors });
  };
}

function logout() {
  return (dispatch) => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    dispatch({ type: 'LOGOUT_REQUEST' });
    authService.logout().then(
      (result) => {
        window.location.reload();
      },
      (error) => {
        window.location.reload();
      },
    );
  };
}

function generateQrCode() {
  return (dispatch) => {
    dispatch({ type: 'GENERATE_QR_CODE_REQUEST' });
    authService.generateQrCode().then(
      (result) => {
        dispatch({ type: 'GENERATE_QR_CODE_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'GENERATE_QR_CODE_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function verifyQrCode(secret, token) {
  return (dispatch) => {
    dispatch({ type: 'VERIFY_QR_CODE_REQUEST' });

    authService.verifyQrCode(secret, token).then(
      (result) => {
        dispatch({ type: 'VERIFY_QR_CODE_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'VERIFY_QR_CODE_FAILURE', error: error.message });
      },
    );
  };
}
