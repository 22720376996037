import { sendRequest } from '../_helpers/request.lib';

export const userService = {
    getAll,
    activate,
    deactivate,
    setHomepage,
}

const API_URL = process.env.REACT_APP_API_URL

async function getAll() {
    const response = await sendRequest('GET', API_URL + '/users');
    return response.users;
}

async function activate(id) {
    const response = await sendRequest('POST', API_URL + '/users/activate', { id: id })
    return response;
}

async function deactivate(id) {
    const response = await sendRequest('POST', API_URL + '/users/deactivate', { id: id });
    return response;
}

async function setHomepage(url) {
    const response = await sendRequest('POST', API_URL + '/users/homepage', { url });
    return response;
}