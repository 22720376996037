export function jobs(state = {
  list: [],
  getLoading: false,
}, action){
    switch (action.type) {
        case 'CREATE_REQUEST':
            return {
                ...state,
                creating: true
            };
        case 'CREATE_SUCCESS':
            return {
                ...state,
                creating: false,
                job: action.job
            };
        case 'CREATE_FAILURE':
            return {
                ...state,
                creating: false,
                error: action.error
            };


        case 'GET_JOBS_REQUEST':
          return {
            ...state,
            getLoading: true
          };
        case 'GET_JOBS_SUCCESS':
          return {
            ...state,
            list: action.jobs,
            getLoading: false,
          };
        case 'GET_JOBS_FAILURE':
          return { 
            ...state,
            error: action.error,
            getLoading: false,
          };
        

        case 'DELETE_JOB_REQUEST':
          return {
            ...state,
            list: state.list.map(job =>
                job.id === action.id
                ? { ...job, deleting: true }
                : job
            )
          };
        case 'DELETE_JOB_RECEIVED_FROM_SOCKET':
          return {
            ...state,
            list: state.list.filter(job => job.id !== action.id)
          }
        case 'DELETE_JOB_FAILURE':
          return {
            ...state,
            list: state.list.map(job => {
              if (job.id === action.id) {
                const { deleting, ...jobCopy } = jobs;
                return { ...jobCopy, deleteError: action.error };
              }
    
              return job;
            })
          };

        case 'RECEIVE_JOB_SOCKET': 
          return {
            ...state,
            list: [...state.list, action.job]
          }
        default:
          return state
      }
}