/* eslint-disable import/prefer-default-export */
const defaultState = {
  list: [],
  loading: false,
  actionLoading: false,
  finished: [],
};

export function sync(state = defaultState, action) {
  switch (action.type) {
    case 'GET_SYNC_LIST_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'GET_SYNC_LIST_SUCCESS':
      return {
        ...state,
        list: action.data,
        loading: false,
      };
    case 'GET_SYNC_LIST_FAILURE':
      return {
        ...state,
        loading: false,
      };

    case 'SYNC_BOT_REQUEST':
      return {
        ...state,
        actionLoading: true,
      };
    case 'SYNC_BOT_SUCCESS':
      return {
        ...state,
        actionLoading: false,
        finished: [...state.finished, action.tid],
      };
    case 'SYNC_BOT_FAILURE':
      return {
        ...state,
        actionLoading: false,
      };
    default:
      return state;
  }
}
