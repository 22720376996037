import { sendRequest } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function getAll() {
  const response = await sendRequest('GET', `${API_URL}/companies`);
  return response;
}

async function select(id) {
  const response = await sendRequest('GET', `${API_URL}/companies/${id}`);
  return response;
}

async function create(name) {
  const response = await sendRequest('POST', `${API_URL}/companies`, { name });
  return response;
}

async function sync() {
  const response = await sendRequest('GET', `${API_URL}/sync/companies`);
  return response;
}

async function assignMaintenance(companyId, userId) {
  const response = await sendRequest('POST', `${API_URL}/companies/assignMaintenance`, { companyId, userId });
  return response;
}

async function getMaintenanceUserCompanies(userId) {
  const response = await sendRequest('GET', `${API_URL}/companies/getUserCompanies/${userId || 0}`);
  return response;
}

export const companiesService = {
  getAll,
  select,
  create,
  sync,
  assignMaintenance,
  getMaintenanceUserCompanies,
};
