import React, {Fragment, Component} from "react";
import {connect} from 'react-redux';
import Slider from "react-slick";



import {Col, Alert, Row, Form, FormGroup, Label, Input, FormFeedback} from 'reactstrap';

import {Link} from 'react-router-dom'; 
import Isemail from 'isemail';
import {authActions} from '../../../_actions';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

class Register extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            name: '',
            password: '',
            confirmPassword: '',
            registered: false
        }

        this.change = this.change.bind(this);
        this.submit = this.submit.bind(this);
    }

    change(e){
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    async submit(e){
        e.preventDefault();
        let errors = {
            name: '',
            email: '',
            password: '',
            confirmPassword: ''
        };
        let error = 0;
        if(!this.state.email){
            errors.email = 'Email is required'
            error++;
        }else if(!Isemail.validate(this.state.email)){
            errors.email= 'Email is invalid'
            error++;
        }
        if(!this.state.name){
            errors.name= 'Name is required'
            error++;
        }
        if(this.state.password.length < 6){
            errors.password = 'Password needs to be at least 6 characters'
            error++;
        }else if(this.state.password !== this.state.confirmPassword){
            errors.confirmPassword = 'Passwords are not matching'
            error++;
        }
        this.props.setError(errors);
        if(!error){
            await this.props.register(this.state);
        }
    }

    render() {
        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            initialSlide: 0,
            autoplay: true,
            adaptiveHeight: true

        };
        return (

            <Fragment>
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="6" md="12" className="h-100 d-flex justify-content-center align-items-center bg-white">
                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                                <div className="app-logo"/>
                                <h4>
                                    <div>Welcome,</div>
                                    <span>It only takes a <span className="text-success">few seconds</span> to create your account</span>
                                </h4>
                                <div>
                                
                                    {this.props.registered && (<Alert color="success">
                                        You have been successfully registered. Before logging in, please wait for our admins to approve your account.
                                    </Alert>)}
                                    <Form onSubmit={this.submit} noValidate>
                                        <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="exampleEmail">
                                                        <span className="text-danger">*</span>
                                                        {' '}Email
                                                    </Label>
                                                    <Input type="email" name="email" id="exampleEmail" className="form-control form-style" autoComplete="email"
                                                           placeholder="Email here..."  onChange={this.change} invalid={ this.props.errors.email.length > 0 ? true : false }/>
                                                        <FormFeedback>{this.props.errors.email}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="exampleName">Name</Label>
                                                    <Input type="text" name="name" id="exampleName" className="form-control form-style" autoComplete="username"
                                                           placeholder="Name here..."  onChange={this.change}  invalid={ this.props.errors.name.length > 0 ? true : false }/>
                                                        <FormFeedback>{this.props.errors.name}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="examplePassword">
                                                        <span className="text-danger">*</span>
                                                        {' '}Password
                                                    </Label>
                                                    <Input type="password" name="password" id="examplePassword" className="form-control form-style" autoComplete="new-password"
                                                           placeholder="Password here..."  onChange={this.change} invalid={ this.props.errors.password.length > 0 ? true : false }/>
                                                        <FormFeedback>{this.props.errors.password}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="examplePasswordRep">
                                                        <span className="text-danger">*</span>
                                                        {' '}Repeat Password
                                                    </Label>
                                                    <Input type="password" name="confirmPassword" id="examplePasswordRep" className="form-control form-style" autoComplete="new-password"
                                                           placeholder="Repeat Password here..."  onChange={this.change} invalid={ this.props.errors.confirmPassword.length > 0 ? true : false }/>
                                                        <FormFeedback>{this.props.errors.confirmPassword}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="mt-4 d-flex align-items-center alredy">
                                            <h6 className="mb-0 alredy-h-a">
                                                Already have an account?{' '}
                                                <Link to="/login" className="sign-up">Sign in</Link>
                                            </h6>
                                            <div className="ml-auto">
                                                <LaddaButton className="btn btn-primary-custom btn-lg"
                                                         loading={this.props.registering}
                                                         data-style={ZOOM_OUT}
                                                >
                                                    Create Account
                                                </LaddaButton>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Col>
                        <Col lg="6" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                                          <div className="slide-img-bg-register"/>
			                              <div id="cloud-1"/>
										  <div id="cloud-2"/>
										  <div id="cloud-3"/>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    errors: state.registration.errors,
    registering: state.registration.registering,
    registered: state.registration.registered
})

const actionCreators = {
    setError: authActions.setRegisterError,
    register: authActions.register
}

const connectedLoginPage = connect(mapStateToProps, actionCreators)(Register);
export { connectedLoginPage as Register };