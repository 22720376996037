export function wizard(state={
    reset: false
}, action){

    switch (action.type) {
        case 'RESET_WIZARD':
            return {
                reset: true
            }
        case 'APPLY_WIZARD':
            return {
                reset: false
            }
        default:
            return state;
    }
}