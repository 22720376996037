import { userService } from '../_services';

export const userActions = {
  getAll,
  activate,
  deactivate,
  setHomepage,
};

function getAll() {
  return (dispatch) => {
    dispatch({ type: 'GETALL_USERS_REQUEST' });

    userService.getAll().then(
      (users) => {
        dispatch({ type: 'GETALL_USERS_SUCCESS', users: users });
      },
      (error) => {
        dispatch({ type: 'GETALL_USERS_FAILURE' });
      },
    );
  };
}

function activate(id) {
  return (dispatch) => {
    dispatch({ type: 'ACTIVATE_USER_REQUEST' });

    userService.activate(id).then(
      (result) => {
        dispatch({ type: 'ACTIVATE_USER_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'ACTIVATE_USER_FAILURE', error: error });
      },
    );
  };
}

function deactivate(id) {
  return (dispatch) => {
    dispatch({ type: 'DEACTIVATE_USER_REQUEST' });

    userService.deactivate(id).then(
      (result) => {
        dispatch({ type: 'DEACTIVATE_USER_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'DEACTIVATE_USER_FAILURE', error: error });
      },
    );
  };
}

function setHomepage(url) {
  return (dispatch) => {
    dispatch({ type: 'SET_HOMEPAGE_REQUEST' });
    userService.setHomepage(url).then(
      (result) => {
        dispatch({ type: 'SET_HOMEPAGE_SUCCESS ' });
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
      },
      (error) => {
        dispatch({ type: 'SET_HOMEPAGE_ERROR' });
      },
    );
  };
}
