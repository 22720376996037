export function users(state = {
  list: []
}, action) {
  switch (action.type) {
    case 'GETALL_USERS_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'GETALL_USERS_SUCCESS':
      return {
        ...state,
        loading: false,
        list: action.users
      };
    case 'GETALL_USERS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case 'DELETE_USERS_REQUEST':
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        list: state.list.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case 'DELETE_USERS_SUCCESS':
      // remove deleted user from state
      return {
        list: state.list.filter(user => user.id !== action.id)
      };
    case 'DELETE_USERS_FAILURE':
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        list: state.list.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };

    case 'ACTIVATE_USER_REQUEST':
      return {
        ...state,
        loadingActivate: true,
      }

    case 'ACTIVATE_USER_SUCCESS':
      return {
        ...state,
        loadingActivate: false,
        list: state.list.map(el => {
          return el.id === action.data.id ?
            action.data : el
        })
      }

    case 'ACTIVATE_USER_FAILURE':
      return {
        ...state,
        loadingActivate: false,
        error: action.error
      }

    case 'DEACTIVATE_USER_REQUEST':
      return {
        ...state,
        loadingDeactivate: true,
      }

    case 'DEACTIVATE_USER_SUCCESS':
      return {
        ...state,
        loadingDeactivate: false,
        list: state.list.map(el => {
          return el.id === action.data.id ?
            action.data : el
        })
      }

    case 'DEACTIVATE_USER_FAILURE':
      return {
        ...state,
        loadingDeactivate: false,
        error: action.error
      }
    default:
      return state
  }
}