export function registration(state = {
  errors: {
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  },
}, action) {
  switch (action.type) {
    case 'REGISTER_REQUEST':
      return { 
        ...state,
        registering: true 
      };
    case 'REGISTER_SUCCESS':
      return { 
        ...state,
        registering: false,
        registered: true,
        message: action.message 
      };
    case 'REGISTER_FAILURE':
      return {
        ...state,
        registering: false,
        errors: {
          ...state.errors,
          name: action.error.data.name || '',
          password: action.error.data.password || '',
          email: action.error.data.email || '',
        }
      };

    case 'SET_REGISTER_ERROR':
      return {
        ...state,
        errors: {
          name: action.data.name || '',
          email: action.data.email || '',
          password: action.data.password || '',
          confirmPassword: action.data.confirmPassword || '',
        }
      }
    default:
      return state
  }
}