import {sendRequest} from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function get() {
  return await sendRequest('GET', `${API_URL}/embedded/list`);
}

async function check(tid) {
  return await sendRequest('GET', `${API_URL}/embedded/check/${tid}`);
}

async function update(tid) {
  return await sendRequest('GET', `${API_URL}/embedded/update/${tid}`);
}

export const embeddedService = {
  get,
  check,
  update,
}