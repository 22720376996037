import { wpsService } from '../_services';

function getAll() {
  return (dispatch) => {
    dispatch({ type: 'GETALL_WPS_REQUEST' });

    wpsService.getAll().then(
      (res) => {
        dispatch({ type: 'GETALL_WPS_SUCCESS', res });
      },
      (error) => {
        dispatch({ type: 'GETALL_WPS_FAILURE', error });
      },
    );
  };
}

function create(name) {
  return (dispatch) => {
    dispatch({ type: 'CREATE_WP_REQUEST' });

    wpsService.create(name).then(
      (wp) => {
        dispatch({ type: 'CREATE_WP_SUCCESS', wp });
        dispatch({ type: 'ALERT_SUCCESS', message: 'Website Provider succesfully created.' });
      },
      (error) => {
        dispatch({ type: 'CREATE_WP_FAILURE', error });
        dispatch({ type: 'ALERT_SUCCESS', error });
      },
    );
  };
}

function update(name, id) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_WP_REQUEST' });

    wpsService.update(name, id).then(
      (result) => {
        dispatch({
          type: 'UPDATE_WP_SUCCESS',
          payload: {
            name,
            id,
          },
        });

        dispatch({ type: 'ALERT_SUCCESS', message: 'Website Provider updated succesfully.' });
      },
      (error) => {
        dispatch({ type: 'UPDATE_WP_FAILURE', error });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

/**
 * Delete a website provider actions
 *
 * @param {number} id Targets website provider with this id
 */
function destroy(id) {
  return (dispatch) => {
    dispatch({ type: 'DELETE_WP_REQUEST' });

    wpsService.destroy(id).then(
      (result) => {
        dispatch({ type: 'DELETE_WP_SUCCESS', id });
        dispatch({ type: 'ALERT_SUCCESS', message: 'Website Provider deleted succesfully.' });
      },
      (error) => {
        dispatch({ type: 'DELETE_WP_FAILURE', error });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

/**
 *
 * @param {string} name   Name of the definition
 * @param {integer} wpId  Assignes definition as child to this website provider
 */
function createDef(name, wpId) {
  return (dispatch) => {
    dispatch({ type: 'CREATE_WP_DEFINITION_REQUEST' });

    wpsService.createDef(name, wpId).then(
      (result) => {
        dispatch({ type: 'ALERT_SUCCESS', message: 'Website definition created succesfully.' });
        dispatch({ type: 'CREATE_WP_DEFINITION_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'CREATE_WP_DEFINITION_FAILURE', error });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

/**
 *
 * @param {integer} id Deletes definition with this id
 * @param {integer} wp_id Website provider id this definition belongs to
 */
function deleteDef(id, wp_id) {
  return (dispatch) => {
    dispatch({ type: 'DELETE_WP_DEFINITION_REQUEST' });

    wpsService.deleteDef(id).then(
      (result) => {
        dispatch({ type: 'DELETE_WP_DEFINITION_SUCCESS', data: { id, wp_id } });
        dispatch({ type: 'ALERT_SUCCESS', message: 'Website definition deleted succesfully.' });
      },
      (error) => {
        // dispatch({type: 'DELETE_WP_DEFINITION_SUCCESS', data: {id:}})
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

/**
 * List all definitions of 1 website provider
 * @param {integer} wpId Website provider id
 */
function listDefinitions(wpId) {
  return (dispatch) => {
    dispatch({ type: 'SELECT_WP_REQUEST' });

    wpsService.listDefinitions(wpId).then(
      (result) => {
        dispatch({ type: 'SELECT_WP_SUCCESS', data: result.wp });
      },
      (error) => {
        dispatch({ type: 'ALERT_ERROR', error });
        dispatch({ type: 'SELECT_WP_FAILURE' });
      },
    );
  };
}

export const wpsActions = {
  getAll,
  create,
  update,
  destroy,
  createDef,
  deleteDef,
  listDefinitions,
};