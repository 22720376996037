import { companiesService } from '../_services/companies.service';

function getAll() {
  return (dispatch) => {
    dispatch({ type: 'GETALL_COMPANIES_REQUEST' });

    companiesService.getAll().then(
      (result) => {
        dispatch({ type: 'GETALL_COMPANIES_SUCCESS', list: result.companies });
      },
      (error) => {
        dispatch({ type: 'GETALL_COMPANIES_FAILURE', error });
      },
    );
  };
}

function select(id) {
  console.log(id);
  return (dispatch) => {
    dispatch({ type: 'SELECT_COMPANY_REQUEST' });

    companiesService.select(id).then(
      (result) => {
        dispatch({ type: 'SELECT_COMPANY_SUCCESS', data: result.company });
      },
      (error) => {
        dispatch({ type: 'SELECT_COMPANY_FAILURE', error });
      },
    );
  };
}

function create(name) {
  return (dispatch) => {
    dispatch({ type: 'CREATE_COMPANY_REQUEST' });

    companiesService.create(name).then(
      (result) => {
        dispatch({ type: 'CREATE_COMPANY_SUCCESS', data: result.company });
      },
      (error) => {
        dispatch({ type: 'CREATE_COMPANY_FAILURE', error });
      },
    );
  };
}

function sync() {
  return (dispatch) => {
    dispatch({ type: 'SYNC_COMPANIES_REQUEST' });

    companiesService.sync().then(
      (result) => {
        dispatch({ type: 'SYNC_COMPANIES_SUCCESS' });
        if (result.wp) {
          dispatch({
            type: 'STICK_ALERT_SUCCESS',
            message: `Added ${result.wp} new website providers.`,
          });
        }
        if (result.companies) {
          dispatch({
            type: 'STICK_ALERT_SUCCESS',
            message: `Added ${result.companies} new companies.`,
          });
        }
        if (result.bots) {
          dispatch({
            type: 'STICK_ALERT_SUCCESS',
            message: `Added ${result.bots} new bots.`,
          });
        }
        if (!result.wp && !result.companies && !result.bots) {
          dispatch({
            type: 'ALERT_SUCCESS',
            message: 'No new data since last sync.',
          });
        } else {
          dispatch(getAll());
        }
      },
      (error) => {
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

/**
 * Assign maintenance user to all bots to a given company
 * @param {int} companyId Target company id
 * @param {int} user_id User to assign company bots to
 */
function assignMaintenance(companyId, userId) {
  return (dispatch) => {
    dispatch({ type: 'ASSIGN_MAINTENANCE_TO_COMPANY', data: companyId });
    companiesService.assignMaintenance(companyId, userId).then(
      (result) => {
        dispatch({
          type: 'ASSIGN_MAINTENANCE_TO_COMPANY_SUCCESS',
          data: companyId,
        });
      },
      (error) => {
        dispatch({ type: 'ALERT_ERROR', error });
        dispatch({
          type: 'ASSIGN_MAINTENANCE_TO_COMPANY_FAILURE',
          data: companyId,
        });
      },
    );
  };
}

function getMaintenanceUserCompanies(userId) {
  return (dispatch) => {
    dispatch({ type: 'GET_MAINTENANCE_USER_COMPANIES_REQUEST' });
    companiesService.getMaintenanceUserCompanies(userId).then(
      (result) => {
        dispatch({
          type: 'GET_MAINTENANCE_USER_COMPANIES_SUCCESS',
          data: result.data,
        });
      },
      (error) => {
        dispatch({ type: 'ALERT_ERROR', error });
        dispatch({
          type: 'GET_MAINTENANCE_USER_COMPANIES_FAILURE',
        });
      },
    );
  };
}

/**
 * Dispatch an action to reset set loading/updated companies arrays
 */
function reset() {
  return (dispatch) => {
    dispatch({ type: 'RESET_COMPANIES' });
  };
}

function getNextBotList(id) {
  return (dispatch) => {
    dispatch({ type: 'GET_NEXT_MAINTENANCE_COMPANIES_REQUEST' });

    companiesService.getNextMaintenanceCompanies(id).then(
      (result) => {
        dispatch({ type: 'GET_NEXT_MAINTENANCE_COMPANIES_SUCCESS' });
        console.log(result);
      },
      (err) => {
        dispatch({ type: 'GET_NEXT_MAINTENANCE_COMPANIES_FAILURE' });
      },
    );
  };
}

export const companyActions = {
  getAll,
  select,
  create,
  sync,
  assignMaintenance,
  getMaintenanceUserCompanies,
  reset,
  getNextBotList,
};
