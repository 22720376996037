import { integrationsService } from '../_services';
import {maintenanceActions} from './maintenance.actions'

export const integrationsActions = {
  bozzutoHistory,
  bozzutoProgress,
  bozzutoReceiveProgressSocket,
  bozzutoSelect,
  bozzutoCloseSelected,
  bozzutoUpdate,

  get,
  update,
  test,

  g5select,
  g5update,
  g5test,

  entrataUpdate,

  getYardiCredentials,
  yardiUpdate,

  getRealPageCredentials,
  realpageUpdate,

  basicRentCafeUpdate,
};

function bozzutoHistory() {
  return (dispatch) => {
    dispatch({ type: 'GET_BOZZUTO_HISTORY_REQUEST' });
    integrationsService.bozzutoHistory().then(
      (result) => {
        dispatch({ type: 'GET_BOZZUTO_HISTORY_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'GET_BOZZUTO_HISTORY_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function bozzutoProgress() {
  return (dispatch) => {
    dispatch({ type: 'GET_BOZZUTO_PROGRESS_REQUEST' });
    integrationsService.bozzutoProgress().then(
      (result) => {
        dispatch({ type: 'GET_BOZZUTO_PROGRESS_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'GET_BOZZUTO_PROGRESS_FAILURE' });
      },
    );
  };
}

function bozzutoReceiveProgressSocket(data) {
  return (dispatch) => {
    dispatch({ type: 'GET_BOZZUTO_PROGRESS_SUCCESS', data });
  };
}

function bozzutoSelect(batch) {
  return (dispatch) => {
    dispatch({
      type: 'SELECT_BATCH_DETAILS',
      batch,
    });
  };
}

function bozzutoUpdate() {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_BOZZUTO_BOTS_REQUEST' });
    integrationsService.bozzutoUpdate().then(
      (result) => {
        dispatch({ type: 'UPDATE_BOZZUTO_BOTS_SUCCESS' });
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
      },
      (error) => {
        dispatch({ type: 'UPDATE_BOZZUTO_BOTS_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function bozzutoCloseSelected() {
  return (dispatch) => {
    dispatch({ type: 'BOZZUTO_CLOSE_SELECTED' });
  };
}

function get(integration) {
  return (dispatch) => {
    dispatch({ type: 'GET_INTEGRATION_OVERVIEW_REQUEST', data: { integration } });

    integrationsService.get(integration).then(
      (result) => {
        dispatch({ type: 'GET_INTEGRATION_OVERVIEW_SUCCESS', data: { result, integration } });
      },
      (error) => {
        dispatch({ type: 'GET_INTEGRATION_OVERVIEW_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function update(integration, tid, fromMaintenance = false) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_INTEGRATION_BOT_REQUEST', data: { tid, integration } });
    integrationsService.update(integration, tid).then(
      (result) => {
        dispatch({ type: 'UPDATE_INTEGRATION_BOT_SUCCESS', data: { data: result.tenant, integration } });
        dispatch({ type: 'CAN_UNDO_FLOORPLANS' });
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
        if (fromMaintenance) {
          dispatch(maintenanceActions.getFloorplans(tid));
        }
      },
      (error) => {
        dispatch({ type: 'UPDATE_INTEGRATION_BOT_FAILURE', data: { data: error.data.details, integration } });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function test(integration, tid) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_INTEGRATION_BOT_REQUEST', data: { tid, integration } });

    integrationsService.test(integration, tid).then(
      (result) => {
        dispatch({ type: 'UPDATE_INTEGRATION_BOT_SUCCESS', data: { data: result.tenant, integration } });
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
      },
      (error) => {
        dispatch({ type: 'UPDATE_INTEGRATION_BOT_FAILURE', data: { data: error.data.details, integration } });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function g5select(id) {
  return (dispatch) => {
    dispatch({ type: 'SELECT_G5_COMPANY', id });
  };
}

function g5update(tid) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_G5_BOT_REQUEST', tid });

    integrationsService.g5update(tid).then(
      (result) => {
        dispatch({ type: 'UPDATE_G5_BOT_SUCCESS', data: result.tenant });
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
      },
      (error) => {
        dispatch({ type: 'UPDATE_G5_BOT_FAILURE', data: error.data.details });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function g5test(tid) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_G5_BOT_REQUEST', tid });

    integrationsService.g5test(tid).then(
      (result) => {
        dispatch({ type: 'UPDATE_G5_BOT_SUCCESS', data: result.tenant });
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
      },
      (error) => {
        dispatch({ type: 'UPDATE_G5_BOT_FAILURE', data: error.data.details });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function entrata() {
  return (dispatch) => {
    dispatch({ type: 'GET_ENTRATA_OVERVIEW_REQUEST' });

    integrationsService.get('entrata').then(
      (result) => {
        dispatch({ type: 'GET_ENTRATA_OVERVIEW_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'GET_ENTRATA_OVERVIEW_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function entrataUpdate(tid) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_ENTRATA_BOT_REQUEST', tid });

    integrationsService.entrataUpdate(tid).then(
      (result) => {
        dispatch({ type: 'UPDATE_ENTRATA_BOT_SUCCESS', data: result.tenant });
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
        dispatch(entrata());
      },
      (error) => {
        dispatch({ type: 'UPDATE_ENTRATA_BOT_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function getYardiCredentials(id) {
  return (dispatch) => {
    dispatch({ type: 'GET_YARDI_CREDENTIALS_REQUEST' });

    integrationsService.getYardiCredentials(id).then(
      (result) => {
        dispatch({ type: 'GET_YARDI_CREDENTIALS_SUCCESS', data: result.data });
      },
      (error) => {
        dispatch({ type: 'GET_YARDI_CREDENTIALS_FAILURE', error });
      },
    );
  };
}

function yardi() {
  return (dispatch) => {
    dispatch({ type: 'GET_YARDI_OVERVIEW_REQUEST' });

    integrationsService.get('yardi').then(
      (result) => {
        dispatch({ type: 'GET_YARDI_OVERVIEW_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'GET_YARDI_OVERVIEW_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function yardiUpdate(tid) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_YARDI_BOT_REQUEST', tid });

    integrationsService.yardiUpdate(tid).then(
      (result) => {
        dispatch({ type: 'UPDATE_YARDI_BOT_SUCCESS', data: result.tenant });
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
        dispatch(yardi());
      },
      (error) => {
        dispatch({
          type: 'UPDATE_YARDI_BOT_FAILURE',
          data: error.data.details,
        });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function getRealPageCredentials(id) {
  return (dispatch) => {
    dispatch({ type: 'GET_REALPAGE_CREDENTIALS_REQUEST' });

    integrationsService.getRealPageCredentials(id).then(
      (result) => {
        dispatch({ type: 'GET_REALPAGE_CREDENTIALS_SUCCESS', data: result.data });
      },
      (error) => {
        dispatch({ type: 'GET_REALPAGE_CREDENTIALS_FAILURE', error });
      },
    );
  };
}

function realpage() {
  return (dispatch) => {
    dispatch({ type: 'GET_REALPAGE_OVERVIEW_REQUEST' });

    integrationsService.get('realpage').then(
      (result) => {
        dispatch({ type: 'GET_REALPAGE_OVERVIEW_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'GET_REALPAGE_OVERVIEW_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function realpageUpdate(tid) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_REALPAGE_BOT_REQUEST', tid });

    integrationsService.realPageUpdate(tid).then(
      (result) => {
        dispatch({ type: 'UPDATE_REALPAGE_BOT_SUCCESS', data: result.tenant });
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
        dispatch(realpage());
      },
      (error) => {
        dispatch({
          type: 'UPDATE_REALPAGE_BOT_FAILURE',
          data: error.data.details,
        });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function basicRentCafe() {
  return (dispatch) => {
    dispatch({ type: 'GET_RENTCAFE_OVERVIEW_REQUEST' });

    integrationsService.get('rentcafe').then(
      (result) => {
        dispatch({ type: 'GET_RENTCAFE_OVERVIEW_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'GET_RENTCAFE_OVERVIEW_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function basicRentCafeUpdate(tid) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_RENTCAFE_BOT_REQUEST', tid });

    integrationsService.basicRentCafeUpdate(tid).then(
      (result) => {
        dispatch({ type: 'UPDATE_RENTCAFE_BOT_SUCCESS', data: result.tenant });
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
        dispatch(basicRentCafe());
      },
      (error) => {
        dispatch({
          type: 'UPDATE_RENTCAFE_BOT_FAILURE',
          data: error.data.details,
        });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}
