import { Route, Redirect } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import Loader from 'react-loaders';

import {
  ToastContainer,
} from 'react-toastify';
import PrivateRoute from '../../Pages/Components/PrivateRoute';
import AuthRoute from '../../Pages/Components/AuthRoute';

import { Login } from '../../Pages/Auth/Login';
import { Register } from '../../Pages/Auth/Register';
import ForgotPassword from '../../Pages/Auth/ForgotPassword';
import GoogleAuthentication from '../../Pages/GA/GoogleAuthentication';
import BotActions from '../../Pages/Components/Bots/Bots.ActionsDropdown';

const Core = lazy(() => import('../../Pages/Core'));
const Scraper = lazy(() => import('../../Pages/Scraper'));
const Management = lazy(() => import('../../Pages/Management'));
const Integrations = lazy(() => import('../../Pages/Integrations'));
const Settings = lazy(() => import('../../Pages/Settings/index.settings'));
const Reports = lazy(() => import('../../Pages/Reports/index.reports'));
const System = lazy(() => import('../../Pages/System'));

const AppMain = () => (
  <>
    <Suspense fallback={(
      <div className="loader-container">
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader type="ball-grid-cy" />
          </div>
          <h6 className="mt-3">
            Loading core pages
          </h6>
        </div>
      </div>
    )}
    >
      <PrivateRoute path="/core" component={Core} />
    </Suspense>

    <Suspense fallback={(
      <div className="loader-container">
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader type="ball-grid-cy" />
          </div>
          <h6 className="mt-3">
            Loading system pages
          </h6>
        </div>
      </div>
    )}
    >
    <PrivateRoute path="/system" component={System} />
    </Suspense>

    <Suspense fallback={(
      <div className="loader-container">
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader type="ball-grid-cy" />
          </div>
          <h6 className="mt-3">
            Loading integrations pages
          </h6>
        </div>
      </div>
    )}
    >
      <PrivateRoute path="/integrations" component={Integrations} />
    </Suspense>

    <Suspense fallback={(
      <div className="loader-container">
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader type="ball-grid-cy" />
          </div>
          <h6 className="mt-3">
            Loading
          </h6>
        </div>
      </div>
    )}
    >
      <PrivateRoute path="/scraper" component={Scraper} />
    </Suspense>

    <Suspense fallback={(
      <div className="loader-container">
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader type="ball-grid-cy" />
          </div>
          <h6 className="mt-3">
            Loading management pages
          </h6>
        </div>
      </div>
    )}
    >
      <PrivateRoute path="/management" component={Management} />
    </Suspense>

    <Suspense fallback={(
      <div className="loader-container">
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader type="ball-grid-cy" />
          </div>
          <h6 className="mt-3">
            Loading settings pages
          </h6>
        </div>
      </div>
    )}
    >
      <PrivateRoute path="/settings" component={Settings} />
    </Suspense>

    <Suspense fallback={(
      <div className="loader-container">
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader type="ball-grid-cy" />
          </div>
          <h6 className="mt-3">
            Loading reports pages
          </h6>
        </div>
      </div>
    )}
    >
      <PrivateRoute path="/reports" component={Reports} />
    </Suspense>

    <AuthRoute path="/login" component={Login} />
    <AuthRoute path="/register" component={Register} />
    <AuthRoute path="/forgot_password" component={ForgotPassword} />
    <PrivateRoute path="/ga" component={GoogleAuthentication} />

    <Route
      exact
      path="/"
      render={() => (
        <Redirect to="/core/onboarding" />
      )}
    />
    <ToastContainer />
    <BotActions />
  </>
);

export default AppMain;
