import {
  maintenanceService,
  floorplansService,
  integrationsService,
} from '../_services';

import { companyActions } from './company.actions';

function reset() {
  return (dispatch) => {
    dispatch({ type: 'RESET_CURRENT_MAINTENANCE' });
  };
}

function getMissedMaintenance() {
  return (dispatch) => {
    dispatch({ type: 'GET_BOTS_WITH_MISSED_MAINTENANCE_REQUEST' });
    maintenanceService.missedMaintenance().then(
      (res) => {
        dispatch({ type: 'GET_BOTS_WITH_MISSED_MAINTENANCE_SUCCESS', data: res.data });
      },
      (err) => {
        dispatch({ type: 'GET_BOTS_WITH_MISSED_MAINTENANCE_FAILURE' });
      }
    )
  }
}

function getMaintenanceList(userId, companyId) {
  return (dispatch) => {
    dispatch({ type: 'GET_MAINTENANCE_LIST_REQUEST' });

    maintenanceService.get(userId, companyId).then(
      (result) => {
        dispatch({ type: 'GET_MAINTENANCE_LIST_SUCCESS', data: result.bots });
        dispatch({ type: 'SET_WATCHED_BOTS_LIST', data: result.watched });
      },
      (error) => {
        dispatch({ type: 'GET_MAINTENANCE_LIST_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function select(tid) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({ type: 'SELECT_MAINTENANCE_REQUEST' });
    maintenanceService.select(tid).then(
      (result) => {
        if (result.integration === 'Scraper' && !result.central_availability) {
          dispatch({
            type: 'ALERT_WARNING',
            message:
              'Integration is Scraper but there is no central availability link.',
          });
        }
        dispatch({ type: 'SELECT_MAINTENANCE_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'SELECT_MAINTENANCE_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function lastUpdateDate(tid) {
  return (dispatch) => {
    dispatch(reset());
    maintenanceService.lastUpdateDate(tid).then(
      (result) => {},
      (error) => {
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function seenFp(id) {
  return (dispatch) => {
    dispatch({ type: 'SEEN_FP', id });
  };
}

function editBotInfo(data) {
  return (dispatch) => {
    dispatch({ type: 'EDIT_BOT_INFO_MAINTENANCE', data });
  };
}

function updateBotInfo(info) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_BOT_INFO_REQUEST' });
    const { website, central_availability, tid } = info;
    maintenanceService
      .updateBotInfo({ website, central_availability }, tid)
      .then(
        (result) => {
          dispatch({ type: 'UPDATE_BOT_INFO_SUCCESS' });
          dispatch({ type: 'ALERT_SUCCESS', message: result.message });
        },
        (error) => {
          dispatch({ type: 'ALERT_ERROR', error });
          dispatch({ type: 'UPDATE_BOT_INFO_FAILURE' });
        },
      );
  };
}

function updateDefaultSpecial(data) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_DEFAULT_MAINTENANCE_SPECIAL', text: data });
  };
}

function getSpecials(tid) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_GET_DEFAULT_SPECIAL_REQUEST' });
    maintenanceService.getSpecials(tid).then(
      (result) => {
        dispatch({
          type: 'MAINTENANCE_GET_DEFAULT_SPECIAL_SUCCESS',
          data: result,
        });
        dispatch({
            type: 'MAINTENANCE_GET_ALL_SPECIALS_SUCCESS',
            data: result.specials,
          });
        dispatch({
            type: 'MAINTENANCE_SET_ACTIVE_SPECIAL'
          });
      },
      (error) => {
        dispatch({ type: 'MAINTENANCE_GET_DEFAULT_SPECIAL_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function saveNote(tid, note) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_SAVE_NOTE_REQUEST' });
    maintenanceService.saveNote(tid, note).then(
      (result) => {
        dispatch({
          type: 'MAINTENANCE_SAVE_NOTE_SUCCESS',
          data: result,
        });
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
      },
      (error) => {
        dispatch({ type: 'MAINTENANCE_SAVE_NOTE_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function editNote(note) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_EDIT_NOTE', note });
  };
}

function editSpecial(field, value) {
  return (dispatch) => {
    dispatch({ type: 'EDIT_SPECIAL', data: { field, value } });
  };
}
function updateSpecial(data, tid) {
  return (dispatch) => {
      dispatch({ type: 'MAINTENANCE_TOGGLE_EDIT_SPECIAL_MODAL' });
      dispatch({ type: 'MAINTENANCE_TOGGLE_UPDATE_SPECIAL_LOADER' });
      maintenanceService.updateSpecials(data, tid).then(
          (result) => {
              dispatch({ type: 'UPDATE_ACTIVE_SPECIAL'});
              dispatch({ type: 'ALERT_SUCCESS', message: result.data.message });
              dispatch({ type: 'MAINTENANCE_TOGGLE_UPDATE_SPECIAL_LOADER' });
            },
        (error) => {
            dispatch({ type: 'ALERT_ERROR', error });
            dispatch({ type: 'MAINTENANCE_TOGGLE_UPDATE_SPECIAL_LOADER' });
        },
    );
  };
}

function saveSpecials(data, tid) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_SAVE_DEFAULT_SPECIAL_REQUEST' });
    maintenanceService.saveSpecials(data, tid).then(
      (result) => {
        dispatch({ type: 'MAINTENANCE_SAVE_DEFAULT_SPECIAL_SUCCESS' });
        dispatch({ type: 'ALERT_SUCCESS', message: result.data.message });
      },
      (error) => {
        dispatch({ type: 'MAINTENANCE_SAVE_DEFAULT_SPECIAL_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function addSpecial(data, tid) {
  return (dispatch) => {
    dispatch({ type: 'TOGGLE_CREATE_SPECIAL_MODAL_BUTTON' });
    maintenanceService.createSpecial(data, tid).then(
      () => {
        dispatch({ type: 'TOGGLE_CREATE_SPECIAL_MODAL_BUTTON' });
        dispatch({ type: 'ALERT_SUCCESS', message: 'Added new special.' });
        dispatch(select(tid));
        dispatch({ type: 'MAINTENANCE_CREATE_SPECIAL_SUCCESS', data: data });
        
    },
    (error) => {
        dispatch({ type: 'TOGGLE_CREATE_SPECIAL_MODAL_BUTTON' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function deleteSpecial(id, tid) {
  return (dispatch) => {
      dispatch({ type: 'MAINTENANCE_DELETE_SPECIAL_SUCCESS_OPTIMISTIC', id });
      maintenanceService.deleteSpecial(id, tid).then(
          () => {
            dispatch({ type: 'MAINTENANCE_DELETE_SPECIAL_SUCCESS', id });
            dispatch({ type: 'ALERT_SUCCESS', message: 'Special deleted.' });
            dispatch({ type: 'UPDATE_ACTIVE_SPECIAL'});
            dispatch({ type: 'MAINTENANCE_TOGGLE_LIST_ALL_SPECIALS_MODAL' });
      },
      (error) => {
          dispatch({ type: 'MAINTENANCE_DELETE_SPECIAL_FAILURE', id });
          dispatch({ type: 'ALERT_ERROR', error });

      },
    );
  };
}

function deleteSpecialModal(id, tid) {
  return (dispatch) => {
      dispatch({ type: 'MAINTENANCE_TOGGLE_DELETE_SPECIAL_MODAL' });
      maintenanceService.deleteSpecial(id, tid).then(
          () => {
            dispatch({type: 'MAINTENANCE_SET_ACTIVE_SPECIAL'});
            dispatch({ type: 'ALERT_SUCCESS', message: 'Special deleted.' });
            dispatch({ type: 'UPDATE_ACTIVE_SPECIAL'});
      },
      (error) => {
          dispatch({ type: 'MAINTENANCE_DELETE_SPECIAL_FAILURE', id });
          dispatch({ type: 'ALERT_ERROR', error });

      },
      
    );
  };
}

function saveDefaultSpecial(tid) {
  return (dispatch) => {
    maintenanceService.saveDefaultSpecial(tid).then(
      () => {
        dispatch({ type: 'ALERT_SUCCESS', message: 'Default special saved!' });
      },
      (error) => {
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function toggleSpecials(val) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_TOGGLE_SPECIALS', data: val });
  };
}

function toggleListAllSpecials(val) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_TOGGLE_LIST_ALL_SPECIALS_MODAL' });
  };
}

function toggleCreateSpecial(val) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_TOGGLE_CREATE_SPECIAL_MODAL' });
    dispatch({ type: 'MAINTENANCE_RESET_SPECIAL' });
  };
}

function toggleEditModal(id) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_TOGGLE_EDIT_SPECIAL_MODAL' });
    dispatch({ type: 'MAINTENANCE_SET_EDIT_ACTIVE_SPECIAL', data: {id: id} });
  };
}

function toggleDeleteModal(val) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_TOGGLE_DELETE_SPECIAL_MODAL' });
  };
}

function setNextBot() {
  return (dispatch) => {
    dispatch({ type: 'SET_NEXT_BOT' });
  };
}

function getFloorplans(tid) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_GET_FLOORPLANS_REQUEST' });

    floorplansService.get(tid).then(
      (result) => {
        dispatch({ type: 'MAINTENANCE_GET_FLOORPLANS_SUCCESS', list: result });
        dispatch(setNextBot());
      },
      (error) => {
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function toggleCreateModal(state) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_TOGGLE_CREATE', state });
  };
}

function createFloorplan(data, tid) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_FLOORPLAN_CREATE_REQUEST' });
    if (data.photo) {
      dispatch({ type: 'ALERT_WARNING', message: 'Uploading...' });
    }
    floorplansService.create(data, tid).then(
      (result) => {
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
        dispatch(getFloorplans(tid));
      },
      (error) => {
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function selectFloorplan(id) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_SELECT_FLOORPLAN', id });
  };
}

function moveFloorplan(dragIndex, hoverIndex) {
  return (dispatch) => {
    dispatch({
      type: 'MAINTENANCE_MOVE_FLOORPLAN',
      data: {
        dragIndex,
        hoverIndex,
      },
    });
  };
}

function saveReorder(fps, tid) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_SAVE_FLOORPLANS_REQUEST' });
    floorplansService.reorder(fps, tid).then(
      (result) => {
        dispatch({ type: 'MAINTENANCE_SAVE_FLOORPLANS_SUCCESS' });
        const { message } = result.data;
        dispatch({ type: 'ALERT_SUCCESS', message });
      },
      (error) => {
        dispatch({ type: 'MAINTENANCE_SAVE_FLOORPLANS_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function nextFloorplan() {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_SELECT_NEXT_FLOORPLAN' });
  };
}

function editFloorplan(id, name, value) {
  return (dispatch) => {
    dispatch({
      type: 'MAINTENANCE_EDIT_FLOORPLAN',
      data: {
        id,
        name,
        value,
      },
    });
  };
}

function updateFloorplan(tid, id, incData) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_UPDATE_FLOORPLAN_REQUEST' });
    const data = incData;
    const keys = Object.keys(data);
    let key;
    for (let i = 0; i < keys.length; i += 1) {
      key = keys[i];
      if (data[key] == null) {
        data[key] = '';
      }
    }
    if (typeof data.photo === 'object' && data.photo && data.photo !== 'null') {
      dispatch({ type: 'ALERT_WARNING', message: 'Uploading...' });
    }
    floorplansService.update(tid, id, data).then(
      (result) => {
        dispatch({ type: 'MAINTENANCE_UPDATE_FLOORPLAN_SUCCESS', data: id });
        dispatch(getFloorplans(tid));
        const { message } = result.data;
        dispatch({ type: 'ALERT_SUCCESS', message });
      },
      (error) => {
        dispatch({ type: 'MAINTENANCE_UPDATE_FLOORPLAN_FAILURE', data });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function destroyFloorplan(tid, id) {
  return (dispatch) => {
    dispatch({ type: 'MAINTENANCE_DESTROY_FLOORPLAN_REQUEST' });

    floorplansService.destroy(tid, id).then(
      (result) => {
        dispatch({ type: 'MAINTENANCE_DESTROY_FLOORPLAN_SUCCESS', id });
        const { message } = result.data;
        dispatch({ type: 'ALERT_SUCCESS', message });
      },
      (error) => {
        dispatch({ type: 'MAINTENANCE_DESTROY_FLOORPLAN_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function updateAllFloorplans(data, tid, undo = false) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_ALL_FLOORPLANS_MAINTENANCE_REQUEST' });

    floorplansService.sync(data, tid).then(
      (result) => {
        if (undo) {
          dispatch({ type: 'UNDO_FLOORPLANS_SUCCESS' });
        }
        dispatch({ type: 'UPDATE_ALL_FLOORPLANS_MAINTENANCE_SUCCESS' });
        const { message } = result.data;
        dispatch({ type: 'ALERT_SUCCESS', message });
        dispatch(getFloorplans(tid));
      },
      (error) => {
        dispatch({ type: 'UPDATE_ALL_FLOORPLANS_MAINTENANCE_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function resetProperty() {
  return (dispatch) => {
    dispatch({ type: 'RESET_MAINTENANCE_PROPERTY' });
  };
}

function g5update(tid) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_G5_BOT_REQUEST', tid });

    integrationsService.g5update(tid).then(
      (result) => {
        dispatch({ type: 'UPDATE_G5_BOT_SUCCESS', data: result.tenant });
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
        dispatch(getFloorplans(tid));
      },
      (error) => {
        dispatch({ type: 'UPDATE_G5_BOT_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function setGetNotified(val) {
  return (dispatch) => {
    dispatch({ type: 'SET_ALL_GET_NOTIFIED', data: val });
  };
}

function selectUser(id) {
  return async (dispatch) => {
    dispatch({ type: 'SELECT_MAINTENANCE_USER', id });
    dispatch(companyActions.getMaintenanceUserCompanies(id));
    dispatch({ type: 'SELECT_MAINTENANCE_COMPANY', id: '' });
    dispatch({ type: 'RESET_MAINTENANCE_LIST' });
  };
}

function selectCompany(userId, id) {
  return (dispatch) => {
    dispatch({ type: 'RESET_MAINTENANCE_LIST' });
    dispatch({ type: 'SELECT_MAINTENANCE_COMPANY', id });
    if (id) {
      dispatch({ type: 'GET_MAINTENANCE_LIST_REQUEST' });
      maintenanceService.get(userId, id).then(
        (result) => {
          dispatch({ type: 'GET_MAINTENANCE_LIST_SUCCESS', data: result.bots });
        },
        (error) => {
          dispatch({ type: 'GET_MAINTENANCE_LIST_FAILURE' });
          dispatch({ type: 'ALERT_ERROR', error });
        },
      );
    }
  };
}

function selectNextCompany(userId, id) {
  return (dispatch) => {
    maintenanceService.get(userId, id).then(
      (result) => {
        dispatch({ type: 'GET_NEXT_COMPANY_LIST_SUCCESS', data: result.bots });
      },
      (error) => {
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function resetSelectedFps() {
  return (dispatch) => {
    dispatch({ type: 'RESET_MAINTENANCE_FPS' });
  };
}

function removeCurrentList() {
  return (dispatch) => {
    dispatch({ type: 'REMOVE_CURRENT_BOT_LIST' });
  };
}

function updateIntegration(tid, integration, currentIntegration = false) {
  return (dispatch, state) => {
    let intStr = integration;
    let shouldUpdate = false;
    if (!integration && currentIntegration == 'G5') {
      intStr = currentIntegration;
      shouldUpdate = true;
    }
    dispatch({ type: 'UPDATE_INTEGRATION_REQUEST' });

    maintenanceService.updateIntegration(tid, intStr, shouldUpdate).then(
      (result) => {
        dispatch(select(tid));
      },
      (error) => {
        dispatch({ type: 'UPDATE_INTEGRATION_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

export const maintenanceActions = {
  reset,
  saveNote,
  lastUpdateDate,
  editNote,
  getSpecials,
  editSpecial,
  setGetNotified,
  getMaintenanceList,
  select,
  updateDefaultSpecial,
  saveSpecials,
  addSpecial,
  deleteSpecial,
  saveDefaultSpecial,
  toggleSpecials,
  editBotInfo,
  updateBotInfo,
  getFloorplans,
  toggleCreateModal,
  createFloorplan,
  selectFloorplan,
  moveFloorplan,
  saveReorder,
  editFloorplan,
  nextFloorplan,
  updateFloorplan,
  destroyFloorplan,
  seenFp,
  updateAllFloorplans,
  resetProperty,
  g5update,
  selectUser,
  selectCompany,
  selectNextCompany,
  resetSelectedFps,
  removeCurrentList,
  updateIntegration,
  getMissedMaintenance,
  toggleListAllSpecials,
  toggleCreateSpecial,
  toggleDeleteModal,
  toggleEditModal,
  updateSpecial,
  deleteSpecialModal,
};
