import React from 'react';
import {connect} from 'react-redux';
import {Nav, NavItem, NavLink} from "reactstrap";
import {botDetailsActions, botActions} from "../../../_actions";
import OutsideClickHandler from 'react-outside-click-handler';

function ActionsDropdown({tid, data, position, hide, login, loginLoading, watchBot, autoupdate}) {
  const handleWatch = () => {
    hide();
    watchBot(data.id, 1);
  }
  return (
    <>
      { tid ? (
        <OutsideClickHandler
          onOutsideClick={hide}
        >
        <div className={"actions-container"} style={{left: position.left, top: position.top}}>
          <Nav vertical>
            <NavItem className="nav-item-header">
              {data.name}
            </NavItem>
            <NavItem>
              <NavLink target={"_blank"} href={`#/core/maintenance/sync/${tid}`} onClick={hide}>
                <i className="nav-link-icon pe-7s-science"> </i>
                <span>Maintenance</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink target={"_blank"} href={`#/management/companies/${data.Company.id}/bots/${data.id}/edit`} onClick={hide}>
                <i className="nav-link-icon pe-7s-pen"> </i>
                <span>Edit</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={`#`} onClick={handleWatch}>
                <i className="nav-link-icon pe-7s-target"> </i>
                <span>Watch</span>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item-header">
              Actions
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={() => login(tid)} disabled={loginLoading}>
                <i className="nav-link-icon pe-7s-right-arrow"> </i>
                <span>Login</span>
              </NavLink>
            </NavItem>
            <NavItem >
              <NavLink
                href="#"
                disabled={!data.integration || data.integration.toLowerCase() == 'none'}
                onClick={() => {
                  hide()
                  autoupdate(tid, !data.autoUpdate)
                }}>
                {
                  data.autoUpdate ? (
                    <>
                      <i className="nav-link-icon pe-7s-close-circle"> </i>
                      <span>Stop Autoupdate</span>
                    </>
                  ): (
                    <>
                      <i className="nav-link-icon pe-7s-check"> </i>
                      <span>Start Autoupdate</span>
                    </>
                  )
                }
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={hide}>
                <i className="nav-link-icon pe-7s-close-circle"> </i>
                <span>Close</span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        </OutsideClickHandler>
      ) : (<></>)
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  tid: state.botActions.tid,
  data: state.botActions.data,
  position: state.botActions.position,
  loginLoading: state.bot.loginLoading,
});

const actions = {
  hide: botDetailsActions.clearActions,
  login: botActions.login,
  watchBot: botActions.watch,
  autoupdate: botActions.autoupdates
}

export default connect(mapStateToProps, actions)(ActionsDropdown);
