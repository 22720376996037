/* eslint-disable import/prefer-default-export */
export function companies(
  state = {
    bots: [],
    list: [],
    loading: false,
    loadingSync: false,
    loadingMaintenance: false,
    assignMaintenanceLoading: [],
    assignedMaintenanceToCompanies: [],
    maintenanceCompanies: [],
  },
  action,
) {
  switch (action.type) {
    case 'GETALL_COMPANIES_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'GETALL_COMPANIES_SUCCESS':
      return {
        ...state,
        loading: false,
        list: action.list,
      };
    case 'GETALL_COMPANIES_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'CREATE_COMPANY_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'CREATE_COMPANY_SUCCESS':
      return {
        ...state,
        loading: false,
        list: [...state.list, action.data],
      };

    case 'SELECT_COMPANY_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'SELECT_COMPANY_REQUEST_FINISH':
      return {
        ...state,
        loading: false,
      };
    case 'SELECT_COMPANY_SUCCESS':
      return {
        ...state,
        loading: false,
        bots: action.data.Tenants,
      };
    case 'SELECT_COMPANY_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case 'SYNC_COMPANIES_REQUEST':
      return {
        ...state,
        loadingSync: true,
      };

    case 'SYNC_COMPANIES_SUCCESS':
      return {
        ...state,
        loadingSync: false,
      };

    case 'SYNC_COMPANIES_FAILURE':
      return {
        ...state,
        loadingSync: false,
      };

    case 'ASSIGN_MAINTENANCE_TO_COMPANY':
      return {
        ...state,
        assignMaintenanceLoading: [
          ...state.assignMaintenanceLoading,
          action.data,
        ],
      };

    case 'ASSIGN_MAINTENANCE_TO_COMPANY_SUCCESS':
      return {
        ...state,
        assignMaintenanceLoading: state.assignMaintenanceLoading.filter(
          (el) => el !== action.data,
        ),
        assignedMaintenanceToCompanies: [
          ...state.assignedMaintenanceToCompanies,
          action.data,
        ],
      };

    case 'ASSIGN_MAINTENANCE_TO_COMPANY_FAILURE':
      return {
        ...state,
        assignMaintenanceLoading: state.assignMaintenanceLoading.filter(
          (el) => el !== action.data,
        ),
      };

    case 'RESET_COMPANIES':
      return {
        ...state,
        loading: false,
        assignMaintenanceLoading: [],
        assignedMaintenanceToCompanies: [],
      };

    case 'GET_MAINTENANCE_USER_COMPANIES_REQUEST':
      return {
        ...state,
        loadingMaintenance: true,
      };

    case 'GET_MAINTENANCE_USER_COMPANIES_SUCCESS':
      return {
        ...state,
        loadingMaintenance: false,
        maintenanceCompanies: action.data,
      };

    case 'GET_MAINTENANCE_USER_COMPANIES_FAILURE':
      return {
        ...state,
        loadingMaintenance: false,
      };

    default:
      return state;
  }
}
