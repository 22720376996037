import { integrationLogsService } from '../_services';

function get(tid) {
  return (dispatch) => {
    dispatch({ type: 'GET_INTEGRATION_LOGS'});

    integrationLogsService.get(tid).then(
      (res) => {
        dispatch({ type: 'GET_INTEGRATION_LOGS_SUCCESS', data: {list: res}});

      },
      (err) => {
        dispatch({ type: 'GET_INTEGRATION_LOGS_FAILURE'});
      }
    )
  }
}

export const integrationLogsActions = {
  get
}