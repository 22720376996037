import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './assets/base.scss';
import * as serviceWorker from './serviceWorker';
import HomePage from './Pages/HomePage';
import configureStore from './_store/configureStore';

const bugsnagApiKey = process.env.REACT_APP_BUGSNAG_API_KEY;

const store = configureStore();
const rootElement = document.getElementById('root');
let ErrorBoundary = ({ children }) => children;
if (bugsnagApiKey) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.REACT_APP_ENVIRONMENT,
    appVersion: 'latest',
    autoTrackSessions: true,
  });

  ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React);
}
const renderApp = (Component) => {
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <HashRouter>
          <Component />
        </HashRouter>
      </Provider>
    </ErrorBoundary>,
    rootElement,
  );
};

renderApp(HomePage);
serviceWorker.unregister();
