import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { jobs } from './jobs.reducer';
import { alert } from './alert.reducer';
import { alerts } from './alerts.reducer';
import { wps } from './wps.reducer';
import { companies } from './companies.reducer';
import definitions from './definitions.reducer';
import { property } from './property.reducer';
import { bot } from './bot.reducer';
import { onboarding } from './onboarding.reducer';
import { maintenance } from './maintenance.reducer';
import { integrations } from './integrations.reducer';
import { wizard } from './wizard.reducer';
import { settings } from './settings.reducer';
import { toasts } from './toast.reducer';
import { search } from './search.reducer';
import { bulk } from './bulk.reducer';
import { socket } from './socket.reducer';
import { report } from './report.reducer';
import { sync } from './sync.reducer';
import { notifications } from './notifications.reducer';
import { botActions } from './botactions.reducer';
import { conversation } from './conversation.reducer';
import { embedded } from './embedded.reducer';
import ThemeOptions from './ThemeOptions';

export default {
  authentication,
  registration,
  users,
  jobs,
  alert,
  alerts,
  wps,
  companies,
  definitions,
  property,
  bot,
  onboarding,
  maintenance,
  integrations,
  wizard,
  settings,
  bulk,
  toasts,
  socket,
  report,
  ThemeOptions,
  search,
  sync,
  notifications,
  botActions,
  conversation,
  embedded,
};
