import { sendRequest } from '../_helpers/request.lib';

export const jobsService = {
    get,
    create,
    destroy
};

const API_URL = process.env.REACT_APP_API_URL

async function get(){
    const response = await sendRequest('GET', API_URL+'/jobs');
    return response;
}

async function create(job){
    const response = await sendRequest('POST', API_URL+'/jobs', {data: job});
    return response;
}

async function destroy(id){
    const response = await sendRequest('DELETE', API_URL+'/jobs/'+id);
    return response;
}