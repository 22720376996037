import { sendRequest } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function get(tid) {
  return sendRequest('GET', `${API_URL}/integrations/${tid}/logs`);
}

export const integrationLogsService = {
  get
}