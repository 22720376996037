export function bot(
  state = {
    data: {},
    info: {},
    watched: [],
    loading: false,
    loadingInfo: false,
    testEmbeddedLoading: false,
    infoSuccess: false,
    loginLoading: false
  },
  action,
) {
  switch (action.type) {
    case 'SELECT_BOT_REQUEST':
      return {
        ...state,
        loading: true,
      };

    case 'SELECT_BOT_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.data,
      };

    case 'SELECT_BOT_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'SET_WATCHED_BOTS_LIST':
      return {
        ...state,
        watched: action.data,
      };

    case 'CHANGE_BOT_DATA':
      return {
        ...state,
        data: {
          ...state.data,
          [action.field.name]: action.field.value,
        },
      };

    case 'UPDATE_BOT_REQUEST':
      return {
        ...state,
        updateLoading: true,
      };

    case 'UPDATE_BOT_SUCCESS':
      return {
        ...state,
        updateLoading: false,
      };

    case 'UPDATE_BOT_FAILURE':
      return {
        ...state,
        updateLoading: false,
        error: action.error,
      };

    case 'BOT_INFO_REQUEST':
      return {
        ...state,
        loadingInfo: true,
        infoSuccess: false,
      };

    case 'BOT_INFO_SUCCESS':
      return {
        ...state,
        loadingInfo: false,
        info: action.data,
        infoSuccess: true,
      };

    case 'DESELECT_BOT':
      return {
        data: {},
        info: {},
      };

    case 'TEST_EMBEDDED_REQUEST':
      return {
        ...state,
        testEmbeddedLoading: true,
      };

    case 'TEST_EMBEDDED_SUCCESS':
      return {
        ...state,
        testEmbeddedLoading: false,
      };

    case 'TEST_EMBEDDED_FAILURE':
      return {
        ...state,
        testEmbeddedLoading: false,
      };
    case 'LOGIN_BOT_REQUEST':
      return {
        ...state,
        loginLoading: true,
      };

    case 'LOGIN_BOT_SUCCESS':
      return {
        ...state,
        loginLoading: false,
      };

    case 'LOGIN_BOT_FAILURE':
      return {
        ...state,
        loginLoading: false,
      };
    default:
      return state;
  }
}
