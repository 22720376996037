import { sendRequest, sendFormData } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function getData() {
  const response = await sendRequest('GET', `${API_URL}/bulk/getdata`);
  return response;
}

async function sendData(data) {
  const formData = new FormData();
  const fieldsArr = [];
  data.fields.forEach((el) => {
    fieldsArr.push(el);
    formData.append(el.name, el.value);
  });
  formData.append('bots', JSON.stringify(data.bots));
  formData.append('fields', JSON.stringify(fieldsArr));
  const response = await sendFormData('POST', `${API_URL}/bulk/create`, formData);
  return response;
}

async function getJobs() {
  const response = await sendRequest('GET', `${API_URL}/bulk/jobs`);
  return response;
}

async function selectTids(tids) {
  const response = await sendRequest('POST', `${API_URL}/bulk/selectTids`, { tids });
  return response;
}

export const bulkService = {
  getData,
  sendData,
  getJobs,
  selectTids,
};
