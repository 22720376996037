/* eslint-disable import/prefer-default-export */

import { bulkService } from '../_services/bulk.service';

function selectBot(data, company) {
  return (dispatch) => {
    dispatch({
      type: 'ADD_BOT_TO_BULK_EDIT_GROUP',
      data: { ...data, company },
    });
  };
}

function deselectBot(id) {
  return (dispatch) => {
    dispatch({ type: 'REMOVE_BOT_FROM_BULK_EDIT_GROUP', id });
  };
}

function selectAllBots(bots, company) {
  return (dispatch) => {
    dispatch({
      type: 'ADD_ALL_BOTS_TO_BULK_EDIT_GROUP',
      data: { bots, company },
    });
  };
}

function deselectAllBots() {
  return (dispatch) => {
    dispatch({ type: 'REMOVE_ALL_BOTS_FROM_BULK_EDIT_GROUP' });
  };
}

function setField(name, disabled, value = null) {
  return (dispatch) => {
    dispatch({ type: 'ADD_OR_REMOVE_FIELD', data: { name, disabled, value } });
  };
}

function setValue(field, value) {
  return (dispatch) => {
    dispatch({ type: 'CHANGE_FIELD_VALUE', data: { field, value } });
  };
}

function getData() {
  return (dispatch) => {
    dispatch({ type: 'GET_BULK_EDIT_FIELDS_DATA_REQUEST' });

    bulkService.getData().then(
      (result) => {
        dispatch({ type: 'GET_BULK_EDIT_FIELDS_DATA_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'GET_BULK_EDIT_FIELDS_DATA_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function submit(fields, bots) {
  return (dispatch) => {
    dispatch({ type: 'SEND_BULK_EDIT_FIELDS_REQUEST' });
    bulkService.sendData({ fields, bots }).then(
      (result) => {
        dispatch({ type: 'SEND_BULK_EDIT_FIELDS_SUCCESS', data: result });
        dispatch({
          type: 'ALERT_SUCCESS',
          message: 'Bulk edit job created successfully.',
        });
      },
      (error) => {
        dispatch({ type: 'SEND_BULK_EDIT_FIELDS_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function getJobs() {
  return (dispatch) => {
    dispatch({ type: 'GET_CURRENT_BULK_JOBS_REQUEST' });
    bulkService.getJobs().then(
      (result) => {
        dispatch({ type: 'GET_CURRENT_BULK_JOBS_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'SEND_BULK_EDIT_FIELDS_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function selectTidBots(list) {
  return (dispatch) => {
    dispatch({ type: 'SELECT_COMPANY_REQUEST' });
    bulkService.selectTids(list).then(
      (result) => {
        dispatch({ type: 'SELECT_COMPANY_REQUEST_FINISH' });
        dispatch({ type: 'ADD_ALL_BOTS_TO_BULK_EDIT_GROUP', data: { bots: result.bots } });
      },
      (error) => {
        dispatch({ type: 'SELECT_BULK_TIDS_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

export const bulkActions = {
  selectBot,
  deselectBot,
  selectAllBots,
  deselectAllBots,
  setField,
  setValue,
  getData,
  submit,
  getJobs,
  selectTidBots,
};
