import { sendRequest } from '../_helpers/request.lib'

export const settingsService = {
    crons,
    updateSettings
}
const API_URL = process.env.REACT_APP_API_URL

async function crons(){
    const response = await sendRequest('GET', API_URL+'/settings/crons');
    return response;
}

async function updateSettings(name, value){
    const response = await sendRequest('POST', API_URL+'/settings', {
        field: name,
        value: value
    });

    return response;
}
