/* eslint-disable import/prefer-default-export */
export function embedded(state = {
  connected: false,
  list: [],
  loading: false,
  loadingCheck: false,
}, action) {
  switch (action.type) {
    case 'GET_EMBEDDED_LIST_REQUEST':
      return {
        ...state,
        loading: true,
      };

    case 'GET_EMBEDDED_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        list: action.data,
      };

    case 'GET_EMBEDDED_LIST_FAILURE':
      return {
        ...state,
        loading: false,
      };

    case 'CHECK_EMBEDDED_STATUS_REQUEST':
      return {
        ...state,
        loadingCheck: true,
      }

    case 'CHECK_EMBEDDED_STATUS_SUCCESS':
      return {
        ...state,
        list: state.list.map((el) => {
          if(el.tid === action.data.tid) {
            el.scraperStatus = action.data.res.scraperStatus;
          }
          return el;
        }),
        loadingCheck: false,
      }

    case 'CHECK_EMBEDDED_STATUS_FAILURE':
      return {
        ...state,
        loadingCheck: false,
      }

    case 'UPDATE_BOT_STATUS_REQUEST':
      return {
        ...state,
        loadingCheck: true,
      }

    case 'UPDATE_BOT_STATUS_SUCCESS':
      return {
        ...state,
        list: state.list.map((el) => {
          if(action.data.status) {
            if(el.tid === action.data.tid) {
              el.statusId = action.data.status.id;
              el.Status = action.data.status;
            }
          }
          return el;
        }),
        loadingCheck: false,
      }

    case 'UPDATE_BOT_STATUS_FAILURE':
      return {
        ...state,
        loadingCheck: false,
      }

    default:
      return state;
  }
}
