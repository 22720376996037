// import { propertyService } from '../_services';

export const propertyActions = {
    save,
    deselect,
    saveFloorplan,
    changeOptions,
    changeAppointmentTime,
    createGalleryCategory,
    updateDefaultSpecial,
    deleteImage,
}

function save(fps){
    return dispatch => {
        dispatch({type: 'SAVE_FLOORPLANS_REQUEST'});
    }
}

function deselect(){
    return dispatch => {
        dispatch({type: 'DESELECT_FLOORPLAN'});
    }
}

function saveFloorplan(){
    return dispatch => {
        dispatch({type: 'SAVE_FLOORPLAN'});
        this.deselect();
    }
}

function changeOptions(name){
    return dispatch => {
        dispatch({type: 'CHANGE_OPTIONS', name: name});
    }
}

function changeAppointmentTime(data){
    return dispatch => {
        dispatch({type: 'CHANGE_APPOINTMENT_TIME', data: data})
    }
}



function createGalleryCategory(name){
    return dispatch => {
        dispatch({type: 'CREATE_GALLERY_CATEGORY', name: name});
    }
}

function updateDefaultSpecial(text){
    return dispatch => {
        dispatch({type: 'UPDATE_DEFAULT_SPECIAL', text: text});
    }
}

function deleteImage(id){
    console.log(id);
}