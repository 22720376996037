import { sendRequest } from '../_helpers/request.lib'

export const scraperService = {
    scrape
}
const API_URL = process.env.REACT_APP_API_URL

async function scrape(tid){
    const response = await sendRequest('GET', API_URL+'/scrape/'+tid);
    return response;
}