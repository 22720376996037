import {toast} from 'react-toastify'

export function toasts(state = {}, action) {
    switch (action.type) {
        case 'ALERT_WARNING':
            toast['warning'](action.message, {
                autoClose: false
            });
            return {};
        case 'ALERT_SUCCESS':
            toast.dismiss();
            toast['success'](action.message)
            return {};
        case 'STICK_ALERT_SUCCESS':
            toast['success'](action.message)
            return {};
        case 'STICK_ALERT_ERROR':
            toast['success'](action.message)
            return {};
        case 'ALERT_ERROR':
            toast.dismiss();
            let {message} = action.error;
            message = message ? message : action.error.data?.message;
            toast['error'](message || 'Something went wrong.')
            return {};
        default:
            return {};
    }
}