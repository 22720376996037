import { embeddedService } from '../_services';


function get() {
  return (dispatch) => {
    dispatch({type: 'GET_EMBEDDED_LIST_REQUEST'});

    embeddedService.get().then(
      (res) => {
        dispatch({type: 'GET_EMBEDDED_LIST_SUCCESS', data: res});
      },
      (error) => {
        dispatch({type: 'GET_EMBEDDED_LIST_FAILURE'});
        dispatch({type: 'ALERT_ERROR', error: error});
      }
    )
  }
}

function check(tid) {
  return (dispatch) => {
    dispatch({type: 'CHECK_EMBEDDED_STATUS_REQUEST'});

    embeddedService.check(tid).then(
      (res) => {
        dispatch({type: 'CHECK_EMBEDDED_STATUS_SUCCESS', data: {res, tid}});
      },
      (error) => {
        dispatch({type: 'CHECK_EMBEDDED_STATUS_FAILURE'});
        dispatch({type: 'ALERT_ERROR', error: error});
      }
    )
  }
}

function update(tid) {
  return (dispatch) => {
    dispatch({type: 'UPDATE_BOT_STATUS_REQUEST'});

    embeddedService.update(tid).then(
      (res) => {
        dispatch({type: 'UPDATE_BOT_STATUS_SUCCESS', data: {status: res, tid}});
      },
      (error) => {
        dispatch({type: 'UPDATE_BOT_STATUS_FAILURE'});
        dispatch({type: 'ALERT_ERROR', error: error});
      }
    )
  }
}

export const embeddedActions = {
  get,
  check,
  update,
}