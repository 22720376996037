function showActions(tid, data, position) {
  return (dispatch) => {
    dispatch({type: 'SHOW_BOT_ACTIONS', data: {tid, data, position}});
  }
}

function clearActions() {
  return (dispatch) => {
    dispatch({type: 'SHOW_BOT_ACTIONS', data: {tid: '', data: {}, position: {}}});
  }
}

export const botDetailsActions = {
  showActions,
  clearActions
}