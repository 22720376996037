import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function Private({ component: Component, ...rest }) {
  const userStr = localStorage.getItem('user');
  const user = userStr ? JSON.parse(userStr) : false;

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          !user.gaToken && rest.path !== '/ga' ? (
            <Redirect
              to={{ pathname: '/ga', state: { from: props.location } }}
            />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}
export default Private;
