import { sendRequest, sendFormData } from '../../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function get(tid) {
  const response = await sendRequest('GET', `${API_URL}/floorplans/${tid}`);
  return response;
}

async function create(data, tid) {
  let formData = new FormData();
  for (let [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  const response = await sendFormData(
    'POST',
    `${API_URL}/floorplans/${tid}/create`,
    formData,
  );
  return response;
}

async function update(tid, id, data) {
  let formData = new FormData();
  for (let [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  const response = await sendFormData(
    'PUT',
    `${API_URL}/floorplans/${tid}/update/${id}`,
    formData,
  );
  return response;
}

async function reorder(data, tid) {
  const requestData = data.map((el) => ({ id: el.id, weight: el.weight }));
  const response = await sendRequest(
    'POST',
    `${API_URL}/floorplans/${tid}/reorder`,
    requestData,
  );
  return response;
}

async function destroy(tid, id) {
  const response = await sendRequest(
    'DELETE',
    `${API_URL}/floorplans/${tid}/delete/${id}`,
  );
  return response;
}

async function sync(data, tid) {
  let check = true;
  for (let i = 0; i < data.length; i += 1) {
    const fp = data[i];
    if (fp.active) {
      check = fp.get_notified;
      if (!check) {
        check = false;
        break;
      }
    }
  }

  if (check) {
    data = data.map((fp) => {
      fp.get_notified = 0;
      return fp;
    });
  }

  const response = await sendRequest(
    'POST',
    `${API_URL}/floorplans/${tid}/sync`,
    { data },
  );
  return response;
}

export const floorplansService = {
  get,
  create,
  update,
  reorder,
  destroy,
  sync,
};
