import {sendRequest} from '../_helpers/request.lib';

export const utilityService = {
    getPresignedUploadParams
};

const API_URL = process.env.REACT_APP_API_URL;

async function getPresignedUploadParams(name, type){
    const response = await sendRequest('POST', API_URL+'/get_s3_presigned_url/', {name: name, type: type});
    return response;
}