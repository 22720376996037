import axios from 'axios';

function getTokens() {
  const tokens = {
    accessToken: localStorage.getItem('accessToken'),
    rememberToken: localStorage.getItem('rememberToken'),
  };

  return tokens;
}

/**
 * Send request to server
 *
 * @param {string}  method                Assigns a method to this URL
 * @param {string}  url                   Sends request to this URL
 * @param {JSON}    data                  Sends this data as body parameters to this request
 * @param {boolean} shouldBeAuthenticated Checks if the request should be triggered with saved authenticated user
 */
export async function sendRequest(
  method,
  url,
  data = {},
  shouldBeAuthenticated = true,
) {
  const tokens = getTokens();

  const request = await axios({
    method,
    url,
    params: {
      rememberToken: tokens.rememberToken,
    },
    data,
    headers: {
      Authorization: `Bearer ${tokens.accessToken}`,
    },
  })
    .then((response) => {
      if (response.headers.new_access_token) {
        localStorage.setItem('accessToken', response.headers.new_access_token);
      }
      if (response.headers.new_access_token) {
        localStorage.setItem(
          'rememberToken',
          response.headers.new_remember_token,
        );
      }
      return response.data;
    })
    .catch((error) => {
      if (shouldBeAuthenticated && error.response.status === 401) {
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('rememberToken');
        window.location.reload();
      }
      return Promise.reject(error.response.data);
    });

  return request;
}

export async function sendFormData(method, url, data) {
  const tokens = getTokens();
  const request = await axios({
    method,
    url,
    params: {
      rememberToken: tokens.rememberToken,
    },
    data,
    headers: {
      Authorization: `Bearer ${tokens.accessToken}`,
    },
  })
    .then((res) => {
      if (res.headers.new_access_token) {
        localStorage.setItem('accessToken', res.headers.new_access_token);
      }
      if (res.headers.new_access_token) {
        localStorage.setItem('rememberToken', res.headers.new_remember_token);
      }
      return res.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.removeItem('loggedIn');
        window.location.reload();
      }
      return Promise.reject(error.response.data.msg);
    });
  return request;
}
