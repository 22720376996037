import {onboardingService, galleryService} from '../_services';

export const onboardingActions = {
    getOnboardingList,
    select,
    changeMap,
    changeMapInput,
    
    getSpecials,
    updateDefaultSpecial,
    addSpecial,
    deleteSpecial,
    saveSpecials,

    getFloorplans,
    createFloorplanModal,
    createFloorplan,
    editFloorplanModal,
    updateFloorplan,
    deleteFloorplan,
    moveFloorplan,
    reorderFloorplans,
    toggleFloorplansOption,
    saveEditFloorplanData,
    resetModal,

    getAppointments,
    changeAppointments,
    saveAppointmentData,
    
    getAmenities,
    addAmenityCategory,
    saveAmenitiesChange,
    saveTitleChange,
    deleteAmenityCategory,
    saveAmenities,

    getGallery,
    saveGallery,
    moveImage,
    resetSelected,
    createGalleryCategory,
    newImage,
    deleteImage,

    changeGeneralData,
    saveGeneralInfo
}

function getOnboardingList(){
    return dispatch => {
        dispatch({type: 'GET_ONBOARDING_LIST_REQUEST'})

        onboardingService.get().then(
            result => {
                dispatch({type: 'GET_ONBOARDING_LIST_SUCCESS', list: result})
            },
            error => {
                dispatch({type: 'ALERT_ERROR', error: error})
            }
        )
    }
}

function select(tid){
    return dispatch => {
        dispatch({type: 'SELECT_ONBOARDING_BOT_REQUEST'});

        onboardingService.select(tid).then(
            result => {
                dispatch({type: 'SELECT_ONBOARDING_BOT_SUCCESS', data: result});
            },
            error => {
                dispatch({type: 'SELECT_ONBOARDING_BOT_FAILURE', error: error});
                dispatch({type: 'ALERT_ERROR', error: error})
            }
        )
    }
}

function changeMap(lat, lng){
    return dispatch => {
        dispatch({type: 'CHANGE_MAP_DATA', data: {lat: lat, lng: lng}});
    }
}

function changeMapInput(text){
    return dispatch => {
        dispatch({type: "CHANGE_MAP_INPUT", text: text});
    }
}

function changeGeneralData(name, value){
    return dispatch => {
        dispatch({type: 'CHANGE_GENERAL_INFO', data: {name: name, value: value}});
    }
}

function saveGeneralInfo(data, tid){
    return dispatch => {
        dispatch({type: 'SAVE_GENERAL_INFO_REQUEST'});
        console.log(data);
        onboardingService.saveGeneralInfo(data, tid).then(
            result => {
                dispatch({type: 'SAVE_GENERAL_INFO_SUCCESS', data: data});
                let {message} = result;
                dispatch({type: 'ALERT_SUCCESS', message: message})
                dispatch(select(tid))
            },
            error => {
                dispatch({type: 'SAVE_GENERAL_INFO_FAILURE'});
                dispatch({type: 'ALERT_ERROR', error:error})
                
            }
        )
    }
}

function getSpecials(tid){
    return dispatch => {
        dispatch({type: 'GET_SPECIALS_REQUEST'});

        onboardingService.getSpecials(tid).then(
            result => {
                dispatch({type: 'GET_SPECIALS_SUCCESS', data: result});
            },
            error => {
                dispatch({type: 'GET_SPECIALS_FAILURE', data: error});
                dispatch({type: 'ALERT_ERROR', error:error})
            }
        )
    }
}

function addSpecial(data){
    return dispatch => {
        dispatch({type: 'ADD_NEW_SPECIAL', data: data});
    }
}
function updateDefaultSpecial(text){
    return dispatch => {
        dispatch({type: 'UPDATE_DEFAULT_SPECIAL', text: text});
    }
}
function deleteSpecial(text){
    return dispatch => {
        dispatch({type: 'DELETE_SPECIAL', text: text});
    }
}
function saveSpecials(data, tid){
    return dispatch => {
        dispatch({type: 'SAVE_SPECIALS_REQUEST'});

        onboardingService.saveSpecials(data, tid).then(
            result => {
                dispatch({type: 'SAVE_SPECIALS_SUCCESS', data: result.data});
                let {message} = result.data;
                dispatch({type: 'ALERT_SUCCESS', message: message})
            },
            error => {
                dispatch({type: 'SAVE_SPECIALS_FAILURE'});
                dispatch({type: 'ALERT_ERROR', error:error})
            }
        )
    }
}




function getFloorplans(tid){
    return dispatch => {
        dispatch({type: 'GET_FLOORPLANS_REQUEST'});

        onboardingService.getFloorplans(tid).then(
            result => {
                dispatch({type: 'GET_FLOORPLANS_SUCCESS', data: result});
            },
            error => {
                dispatch({type: 'GET_FLOORPLANS_FAILURE', error: error})
                dispatch({type: 'ALERT_ERROR'})
            }
        )
    }
}

function createFloorplanModal(state){
    return dispatch => {
        dispatch({type: "TOGGLE_CREATE_FLOORPLAN", value: state})
    }
}

function editFloorplanModal(state){
    return dispatch => {
        dispatch({type: "TOGGLE_EDIT_FLOORPLAN", value: state})
    }
}

function createFloorplan(data, tid){
    return dispatch => {
        dispatch({type: 'CREATE_FLOORPLAN_REQUEST'});

        onboardingService.createFloorplan(data, tid).then(
            result => {
                dispatch({type: 'CREATE_FLOORPLAN_SUCCESS', data: result});
                let {message} = result.data;
                dispatch({type: 'ALERT_SUCCESS', message: message})
                dispatch(getFloorplans(tid));
            },
            error => {
                dispatch({type: 'CREATE_FLOORPLAN_FAILURE'});
                dispatch({type: 'ALERT_ERROR'})
            }
        );
    }
}

function updateFloorplan(data, tid){
    return dispatch => {
        dispatch({type: 'UPDATE_FLOORPLAN_REQUEST'});
        for (const [key, value] of Object.entries(data)) {
            if(value == null){
                data[key] = '';
            }
        }
        onboardingService.updateFloorplan(data, tid).then(
            result => {
                dispatch({type: 'UPDATE_FLOORPLAN_SUCCESS', data: data})
                let {message} = result.data;
                dispatch({type: 'ALERT_SUCCESS', message: message})
                dispatch(editFloorplanModal(false));
                dispatch(getFloorplans(tid));
            }
        )
    }
}

function reorderFloorplans(list, tid){
    return dispatch => {
        dispatch({type: 'REORDER_FLOORPLAN_REQUEST'});
        onboardingService.reorderFloorplans(list, tid).then(
            result => {
                dispatch({type: 'REORDER_FLOORPLAN_SUCCESS'})
                let {message} = result.data;
                dispatch({type: 'ALERT_SUCCESS', message: message})
            }
        )
    }
}

function deleteFloorplan(tid, id){
    return dispatch => {
        dispatch({type: 'DELETE_FLOORPLAN_REQUEST'});

        onboardingService.deleteFloorplan(tid, id).then(
            result => {
                dispatch({type: 'DELETE_FLOORPLAN_SUCCESS', id: id})
                let {message} = result.data;
                dispatch({type: 'ALERT_SUCCESS', message: message})
            }
        )
    }
}


function saveEditFloorplanData(id, name, value){
    return dispatch => {
        dispatch({type: "SAVE_EDIT_FLOORPLAN_DATA", data: {id: id, name: name, value: value}})
    }
}

function resetModal(){
    return dispatch => {
        dispatch({type: 'RESET_FLOORPLANS_MODAL'});
    }
}

function toggleFloorplansOption(name){
    return dispatch => {
        dispatch({type: 'TOGGLE_FLOORPLANS_OPTIONS', name: name});
    }
}

function moveFloorplan(dragIndex, hoverIndex){
    return dispatch => {
        dispatch({type: 'MOVE_FLOORPLAN', data: {
            dragIndex: dragIndex,
            hoverIndex: hoverIndex
        }});
    }
}




function getAppointments(tid){
    return dispatch => {
        dispatch({type: 'GET_APPOINTMENTS_REQUEST'});

        onboardingService.getAppointments(tid).then(
            result => {
                dispatch({type: 'GET_APPOINTMENTS_SUCCESS', data: result});
            },
            error => {

            }
        )
    }
}

function changeAppointments(data){
    return dispatch => {
        dispatch({type: 'CHANGE_APPOINTMENT_TIME', data: data})
    }
}

function saveAppointmentData(data, tid){
    return dispatch => {
        dispatch({type: 'SAVE_APPOINTMENTS_REQUEST'});

        onboardingService.saveAppointments(data, tid).then(
            result => {
                dispatch({type: 'SAVE_APPOINTMENTS_SUCCESS', data: result.data});
                let {message} = result.data;
                dispatch({type: 'ALERT_SUCCESS', message: message})
            },
            error => {
                dispatch({type: 'ALERT_ERROR'})
                dispatch({type: 'SAVE_APPOINTMENTS_FAILURE'});
            }
        )
    }
}



function getAmenities(tid){
    return dispatch => {
        dispatch({type: 'GET_AMENITIES_REQUEST'});

        onboardingService.getAmenities(tid).then(
            result => {
                dispatch({type: 'GET_AMENITIES_SUCCESS', data: result})
            },
            error => {

            }
        )
    }
}

function addAmenityCategory(data){
    return dispatch => {
        dispatch({type: 'ADD_AMENITIES_CATEGORY', data: data})
    }
}

function deleteAmenityCategory(key, id, tid){
    return dispatch => {
        if(id){
            onboardingService.deleteAmenityCategory(id, tid).then(
                result => {
                    dispatch({type: 'DELETE_AMENITY_CATEGORY_SUCCESS', data: key});
                },
                error => {

                }
            )         
        }else{
            dispatch({type: 'DELETE_AMENITY_CATEGORY_SUCCESS', data: key});
        }
    }
}

function saveAmenities(data, tid){
    return dispatch => {
        onboardingService.saveAmenities(data, tid).then(
            result => {
                let {message} = result;
                dispatch({type: 'ALERT_SUCCESS', message: message})
                dispatch(getAmenities(tid));
            },
            error => {

            }
        )
    }
}


function saveAmenitiesChange(key, text){
    return dispatch => {
        dispatch({type: 'EDIT_AMENITIES', data: {key: key, text: text}});
    }
}

function saveTitleChange(key, text){
    return dispatch => {
        dispatch({type: 'EDIT_AMENITIES_TITLE', data: {key: key, text: text}});
    }
}






function getGallery(tid){
    return dispatch => {
        dispatch({type: 'GET_GALLERY_REQUEST'});

        galleryService.get(tid).then(
            result => {
                dispatch({type: 'GET_GALLERY_SUCCESS', data: result});
            },
            error => {
                dispatch({type: 'GET_GALLERY_FAILURE', error: error});
                dispatch({type: 'ALERT_ERROR', error: error})
            }
        )
    }
}

function saveGallery(data, tid){
    return dispatch => {
        dispatch({type: 'SAVE_GALLERY_REQUEST'});

        galleryService.save(tid, data).then(
            result => {
                dispatch({type: 'SAVE_GALLERY_SUCCESS', data: result.data});
                let {message} = result;
                dispatch({type: 'ALERT_SUCCESS', message: message})
            },
            error => {
                console.log(error);
                dispatch({type: 'ALERT_ERROR', error: error})
            }
        )
    }
}

function moveImage(dragIndex, hoverIndex, index){
    return dispatch => {
        dispatch({type: 'MOVE_IMAGE', data: {dragIndex: dragIndex, hoverIndex: hoverIndex, galleryIndex: index}});
    }
}

function resetSelected(){
    return dispatch => {
        dispatch({type: 'RESET_ONBOARDING_SELECTED'});
    }
}

function createGalleryCategory(name){
    return dispatch => {
        dispatch({type: 'CREATE_GALLERY_CATEGORY', data: name});
    }
}

function newImage(name, galleryName){
    return dispatch => {
        dispatch({type: 'ADD_NEW_IMAGE', data: {name: name, galleryName:galleryName}});
    }
}

function deleteImage(data){
    return dispatch => {
        dispatch({type: 'DELETE_IMAGE', data: data});
    }
}