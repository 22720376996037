export function botActions(state = {
  tid: false,
  position: {},
  data: {}
}, action){
  switch (action.type) {
    case 'SHOW_BOT_ACTIONS':
      return {
        ...state,
        tid: action.data.tid,
        data: action.data.data,
        position: action.data.position,
      }
    default:
      return state;
  }
}