import { definitionService } from '../_services';

function select(def_id) {
  return (dispatch) => {
    dispatch({ type: 'SELECT_DEFINITION_REQUEST' });
    definitionService.select(def_id).then(
      (result) => {
        dispatch({ type: 'SELECT_DEFINITION_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'SELECT_DEFINITION_FAILURE', error });
      },
    );
  };
}

function changeGeneralData(name, value) {
  return (dispatch) => {
    dispatch({ type: 'CHANGE_GENERAL_DATA', data: { name, value } });
  };
}

function addInitialAction(action, def_id) {
  return (dispatch) => {
    dispatch({ type: 'ADD_INIT_ACTION_REQUEST' });

    definitionService.createInitAction(action, def_id).then(
      (result) => {
        dispatch({ type: 'ADD_INIT_ACTION_SUCCESS', data: result });
        dispatch({ type: 'ALERT_SUCCESS', message: 'Added an initial action.' });
      },
      (error) => {
        dispatch({ type: 'ADD_INIT_ACTION_FAILURE', error });
      },
    );
  };
}

function deleteAction(id, type) {
  return (dispatch) => {
    dispatch({ type: 'DELETE_ACTION_REQUEST' });
    definitionService.deleteAction(id).then(
      (result) => {
        dispatch({ type: 'DELETE_ACTION_SUCCESS', data: { id, type } });
        dispatch({ type: 'ALERT_SUCCESS', message: 'Action deleted.' });
      },
      (error) => {
        dispatch({ type: 'DELETE_ACTION_FAILURE', error });
      },
    );
  };
}

function updateDefinition(data) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_DEFINITION_REQUEST' });
    definitionService.updateDefinition(data).then(
      (result) => {
        dispatch({ type: 'UPDATE_DEFINITION_SUCCESS', data: result });
        dispatch({ type: 'ALERT_SUCCESS', message: 'Website Definition updated succesfully' });
      },
      (error) => {
        dispatch({ type: 'UPDATE_DEFINITION_FAILURE', data: error });
      },
    );
  };
}

function addTargetContainer(data) {
  return (dispatch) => {
    dispatch({ type: 'ADD_ITEM_CONTAINER_REQUEST' });

    definitionService.createContainer(data).then(
      (result) => {
        dispatch({ type: 'ADD_ITEM_CONTAINER_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'ADD_ITEM_CONTAINER_FAILURE' });
      },
    );
  };
}

function deleteContainer(data) {
  return (dispatch) => {
    dispatch({ type: 'DELETE_CONTAINER_REQUEST' });
    definitionService.deleteContainer(data.id).then(
      (result) => {
        dispatch({ type: 'DELETE_CONTAINER_SUCCESS', id: data.id });
        dispatch({ type: 'ALERT_SUCCESS', message: 'Container deleted.' });
      },
      (error) => {
        dispatch({ type: 'DELETE_CONTAINER_FAILURE', error });
      },
    );
  };
}

function updateContainer(data, id) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_CONTAINER_REQUEST' });
    definitionService.updateContainer(data, id).then(
      (result) => {
        dispatch({ type: 'UPDATE_CONTAINER_SUCCESS', data: result });
        dispatch({ type: 'ALERT_SUCCESS', message: 'Container updated succesfully' });
      },
      (error) => {
        dispatch({ type: 'UPDATE_CONTAINER_FAILURE', error });
      },
    );
  };
}

function addContainerAction(data, id) {
  return (dispatch) => {
    dispatch({ type: 'ADD_CONTAINER_ACTION_REQUEST' });
    data.containerId = id;
    definitionService.createContainerAction(data, id).then(
      (result) => {
        dispatch({ type: 'ADD_CONTAINER_ACTION_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'ADD_CONTAINER_ACTION_FAILURE', error });
      },
    );
  };
}

function reset() {
  return (dispatch) => {
    dispatch({ type: 'RESET_DEFINITION_REQUEST' });
  };
}

function test(url, id) {
  return (dispatch) => {
    dispatch({ type: 'TEST_DEFINITION_URL_REQUEST' });
    definitionService.test(url, id).then(
      (result) => {
        dispatch({ type: 'TEST_DEFINITION_URL_SUCCESS', result });
      },
      () => {
        dispatch({ type: 'TEST_DEFINITION_URL_FAILURE' });
      },
    );
  };
}

// eslint-disable-next-line import/prefer-default-export
export const definitionActions = {
  select,
  addTargetContainer,
  changeGeneralData,
  addInitialAction,
  deleteAction,
  updateDefinition,
  updateContainer,
  deleteContainer,
  addContainerAction,
  reset,
  test,
};
