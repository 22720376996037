export default function definitions(state = {
  testDefinition: {
    finished: false,
    loading: false,
    result: [],
  },
  current: {
    Actions: [],
    Containers: [],
    name: '',
    parent: '',
    parentSelector: 'id',
    iframeSelector: 'id',
    isIframe: false,
    waitForNetworkIdle: false,
    headless: false,
  },
}, action) {
  switch (action.type) {
    case 'SELECT_DEFINITION_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'SELECT_DEFINITION_SUCCESS':
      return {
        ...state,
        loading: false,
        current: {
          ...state.current,
          ...action.data.def,
        },
      };
    case 'SELECT_DEFINITION_FAILURE':
      return {
        ...state,
        itemLoading: false,
        error: action.error,
      };

    case 'CHANGE_GENERAL_DATA':
      return {
        ...state,
        current: {
          ...state.current,
          [action.data.name]: action.data.value,
        },
      };

    case 'ADD_INIT_ACTION_SUCCESS':
      return {
        ...state,
        current: {
          ...state.current,
          Actions: [...state.current.Actions, action.data],
        },
      };

    case 'DELETE_ACTION_SUCCESS':
      if (action.data.type === 'initial') {
        return {
          ...state,
          current: {
            ...state.current,
            Actions: state.current.Actions.filter((el) => el.id !== action.data.id),
          },
        };
      }
      return {
        ...state,
        current: {
          ...state.current,
          Containers: state.current.Containers.map((el) => ({
            ...el,
            Actions: el.Actions.filter((ac) => ac.id !== action.data.id),
          })),
        },
      };

    case 'ADD_ITEM_CONTAINER_REQUEST':
      return {
        ...state,
        loadingContainer: true,
      };

    case 'ADD_ITEM_CONTAINER_SUCCESS': {
      const newContainer = {
        ...action.data,
        Actions: [],
      };
      return {
        ...state,
        loadingContainer: false,
        current: {
          ...state.current,
          Containers: [...state.current.Containers, newContainer],
        },
      };
    }

    case 'CHANGE_ITEM_CONTAINER':
      return {
        ...state,
        current: {
          ...state.current,
          Containers: [...state.current.Containers],
        },
      };

    case 'DELETE_CONTAINER_SUCCESS': {
      const containers = state.current.Containers;
      containers.splice(action.key, 1);
      return {
        ...state,
        current: {
          ...state.current,
          Containers: containers,
        },
      };
    }
    case 'RESET_DEFINITION_REQUEST':
      return {
        ...state,
        testDefinition: {
          finished: false,
          loading: false,
          result: [],
        },
        current: {
          Actions: [],
          Containers: [],
          name: '',
          parent: '',
          parentSelector: 'id',
          iframeSelector: 'id',
          isIframe: false,
          waitForNetworkIdle: false,
          headless: false
        },
      };

    case 'ADD_CONTAINER_ACTION_SUCCESS':
      return {
        ...state,
        current: {
          ...state.current,
          Containers: state.current.Containers.map((el) => (el.id === action.data.containerId ? {
            ...el,
            Actions: [...el.Actions, action.data],
          } : el)),
        },
      };

    case 'TEST_DEFINITION_URL_REQUEST':
      return {
        ...state,
        testDefinition: {
          ...state.testDefinition,
          loading: true,
          finished: false,
        },
      };

    case 'TEST_DEFINITION_URL_SUCCESS':
      return {
        ...state,
        testDefinition: {
          loading: false,
          result: action.result,
          finished: true,
        },
      };

    default:
      return state;
  }
}
