import {sendRequest} from '../../_helpers/request.lib'

export const galleryService = {
    get,
    save
}
const API_URL = process.env.REACT_APP_API_URL;

async function get(tid){
    const response = await sendRequest('GET', API_URL+'/gallery/'+tid);
    return response;
}

async function save(tid, data){
    const response = await sendRequest('POST', API_URL+'/gallery/'+tid, data);
    return response;
}