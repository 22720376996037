import { termsService } from '../_services';

function get(tid) {
  return (dispatch) => {
    dispatch({ type: 'GET_TERMS_REQUEST' });
    termsService.get(tid).then(
      (result) => {
        dispatch({ type: 'GET_TERMS_SUCCESS', data: result.data.terms });
      },
      (error) => {
        dispatch({ type: 'GET_TERMS_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function toggleCreate() {
  return (dispatch) => {
    dispatch({ type: 'TOGGLE_CREATE_TERM' });
  };
}

function toggleEdit() {
  return (dispatch) => {
    dispatch({ type: 'TOGGLE_EDIT_TERM' });
  };
}

function toggleDelete() {
  return (dispatch) => {
    dispatch({ type: 'TOGGLE_DELETE_TERM' });
  };
}

function create(tid, data) {
  return (dispatch) => {
    dispatch({ type: 'CREATE_TERM_REQUEST' });

    termsService.create(tid, data).then(
      (result) => {
        dispatch({ type: 'CREATE_TERM_SUCCESS' });
        dispatch(get(tid));
      },
      (error) => {
        dispatch({ type: 'CREATE_TERM_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function edit(tid, id, data) {
  return (dispatch) => {
    dispatch({ type: 'EDIT_TERM_REQUEST' });

    termsService.edit(tid, id, data).then(
      (result) => {
        dispatch({ type: 'EDIT_TERM_SUCCESS' });
        dispatch(get(tid));
      },
      (error) => {
        dispatch({ type: 'EDIT_TERM_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function deselect(position) {
  return (dispatch) => {
    dispatch({ type: 'DESELECT_TERM', data: position });
  };
}

function deleteTerm(tid, id) {
  return (dispatch) => {
    dispatch({ type: 'DELETE_TERM_REQUEST' });
    termsService.destroy(tid, id).then(
      (result) => {
        dispatch({ type: 'DELETE_TERM_SUCCESS' });
        dispatch({ type: 'ALERT_SUCCESS', message: result.data.message });
        dispatch(deselect(0));
        dispatch(get(tid));
      },
      (error) => {
        dispatch({ type: 'DELETE_TERM_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function select(tid, id) {
  return (dispatch) => {
    dispatch(deselect(-1));
    dispatch({ type: 'SELECT_TERM_REQUEST' });

    termsService.select(tid, id).then(
      (result) => {
        dispatch({ type: 'SELECT_TERM_SUCCESS', data: result.data });
      },
      (error) => {
        dispatch({ type: 'SELECT_TERM_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function updateTermFloorplans(tid, data, id) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_TERM_FLOORPLANS_REQUEST' });

    termsService.updateTermFloorplans(tid, data, id).then(
      (result) => {
        dispatch({ type: 'UPDATE_TERM_FLOORPLANS_SUCCESS' });
        dispatch({ type: 'ALERT_SUCCESS', message: result.data.message });
        dispatch(select(tid, id));
      },
      (error) => {
        dispatch({ type: 'UPDATE_TERM_FLOORPLANS_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function fetchCampus(tid, id, url, terms, index = 0) {
  return (dispatch) => {
    const term = terms[index];
    dispatch({ type: 'FETCH_CAMPUS_REQUEST' });
    termsService.fetchCampus(tid, term.id, url, term.name).then(
      (result) => {
        dispatch({ type: 'FETCH_CAMPUS_SUCCESS', data: term.name });
        dispatch({ type: 'ALERT_SUCCESS', message: result.data.message });
        if (index < terms.length - 1) {
          dispatch(fetchCampus(tid, term.id, url, terms, index + 1));
        }
      },
      (error) => {
        dispatch({ type: 'FETCH_CAMPUS_FAILURE', data: term.name });
        dispatch({ type: 'ALERT_ERROR', error });
        if (index < terms.length - 1) {
          dispatch(fetchCampus(tid, term.id, url, terms, index + 1));
        }
      },
    );
  };
}

export const termsActions = {
  get,
  toggleCreate,
  toggleEdit,
  toggleDelete,
  create,
  edit,
  deleteTerm,
  select,
  deselect,
  updateTermFloorplans,
  fetchCampus,
};
