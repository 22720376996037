import { sendRequest } from '../_helpers/request.lib';

export const authService = {
  login,
  logout,
  register,
  generateQrCode,
  verifyQrCode,
};

const API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL;

/**
 *
 * @param {string} email
 * @param {string} password
 */
async function login(email, password, rememberMe, otp) {
  const response = await sendRequest(
    'POST',
    API_AUTH_URL + '/auth/login',
    { email, password, rememberMe, otp },
    false,
  );
  return response.data;
}

async function logout() {
  // trigger logout to clear server cookies and back end authentication
  let response = await sendRequest('GET', API_AUTH_URL + '/auth/logout');
  return response;
}

/**
 *
 * @param {JSON} user Must include name, password, and password reset parameters
 */
async function register(user) {
  let response = await sendRequest(
    'POST',
    `${API_AUTH_URL}/auth/register`,
    { user: user },
    false,
  );
  return response;
}

async function generateQrCode() {
  let response = await sendRequest(
    'GET',
    `${API_AUTH_URL}/private/ga/generateQr`,
  );

  return response;
}

async function verifyQrCode(secret, token) {
  let response = await sendRequest(
    'POST',
    `${API_AUTH_URL}/private/ga/verify`,
    {
      secret,
      token,
    },
  );

  return response;
}
