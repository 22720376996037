import { sendRequest } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function bozzutoProgress() {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/bozzuto/progress`,
  );
  return response;
}

async function bozzutoHistory() {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/bozzuto/history`,
  );
  return response;
}

async function bozzutoSelect(id) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/bozzuto/select/${id}`,
  );
  return response;
}

async function bozzutoUpdate() {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/bozzuto/update`,
  );
  return response;
}

async function get(integration) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/${integration}`,
  );
  return response;
}

async function update(integration, tid) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/${integration}/update/${tid}`,
  );
  return response;
}

async function test(integration, tid) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/${integration}/test/${tid}`,
  );
  return response;
}

async function g5update(tid) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/g5/update/${tid}`,
  );
  return response;
}

async function g5test(tid) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/g5/test/${tid}`,
  );
  return response;
}

async function entrataUpdate(tid) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/entrata/update/${tid}`,
  );
  return response;
}

async function getYardiCredentials(id) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/yardi/credentials/${id}`,
  );
  return response;
}

async function yardiUpdate(tid) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/yardi/update/${tid}`,
  );
  return response;
}

async function getRealPageCredentials(id) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/realpage/credentials/${id}`,
  );
  return response;
}

async function realPageUpdate(tid) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/integrations/realpage/update/${tid}`,
  );
  return response;
}
  
async function basicRentCafeUpdate(tid) {
    const response = await sendRequest(
        'GET',
        `${API_URL}/integrations/basicrentcafe/update/${tid}`,
    );
    return response;
}

export const integrationsService = {
  bozzutoProgress,
  bozzutoHistory,
  bozzutoSelect,
  bozzutoUpdate,

  get,
  update,
  test,

  g5update,
  g5test,

  entrataUpdate,

  getYardiCredentials,
  yardiUpdate,

  getRealPageCredentials,
  realPageUpdate,

  basicRentCafeUpdate,
};
