import { sendRequest } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function forceStop(id) {
  const response = await sendRequest('GET', `${API_URL}/reports/${id}/force-stop`);
  return response;
}

async function getFields() {
  const response = await sendRequest('GET', `${API_URL}/reports/build/fields`);
  return response;
}

async function testTemplateField(definition) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/reports/build/testField`,
    { definition },
  );
  return response;
}

async function createTemplateField(name, data) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/reports/build/createField`,
    { name, data },
  );
  return response;
}

async function createReport(data) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/reports/build/create`,
    data,
  );
  return response;
}

async function getRecentReportList() {
  const response = await sendRequest('GET', `${API_URL}/reports/build/recent`);
  return response;
}

async function getReportList() {
  const response = await sendRequest('GET', `${API_URL}/reports/build/list`);
  return response;
}

async function exportReport(format, reportId) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/reports/build/export`,
    {
      format,
      reportId,
    },
  );
  return response;
}

async function getMaintenanceReport() {
  const response = await sendRequest('GET', `${API_URL}/reports/maintenance`);
  return response;
}

async function addFolder(name) {
  const response = await sendRequest('POST', `${API_URL}/folders/create`, {
    name,
  });
  return response;
}

async function moveFolderOrReport(data) {
  const response = await sendRequest('POST', `${API_URL}/folders/move`, data);
  return response;
}

async function viewReport(id) {
  const response = await sendRequest('GET', `${API_URL}/reports/${id}/view`);
  return response;
}

async function scraperDefinitions() {
  const response = await sendRequest('GET', `${API_URL}/reports/definitions`);
  return response;
}

async function getFieldDetails(selected) {
  const response = await sendRequest('GET', `${API_URL}/reports/modules/${selected.value}`);
  return response;
}

async function updateTemplate(data) {
  const response = await sendRequest('PUT', `${API_URL}/reports/modules/${data.id}`, data);
  return response;
}

export const reportService = {
  getFields,
  testTemplateField,
  createTemplateField,
  createReport,
  getReportList,
  exportReport,
  getMaintenanceReport,
  getRecentReportList,
  addFolder,
  moveFolderOrReport,
  viewReport,
  scraperDefinitions,
  forceStop,
  getFieldDetails,
  updateTemplate,
};
