/* eslint-disable import/prefer-default-export */
const defaultState = {
  selected: {
    canUndo: false,
    undoFps: [],
    tid: false,
    info: {},
    terms: {
      selected: null,
      edit: null,
      list: [],
      selectedData: {},
      loading: false,
      create: false,
      createLoading: false,
      editLoading: false,
      deleteLoading: false,
      loadingSubmit: false,
      campusUpdated: [],
      campusFailed: [],
      updated: [],
    },
    // Old Default special
    special: {
      english: '',
      spanish: '',
      from: '',
      to: '',
      id: null,
      on: true,
    },
    specials: [],
    activeSpecial: null,
    listAllSpecialsModal: false,
    createSpecialModal: false,
    deleteSpecialModal: false,
    editSpecialModal: false,
    loadingCreateSpecial: false,
    loadingDeleteSpecial: false,
    loadingUpdateSpecial: false,
    activeSpecialUpdate: 0,
    deletedSpecials: [],
    deletingSpecial: false,
    pulledFps: 0,
    floorplans: {
      prevValues: [],
      list: [],
      options: {},
      selected: null,
      viewed: [],
      updated: [],
    },
    initScrapedData: [],
    scrapedData: [],
    loading: false,
    loadingSpecial: false,
    updated: false,
    nextBot: null,
    previousBot: null,
  },
  selectedUser: '',
  selectedCompany: '',
  setGetNotified: 0,
  selectLoading: false,
  selectedList: [],
  list: [],
  nextList: [],
  createFloorplanModal: false,
  loadingSync: false,
  loadingUpdate: false,
  loadingDelete: false,
  loadingUpdateAll: false,
  loadingScrape: false,
  loadingList: false,
  loadingFps: true,
  finishedScrape: false,
  loadingBotNote: false,
  missed: [],
  missedLoading: false,
};

export function maintenance(state = defaultState, action) {
  switch (action.type) {
    case 'GET_MAINTENANCE_LIST_REQUEST':
      return {
        ...state,
        loadingList: true,
      };

    case 'GET_MAINTENANCE_LIST_SUCCESS': {
      let nextBot = null;

      const previousBot = null;
      if (action.data[1]) {
        nextBot = {
          tid: action.data[1].tid,
          name: action.data[1].name,
          index: action.data[1].index,
        };
      }
      return {
        ...state,
        list: action.data,
        loadingList: false,
        selected: {
          ...state.selected,
        },
      };
    }
    case 'GET_MAINTENANCE_LIST_FAILURE':
      return {
        ...state,
        loadingList: false,
      };

    case 'SCRAPE_REQUEST':
      return {
        ...state,
        finishedScrape: false,
        loadingScrape: true,
      };

    case 'SCRAPE_END':
      return {
        ...state,
        finishedScrape: true,
        loadingScrape: false,
      };

    case 'RESET_CURRENT_MAINTENANCE':
      return {
        ...state,
        selected: {
          ...defaultState.selected,
          floorplans: {
            ...defaultState.selected.floorplans,
            updated: [],
            selected: [],
            viewed: [],
          },
        },
      };

    case 'CAN_UNDO_FLOORPLANS':
      return {
        ...state,
        selected: {
          ...state.selected,
          canUndo: true,
        },
      };

    case 'SELECT_MAINTENANCE_REQUEST':
      return {
        ...state,
        selected: {
          ...defaultState.selected,
        },
        selectLoading: true,
      };
    case 'SELECT_MAINTENANCE_SUCCESS': {
      let current = 0;

      for (let i = 0; i < state.list.length; i++) {
        const el = state.list[i];
        if (el.tid === action.data.tid) {
          current = i;
          break;
        }
      }

      let nextBot = null;
      if (state.list.length) {
        if (state.list.length > current + 1) {
          nextBot = {
            tid: state.list[current + 1].tid,
            name: state.list[current + 1].name,
            index: current + 2,
          };
        }
      }
      let previousBot = null;
      if (state.list.length) {
        if (current - 1 >= 0) {
          previousBot = {
            tid: state.list[current - 1].tid,
            name: state.list[current - 1].name,
            index: current,
          };
        }
      }

      return {
        ...state,
        selected: {
          ...state.selected,
          tid: action.data.tid,
          integration: action.data.integration,
          info: action.data,
          initScrapedData: action.data.scrapeData,
          specials: {
            list: action.data.specials,
            default: action.data.details,
          },
          nextBot,
          previousBot,
        },
        selectLoading: false,
        selectedList: [...state.selectedList, action.data.tid],
      };
    }
    case 'SELECT_MAINTENANCE_FAILURE':
      return {
        ...state,
        selectLoading: false,
      };
    case 'UPDATE_DEFAULT_MAINTENANCE_SPECIAL':
      return {
        ...state,
        selected: {
          ...state.selected,
          specials: {
            ...state.selected.specials,
            default: {
              ...state.selected.specials.default,
              special_default: action.text,
            },
          },
        },
        selectLoading: false,
      };

    case 'EDIT_BOT_INFO_MAINTENANCE':
      return {
        ...state,
        selected: {
          ...state.selected,
          info: {
            ...state.selected.info,
            [action.data.name]: action.data.value,
          },
        },
      };

    case 'MAINTENANCE_GET_DEFAULT_SPECIAL_REQUEST':
      return {
        ...state,
        selected: {
          ...state.selected,
          loadingSpecial: true,
        },
      };
    case 'MAINTENANCE_GET_DEFAULT_SPECIAL_SUCCESS':
      return {
        ...state,
        selected: {
          ...state.selected,
          loadingSpecial: false,
        },
      };
    case 'MAINTENANCE_GET_DEFAULT_SPECIAL_FAILURE':
      return {
        ...state,
        selected: {
          ...state.selected,
          loadingSpecial: false,
        },
      };
    case 'MAINTENANCE_GET_ALL_SPECIALS_SUCCESS':
      return {
        ...state,
        selected: {
          ...state.selected,
          specials: action.data,
          loadingSpecial: false,
        },
      };
    case 'MAINTENANCE_SET_ACTIVE_SPECIAL':
        return {
        ...state,
        selected: {
          ...state.selected,
          activeSpecial: state.selected.specials.filter(special => special.active)[0],
          loadingSpecial: false,
        },
      };
    case 'MAINTENANCE_GET_ALL_SPECIALS_FAILURE':
      return {
        ...state,
        selected: {
          ...state.selected,
          specials: [],
          loadingSpecial: false,
        },
      };

    case 'EDIT_SPECIAL':
      return {
        ...state,
        selected: {
          ...state.selected,
          special: {
            ...state.selected.special,
            [action.data.field]: action.data.value,
          },
        },
      };

    case 'UPDATE_ACTIVE_SPECIAL':
      return {
        ...state,
        selected: {
          ...state.selected,
          activeSpecialUpdate: state.selected.activeSpecialUpdate + 1
        },
      };

    case 'CREATED_SPECIAL':
      return {
        ...state,
        selected: {
          ...state.selected,
          special: {
            from: '',
            to: '',
            english: '',
            spanish: ''
          },
        },
      };

    case 'UPDATE_BOT_INFO_REQUEST':
      return {
        ...state,
        selected: {
          ...state.selected,
          updateLoading: true,
        },
      };
    case 'UPDATE_BOT_INFO_SUCCESS':
      return {
        ...state,
        selected: {
          ...state.selected,
          updateLoading: false,
        },
      };
    case 'UPDATE_BOT_INFO_FAILURE':
      return {
        ...state,
        selected: {
          ...state.selected,
          updateLoading: false,
        },
      };

    case 'SETUP_PROPERTY_SCRAPER_DATA': {
      let prevIndex = 0;
      let notFound = [];
      let reordered = [];
      let foundIndexes = [];
      for (let i = 0; i < action.data.length; i += 1) {
        const newFp = action.data[i];
        let foundIndex = -1;
        for (let j = 0; j < state.selected.floorplans.list.length; j++) {
          let fp = state.selected.floorplans.list[j];
          if (newFp.name === fp.name) {
            foundIndex = j;
            foundIndexes.push(j);
          }
        }

        if (foundIndex >= 0) {
          let fpToOrder = state.selected.floorplans.list[foundIndex];
          fpToOrder = { ...fpToOrder, ...newFp };
          ['price_from', 'price_to', 'square_feet'].forEach((el) => {
            fpToOrder[el] =
              parseFloat(fpToOrder[el].toString().replace(/[^\d.]*/g, '')) || 0;
          });
          reordered.push(fpToOrder);
        }
      }
      if (reordered.length !== state.selected.floorplans.list.length) {
        for (let q = 0; q < state.selected.floorplans.list.length; q++) {
          const fp = state.selected.floorplans.list[q];
          if (!foundIndexes.includes(q)) {
            reordered.push(fp);
          }
        }
      }

      return {
        ...state,
        selected: {
          ...state.selected,
          scrapedData: action.data,
          floorplans: {
            ...state.selected.floorplans,
            list: reordered,
          },
        },
      };
    }
    case 'MAINTENANCE_TOGGLE_SPECIALS':
      return {
        ...state,
        selected: {
          ...state.selected,
          specials: {
            ...state.selected.specials,
            default: {
              ...state.selected.specials.default,
              'quicklink_Specials-SpecialsConversation': action.data,
            },
          },
        },
      };
    case 'MAINTENANCE_TOGGLE_LIST_ALL_SPECIALS_MODAL':
      return {
        ...state,
        selected: {
          ...state.selected,
          listAllSpecialsModal: !state.selected.listAllSpecialsModal
        },
      };
    case 'MAINTENANCE_TOGGLE_CREATE_SPECIAL_MODAL':
      return {
        ...state,
        selected: {
          ...state.selected,
          createSpecialModal: !state.selected.createSpecialModal,
        },
      };
    case 'TOGGLE_CREATE_SPECIAL_MODAL_BUTTON':
      return {
        ...state,
        selected: {
          ...state.selected,
          loadingCreateSpecial: !state.selected.loadingCreateSpecial
        },
      };
    case 'MAINTENANCE_TOGGLE_DELETE_SPECIAL_MODAL':
      return {
        ...state,
        selected: {
          ...state.selected,
          deleteSpecialModal: !state.selected.deleteSpecialModal
        },
      };
    case 'MAINTENANCE_TOGGLE_EDIT_SPECIAL_MODAL':
      return {
        ...state,
        selected: {
          ...state.selected,
          editSpecialModal: !state.selected.editSpecialModal,
        },
      };
    case 'MAINTENANCE_TOGGLE_UPDATE_SPECIAL_LOADER':
      return {
        ...state,
        selected: {
          ...state.selected,
          loadingUpdateSpecial: !state.selected.loadingUpdateSpecial
        },
      };
    case 'MAINTENANCE_SET_EDIT_ACTIVE_SPECIAL':
      const activeSpecial = state.selected.specials.filter(s => s.id == action.data.id)[0]
      return {
        ...state,
        selected: {
          ...state.selected,
          special: {
            english: activeSpecial?.text.split("</en><es>")[0].replace("<en>", ""),
            spanish: activeSpecial?.text.includes("</en><es>") && activeSpecial?.text.split("</en><es>")[1].replace("</es>", ""),
            from: activeSpecial?.date_from,
            to: activeSpecial?.date_to,
            id: activeSpecial?.id
          }
        },
      };
    case 'MAINTENANCE_RESET_SPECIAL':
      return {
        ...state,
        selected: {
          ...state.selected,
          special: {
            english: '',
            spanish: '',
            from: '',
            to: '',
          }
        },
      };
    case 'TOGGLE_DELETE_SPECIAL_MODAL_BUTTON':
      return {
        ...state,
        selected: {
          ...state.selected,
          loadingDeleteSpecial: !state.selected.loadingDeleteSpecial
        },
      };
    case 'MAINTENANCE_UPDATE_SPECIAL_TOGGLE':
      return {
        ...state,
        loading: !state.loading,
      };
    case 'MAINTENANCE_SAVE_DEFAULT_SPECIAL_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'MAINTENANCE_SAVE_DEFAULT_SPECIAL_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'MAINTENANCE_SAVE_DEFAULT_SPECIAL_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'MAINTENANCE_CREATE_SPECIAL_SUCCESS':
      return {
        ...state,
        selected: {
          ...state.selected,
          specials: {
            ...state.selected.specials,
            ...action.data,
          },
        },
      };
    case 'MAINTENANCE_DELETE_SPECIAL_SUCCESS_OPTIMISTIC':
      return {
        ...state,
        selected: {
          ...state.selected,
          specials: state.selected.specials.filter(el => el.id !== action.id),
          deletedSpecials: [...state.selected.specials.filter(el => el.id === action.id)],
          deletingSpecial: true,
        },
      };
    case 'MAINTENANCE_DELETE_SPECIAL_SUCCESS':
      return {
        ...state,
        selected: {
          ...state.selected,
          deletingSpecial: false,
        },
      };
    case 'MAINTENANCE_DELETE_SPECIAL_FAILURE':
      return {
        ...state,
        selected: {
          ...state.selected,
          specials: [
            ...state.selected.specials,
            ...state.selected.deletedSpecials
          ],
          deletingSpecial: false,
        },
      };

    case 'MAINTENANCE_GET_FLOORPLANS_REQUEST':
      return {
        ...state,
        loadingFps: true,
      };
    case 'MAINTENANCE_GET_FLOORPLANS_FAILURE':
      return {
        ...state,
        loadingFps: false,
      };

    case 'UNDO_FLOORPLANS_SUCCESS':
      return {
        ...state,
        selected: {
          ...state.selected,
          undoFps: [],
          canUndo: false,
        },
      };

    case 'RESET_MAINTENANCE_FPS':
      return {
        ...state,
        selected: {
          ...state.selected,
          floorplans: {
            ...state.selected.floorplans,
            selected: [],
            viewed: [],
            updated: [],
          },
        },
      };

    case 'MAINTENANCE_GET_FLOORPLANS_SUCCESS': {
      const selectedId = 0;
      const arr = action.list;
      const { canUndo } = state.selected;
      let undoFps = [];
      let foundIndexes = [];
      let reordered = [];
      // Deep copy an array of objects
      const prevValues = JSON.parse(JSON.stringify(action.list));
      if (state.selected.scrapedData.length) {
        arr.forEach((el) => {
          let scraped = state.selected.scrapedData.filter(
            (s) => s.name === el.name,
          )[0];
          if (scraped) {
            let sKeys = Object.keys(scraped);
            for (let i = 0; i < sKeys.length; i += 1) {
              let key = sKeys[i];
              el[key] = scraped[key];
              if (['price_from', 'price_to', 'square_feet'].includes(key)) {
                el[key] =
                  parseFloat(scraped[key].toString().replace(/[^\d.]*/g, '')) ||
                  0;
              }
            }
          }
        });

        for (let i = 0; i < state.selected.scrapedData.length; i += 1) {
          const newFp = state.selected.scrapedData[i];
          let foundIndex = -1;
          for (let j = 0; j < arr.length; j++) {
            const fp = arr[j];
            if (newFp.name === fp.name) {
              foundIndex = j;
              foundIndexes.push(j);
            }
          }

          if (foundIndex >= 0) {
            reordered.push(arr[foundIndex]);
          }
        }
        if (reordered.length !== arr.length) {
          for (let q = 0; q < arr.length; q++) {
            const fp = arr[q];
            if (!foundIndexes.includes(q)) {
              reordered.push(fp);
            }
          }
        }
      } else {
        reordered = action.list;
      }
      if (!canUndo) {
        undoFps = prevValues;
      } else {
        undoFps = state.selected.undoFps;
      }

      return {
        ...state,
        loadingFps: false,
        selected: {
          ...state.selected,
          undoFps,
          pulledFps: 1,
          floorplans: {
            ...state.selected.floorplans,
            prevValues,
            list: reordered,
            selected: selectedId,
          },
        },
      };
    }

    case 'MAINTENANCE_TOGGLE_CREATE':
      return {
        ...state,
        createFloorplanModal: action.state,
      };

    case 'MAINTENANCE_MOVE_FLOORPLAN': {
      const dragFloorplans =
        state.selected.floorplans.list[action.data.dragIndex];
      const list = state.selected.floorplans.list.slice();
      list.splice(action.data.dragIndex, 1);
      list.splice(action.data.hoverIndex, 0, dragFloorplans);
      list.map((el, key) => (el.weight = key));
      return {
        ...state,
        selected: {
          ...state.selected,
          floorplans: {
            ...state.selected.floorplans,
            list,
          },
        },
      };
    }

    case 'MAINTENANCE_SAVE_FLOORPLANS_REQUEST':
      return {
        ...state,
        loadingSync: true,
      };
    case 'MAINTENANCE_SAVE_FLOORPLANS_SUCCESS':
      return {
        ...state,
        loadingSync: false,
      };
    case 'MAINTENANCE_SAVE_FLOORPLANS_FAILURE':
      return {
        ...state,
        loadingSync: false,
      };

    case 'SEEN_FP': {
      let select = true;
      if (state.selected.floorplans.viewed.includes(action.id)) {
        select = false;
      }
      return {
        ...state,
        selected: {
          ...state.selected,
          floorplans: {
            ...state.selected.floorplans,
            viewed: select
              ? [...state.selected.floorplans.viewed, action.id]
              : state.selected.floorplans.viewed.filter(
                  (fp) => fp !== action.id,
                ),
          },
        },
      };
    }
    case 'MAINTENANCE_SELECT_FLOORPLAN': {
      const id =
        action.id === state.selected.floorplans.selected ? null : action.id;
      const { viewed } = state.selected.floorplans;
      if (id) {
        viewed.push(id);
      }
      return {
        ...state,
        selected: {
          ...state.selected,
          floorplans: {
            ...state.selected.floorplans,
            selected: id,
            viewed,
          },
        },
      };
    }
    case 'MAINTENANCE_SELECT_NEXT_FLOORPLAN': {
      const current = state.selected.floorplans.selected;
      const viewedFps = state.selected.floorplans.viewed;
      let next;
      for (let i = 0; i < state.selected.floorplans.list.length; i += 1) {
        const f = state.selected.floorplans.list;
        if (f[i].id === current) {
          if (f[i + 1]) {
            next = f[i + 1].id;
            viewedFps.push(next);
            break;
          } else {
            next = null;
          }
        }
      }
      return {
        ...state,
        selected: {
          ...state.selected,
          floorplans: {
            ...state.selected.floorplans,
            selected: next,
            viewed: viewedFps,
          },
        },
      };
    }

    case 'MAINTENANCE_EDIT_FLOORPLAN':
      return {
        ...state,
        selected: {
          ...state.selected,
          floorplans: {
            ...state.selected.floorplans,
            list: state.selected.floorplans.list.map((el) => {
              if (el.id === action.data.id) {
                el[action.data.name] = action.data.value;
              }
              return el;
            }),
          },
        },
      };

    case 'SET_ALL_GET_NOTIFIED':
      return {
        ...state,
        setGetNotified: action.data,
        selected: {
          ...state.selected,
          floorplans: {
            ...state.selected.floorplans,
            list: state.selected.floorplans.list.map((el) => {
              el.get_notified = action.data;
              return el;
            }),
            selected: null,
          },
        },
      };

    case 'MAINTENANCE_UPDATE_FLOORPLAN_REQUEST':
      return {
        ...state,
        loadingUpdate: true,
      };
    case 'MAINTENANCE_UPDATE_FLOORPLAN_SUCCESS': {
      const currentUpdated = state.selected.floorplans.updated;
      currentUpdated.push(action.data);

      const currentSel = state.selected.floorplans.selected;
      const nowViewedFps = state.selected.floorplans.viewed;
      let nextFp;
      for (let i = 0; i < state.selected.floorplans.list.length; i += 1) {
        const f = state.selected.floorplans.list;
        if (f[i].id === currentSel) {
          if (f[i + 1]) {
            nowViewedFps.push(nextFp);
            break;
          }
        }
      }

      return {
        ...state,
        loadingUpdate: false,
        selected: {
          ...state.selected,
          floorplans: {
            ...state.selected.floorplans,
            selected: null,
            updated: currentUpdated,
            viewed: nowViewedFps,
          },
        },
      };
    }
    case 'MAINTENANCE_UPDATE_FLOORPLAN_FAILURE':
      return {
        ...state,
        loadingUpdate: false,
      };
    case 'MAINTENANCE_DESTROY_FLOORPLAN_REQUEST':
      return {
        ...state,
        loadingDelete: true,
      };
    case 'MAINTENANCE_DESTROY_FLOORPLAN_SUCCESS': {
      const currentfp = state.selected.floorplans.selected;
      const nowViewedList = state.selected.floorplans.viewed;
      let nextfp;
      for (let i = 0; i < state.selected.floorplans.list.length; i += 1) {
        const f = state.selected.floorplans.list;
        if (f[i].id === currentfp) {
          if (f[i + 1]) {
            nextfp = f[i + 1].id;
            nowViewedList.push(nextfp);
            break;
          } else {
            nextfp = null;
          }
        }
      }
      return {
        ...state,
        loadingDelete: false,
        selected: {
          ...state.selected,
          floorplans: {
            ...state.selected.floorplans,
            list: state.selected.floorplans.list.filter(
              (el) => el.id !== action.id,
            ),
            selected: nextfp,
            viewed: nowViewedList,
          },
        },
      };
    }
    case 'MAINTENANCE_DESTROY_FLOORPLAN_FAILURE':
      return {
        ...state,
        loadingDelete: false,
      };

    case 'UPDATE_ALL_FLOORPLANS_MAINTENANCE_REQUEST':
      return {
        ...state,
        loadingUpdateAll: true,
      };
    case 'UPDATE_ALL_FLOORPLANS_MAINTENANCE_SUCCESS':
      return {
        ...state,
        loadingUpdateAll: false,
        selected: {
          ...state.selected,
          updated: true,
        },
      };
    case 'UPDATE_G5_BOT_SUCCESS':
      return {
        ...state,
        loadingUpdateAll: false,
        selected: {
          ...state.selected,
          updated: true,
        },
      };
    case 'UPDATE_ALL_FLOORPLANS_MAINTENANCE_FAILURE':
      return {
        ...state,
        loadingUpdateAll: false,
      };
    case 'RESET_MAINTENANCE_PROPERTY':
      return defaultState;

    case 'SELECT_MAINTENANCE_USER':
      return {
        ...state,
        selectedUser: action.id,
      };

    case 'SELECT_MAINTENANCE_COMPANY':
      return {
        ...state,
        selectedCompany: action.id,
      };

    case 'RESET_MAINTENANCE_LIST':
      return {
        ...state,
        list: [],
      };

    case 'MAINTENANCE_EDIT_NOTE':
      return {
        ...state,
        selected: {
          ...state.selected,
          info: {
            ...state.selected.info,
            note: action.note,
          },
        },
      };

    case 'GET_NEXT_COMPANY_LIST_SUCCESS':
      return {
        ...state,
        nextList: action.data,
      };

    case 'MAINTENANCE_SAVE_NOTE_REQUEST':
      return {
        ...state,
        loadingBotNote: true,
      };
    case 'MAINTENANCE_SAVE_NOTE_SUCCESS':
      return {
        ...state,
        loadingBotNote: false,
      };
    case 'MAINTENANCE_SAVE_NOTE_FAILURE':
      return {
        ...state,
        loadingBotNote: false,
      };

    case 'REMOVE_CURRENT_BOT_LIST':
      return {
        ...state,
        list: [],
      };

    case 'SET_NEXT_BOT': {
      let nextBot = -1;
      if (state.list.length) {
        let i = 0;
        for (i = 0; i < state.list.length; i++) {
          const tenant = state.list[i];
          if (tenant.tid == state.selected.tid) {
            nextBot = i;
            break;
          }
        }

        if (nextBot >= 0 && state.list[nextBot + 1]) {
          nextBot = {
            tid: state.list[nextBot + 1].tid,
            name: state.list[nextBot + 1].name,
            index: i + 2,
          };
        } else {
          nextBot = null;
        }
      }

      return {
        ...state,
        selected: {
          ...state.selected,
          nextBot,
        },
      };
    }

    case 'GET_TERMS_REQUEST':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            loading: true,
          },
        },
      };

    case 'GET_TERMS_SUCCESS':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            loading: false,
            list: action.data,
          },
        },
      };

    case 'GET_TERMS_FAILURE':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            loading: false,
          },
        },
      };

    case 'TOGGLE_CREATE_TERM':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            create: !state.selected.terms.create,
          },
        },
      };
    case 'TOGGLE_EDIT_TERM': {
      let currentEdit = state.selected.terms.selected;
      if (state.selected.terms.edit) {
        currentEdit = null;
      }
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            edit: currentEdit,
          },
        },
      };
    }

    case 'TOGGLE_DELETE_TERM': {
      let currentDelete = state.selected.terms.selected;
      if (state.selected.terms.delete) {
        currentDelete = null;
      }
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            delete: currentDelete,
          },
        },
      };
    }

    case 'CREATE_TERM_REQUEST':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            createLoading: true,
          },
        },
      };

    case 'CREATE_TERM_SUCCESS':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            createLoading: false,
            create: false,
          },
        },
      };

    case 'CREATE_TERM_FAILURE':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            createLoading: false,
          },
        },
      };

    case 'EDIT_TERM_REQUEST':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            editLoading: true,
          },
        },
      };

    case 'EDIT_TERM_SUCCESS':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            edit: null,
            editLoading: false,
          },
        },
      };

    case 'EDIT_TERM_FAILURE':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            editLoading: false,
          },
        },
      };

    case 'SELECT_TERM_REQUEST':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            loading: true,
          },
        },
      };
    case 'SELECT_TERM_SUCCESS':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            selected: action.data.term.id,
            selectedData: action.data,
            loading: false,
          },
        },
      };
    case 'SELECT_TERM_FAILURE':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            selectedData: {},
            selected: null,
            loading: false,
          },
        },
      };

    case 'DESELECT_TERM':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            selected: action.data,
            selectedData: {},
            loading: false,
          },
        },
      };

    case 'UPDATE_TERM_FLOORPLANS_REQUEST':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            loadingSubmit: true,
          },
        },
      };

    case 'UPDATE_TERM_FLOORPLANS_SUCCESS':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            loadingSubmit: false,
            updated: [
              ...state.selected.terms.updated,
              state.selected.terms.selected,
            ],
          },
        },
      };

    case 'UPDATE_TERM_FLOORPLANS_FAILURE':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            loadingSubmit: false,
          },
        },
      };

    case 'FETCH_CAMPUS_REQUEST':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            loadingSubmit: true,
          },
        },
      };
    case 'FETCH_CAMPUS_SUCCESS':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            campusUpdated: [...state.selected.terms.campusUpdated, action.data],
            loadingSubmit: false,
          },
        },
      };
    case 'FETCH_CAMPUS_FAILURE':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            loadingSubmit: false,
            campusFailed: [...state.selected.terms.campusFailed, action.data]
          },
        },
      };

    case 'DELETE_TERM_REQUEST':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            deleteLoading: true,
          },
        },
      };

    case 'DELETE_TERM_SUCCESS':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            deleteLoading: false,
            delete: null,
          },
        },
      };

    case 'DELETE_TERM_FAILURE':
      return {
        ...state,
        selected: {
          ...state.selected,
          terms: {
            ...state.selected.terms,
            deleteLoading: false,
          },
        },
      };

    case 'GET_BOTS_WITH_MISSED_MAINTENANCE_REQUEST':
      return {
        ...state,
        missed: [],
        missedLoading: true,
      }

    case 'GET_BOTS_WITH_MISSED_MAINTENANCE_FAILURE':
      return {
        ...state,
        missed: [],
        missedLoading: false,
      }

    case 'GET_BOTS_WITH_MISSED_MAINTENANCE_SUCCESS':
      return {
        ...state,
        missed: action.data,
        missedLoading: false,
      }
    default:
      return state;
  }
}
