/* eslint-disable no-case-declarations */
/* eslint-disable space-before-blocks */
/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
export function wps(state = {
  list: [],
  defs: [],
  selected: {},
  loading: false,
}, action) {
  switch (action.type) {
    case 'GETALL_WPS_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'GETALL_WPS_SUCCESS':
      console.log('==========================')
      console.log(action.res);
      console.log('==========================')
      return {
        ...state,
        loading: false,
        list: action.res.wps,
        defs: action.res.defs,
      };

    case 'CREATE_WP_REQUEST':
      return {
        ...state,
        createLoading: true,
      };
    case 'CREATE_WP_SUCCESS':
      action.wp.Definitions = [];
      return {
        ...state,
        list: [...state.list, action.wp],
      };
    case 'CREATE_WP_FAILURE':
      return {
        ...state,
        error: action.error,
      };

    case 'UPDATE_WP_REQUEST':
      return {
        ...state,
        loadingUpdate: true,
      };
    case 'UPDATE_WP_SUCCESS':
      return {
        ...state,
        list: state.list.map(
          (el) => (el.id === action.payload.id ? { ...el, name: action.payload.name } : el),
        ),
      };
    case 'UPDATE_WP_FAILURE':
      return {
        ...state,
        error: action.error,
      };

    case 'DELETE_WP_REQUEST':
      return {
        ...state,
        deleteLoading: true,
      };
    case 'DELETE_WP_SUCCESS':
      return {
        ...state,
        deleteLoading: false,
        list: state.list.filter((el) => el.id !== action.id),
      };

    case 'CREATE_WP_DEFINITION_REQUEST':
      return {
        ...state,
        loadingDef: true,
      };
    case 'CREATE_WP_DEFINITION_SUCCESS':
      return {
        ...state,
        loadingDef: false,
        list: state.list.map(
          (el) => (el.id === action.data.wpId ? { ...el, el: el.Definitions.push(action.data) } : el),
        ),
      };

    case 'DELETE_WP_DEFINITION_REQUEST':
      return {
        ...state,
        loadingDeleteWP: true,
      };
    case 'DELETE_WP_DEFINITION_SUCCESS':
      let list = {};
      let { selected } = state;
      if (state.list.length) {
        const newWP = state.list.filter((el) => el.id === action.data.wp_id);
        const newDef = newWP[0].Definitions.filter((el) => el.id !== action.data.id);
        newWP[0].Definitions = newDef;
        list = state.list.map((el) => (el.id === action.data.wp_id ? newWP[0] : el));
      } else {
        list = state.list;
      }
      if (Object.keys(state.selected).length) {
        selected.Definitions = selected.Definitions.filter(el => el.id !== action.data.id);
      }
      return {
        ...state,
        list: list,
        selected: selected,
      };

    case 'SELECT_WP_SUCCESS':
      return {
        ...state,
        selected: action.data,
      }
    default:
      return state;
  }
}
