import { sendRequest } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function get(){
  const response = await sendRequest(
    'GET',
    `${API_URL}/notifications`,
  );
  return response;
}

async function getGroups(){
  const response = await sendRequest(
    'GET',
    `${API_URL}/notifications/groups`
  );
  return response;
}

async function subscribe(id, subscribed) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/notifications/settings/update`,
    { id, subscribed }
  )

  return response;
}

async function seen() {
  const response = await sendRequest(
    'GET',
    `${API_URL}/notifications/seen`
  );

  return response;
}

async function getAll() {
  const response = await sendRequest(
    'GET',
    `${API_URL}/notifications/all`
  )
  return response;
}

export const notificationsService = {
  get,
  getGroups,
  subscribe,
  seen,
  getAll,
}