/* eslint-disable import/prefer-default-export */
export function report(
  state = {
    list: [],
    fieldDetails: {},
    details: {},
    templates: [],
    viewLoading: false,
    getReportsLoading: false,
    name: '',
    data: [],
    build: {
      list: [],
      fields: [],
      selected: {},
      testJson: {},
      highlight: 0,
      createNewField: false,
      templateLoading: false,
      createReportLoading: false,
      loadingList: false,
      loadingExport: false,
      loadingForceStop: false,
    },
    maintenance: [],
    definitions: [],
    definitionsLoading: false,
    loadingFields: false,
  },
  action,
) {
  switch (action.type) {
    case 'GET_FIELDS_REQUEST':
      return {
        ...state,
        loadingFields: true,
      };

    case 'GET_FIELDS_SUCCESS':
      return {
        ...state,
        build: {
          ...state.build,
          fields: action.data,
        },
        loadingFields: false,
      };
    case 'GET_FIELDS_FAILURE':
      return {
        ...state,
        loadingFields: false,
      };

    case 'TOGGLE_CREATE_NEW_FIELD':
      return {
        ...state,
        build: {
          ...state.build,
          createNewField: !state.build.createNewField,
        },
      };

    case 'TEST_TEMPLATE_MODULE_REQUEST':
      return {
        ...state,
        build: {
          ...state.build,
          templateLoading: true,
        },
      };

    case 'TEST_TEMPLATE_MODULE_SUCCESS':
      return {
        ...state,
        build: {
          ...state.build,
          testJson: action.data,
          templateLoading: false,
        },
      };
    case 'TEST_TEMPLATE_MODULE_FAILURE':
      return {
        ...state,
        build: {
          ...state.build,
          templateLoading: false,
        },
      };

    case 'CREATE_REPORT_REQUEST':
      return {
        ...state,
        build: {
          ...state.build,
          createReportLoading: true,
        },
      };
    case 'CREATE_REPORT_SUCCESS':
      return {
        ...state,
        build: {
          ...state.build,
          createReportLoading: false,
        },
      };
    case 'CREATE_REPORT_FAILURE':
      return {
        ...state,
        build: {
          ...state.build,
          createReportLoading: false,
        },
      };

    case 'GET_RECENT_REPORT_LIST_REQUEST':
      return {
        ...state,
        build: {
          ...state.build,
          loadingList: true,
        },
      };
    case 'GET_RECENT_REPORT_LIST_FAILURE':
      return {
        ...state,
        build: {
          ...state.build,
          loadingList: true,
        },
      };
    case 'GET_RECENT_REPORT_LIST_SUCCESS':
      return {
        ...state,
        build: {
          ...state.build,
          list: action.data,
          loadingList: false,
        },
      };

    case 'SELECT_REPORT':
      return {
        ...state,
        build: {
          ...state.build,
          selected: action.report,
        },
      };

    case 'DESELECT_REPORT':
      return {
        ...state,
        build: {
          ...state.build,
          selected: {},
        },
      };

    case 'UPDATE_REPORT':
      return {
        ...state,
        build: {
          ...state.build,
          list: state.build.list.map((el) => {
            if (el.id === action.data.id) {
              return action.data;
            }
            return el;
          }),
        },
      };

    case 'EXPORT_REPORT_REQUEST':
      return {
        ...state,
        build: {
          ...state.build,
          loadingExport: true,
        },
      };

    case 'EXPORT_REPORT_SUCCESS':
      return {
        ...state,
        build: {
          ...state.build,
          loadingExport: false,
          selected: {
            ...state.build.selected,
            Exports: [...state.build.selected.Exports, action.data.data],
          },
          list: state.build.list.map((rep) => {
            if (rep.id === action.data.data.reportId) {
              rep.Exports.push(action.data.data);
            }
            return rep;
          }),
        },
      };

    case 'EXPORT_REPORT_FAILURE':
      return {
        ...state,
        build: {
          ...state.build,
          loadingExport: false,
        },
      };

    case 'HIGHLIGHT_EXPORTED_REPORT':
      return {
        ...state,
        build: {
          ...state.build,
          highlight: action.exportId,
        },
      };

    case 'REMOVE_HIGHLIGHT':
      return {
        ...state,
        build: {
          ...state.build,
          highlight: 0,
        },
      };

    case 'UPDATE_EXPORT_DATA':
      return {
        ...state,
        build: {
          ...state.build,
          selected: {
            ...state.build.selected,
            Exports: state.build.selected.Exports.map((exp) => {
              if (exp.id === action.data.id) {
                return action.data;
              }
              return exp;
            }),
          },
          list: state.build.list.map((rep) => {
            if (rep.id === action.data.reportId) {
              rep.Exports = rep.Exports.map((exp) => {
                if (exp.id === action.data.id) {
                  return action.data;
                }
                return exp;
              });
            }
            return rep;
          }),
        },
      };

    case 'GET_MAINTENANCE_REPORT_REQUEST':
      return {
        ...state,
        loadingMaintenance: true,
      };
    case 'GET_MAINTENANCE_REPORT_FAILURE':
      return {
        ...state,
        loadingMaintenance: false,
      };
    case 'GET_MAINTENANCE_REPORT_SUCCESS':
      return {
        ...state,
        maintenance: action.data.result,
        definitions: action.data.definitions || [],
        loadingMaintenance: false,
      };

    case 'GET_REPORTS_REQUEST':
      return {
        ...state,
        getReportsLoading: true,
      };
    case 'GET_REPORTS_SUCCESS':
      return {
        ...state,
        getReportsLoading: false,
        list: action.data,
      };
    case 'GET_REPORTS_FAILURE':
      return {
        ...state,
        getReportsLoading: false,
      };

    case 'REORDER_REPORTS':
      return {
        ...state,
        list: action.data,
      };

    case 'VIEW_REPORT_REQUEST':
      return {
        ...state,
        name: '',
        data: [],
        details: {},
        templates: [],
        viewLoading: true,
      };

    case 'VIEW_REPORT_SUCCESS':
      return {
        ...state,
        viewLoading: false,
        data: action.data.report,
        name: action.data.name,
        details: action.data.details,
        templates: action.data.templates,
      };

    case 'VIEW_REPORT_FAILURE':
      return {
        ...state,
        viewLoading: false,
      };

    case 'GET_SCRAPER_DEFINITIONS_REPORT_REQUEST':
      return {
        ...state,
        definitionsLoading: true,
      };

    case 'GET_SCRAPER_DEFINITIONS_REPORT_SUCCESS':
      return {
        ...state,
        definitions: action.data,
        definitionsLoading: false,
      };

    case 'GET_SCRAPER_DEFINITIONS_REPORT_FAILURE':
      return {
        ...state,
        definitionsLoading: false,
      };

    case 'FORCE_STOP_REQUEST':
      return {
        ...state,
        build: {
          ...state.build,
          loadingForceStop: true,
        }
      }

    case 'FORCE_STOP_SUCCESS':
      return {
        ...state,
        build: {
          ...state.build,
          list: state.build.list.map((el) => {
            if(el.id === action.data.id){
              return action.data;
            }
            return el;
          }),
          loadingForceStop: false,
        }
      }

    case 'FORCE_STOP_FAILURE':
      return {
        ...state,
        build: {
          ...state.build,
          loadingForceStop: false,
        }
      }
    case 'GET_FIELD_DETAILS_REQUEST':
      return {
        ...state,
        fieldDetails: {},
        loadingFields: true,
      }
    case 'GET_FIELD_DETAILS_SUCCESS':
      return {
        ...state,
        fieldDetails: action.data,
        loadingFields: false,
      }
    case 'GET_FIELD_DETAILS_FAILURE':
      return {
        ...state,
        fieldDetails: {},
        loadingFields: false,
      }

    case 'EDIT_FIELD_DETAILS':
    return {
      ...state,
      fieldDetails: {
        ...state.fieldDetails,
        [action.data.field]: action.data.value
      }
    }

    case 'UPDATE_FIELD_DETAILS_REQUEST':
      return {
        ...state,
        loadingFields: true
      }
    case 'UPDATE_FIELD_DETAILS_SUCCESS':
      return {
        ...state,
        loadingFields: false
      }
    case 'UPDATE_FIELD_DETAILS_FAILURE':
      return {
        ...state,
        loadingFields: false
      }
    default:
      return state;
  }
}
