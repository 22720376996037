import {settingsService} from '../_services';

export const settingsActions = {
    crons,
    change
}

function crons(){
    return dispatch => {
        dispatch({type: 'SETTINGS_CRONS_REQUEST'});
        settingsService.crons().then(
            result => {
                dispatch({type: 'SETTINGS_CRONS_SUCCESS', data: result})
            },
            error => {
                dispatch({type: 'SETTINGS_CRONS_FAILURE'})
                dispatch({type: 'ALERT_ERROR', error: error});
            }
        )
    }
}

function change(name, value){
    return dispatch => {
        dispatch({type: 'SETTINGS_CRONS_REQUEST'});
        settingsService.updateSettings(name, value).then(
            result => {
                dispatch({type: 'CHANGE_SETTINGS', data: {name: name, value: value}});
                dispatch({type: 'ALERT_SUCCESS', message: result.message});
            },
            error => {
                dispatch({type: 'ALERT_ERROR', error: error});
            }
        )
    }
}