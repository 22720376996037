import { sendRequest } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function get(tid) {
  const response = await sendRequest('GET', `${API_URL}/maintenance/terms/${tid}`);
  return response;
}

async function create(tid, data) {
  const response = await sendRequest('POST', `${API_URL}/maintenance/terms/${tid}/create`, data);
  return response;
}

async function edit(tid, id, data) {
  const response = await sendRequest('POST', `${API_URL}/maintenance/terms/${tid}/edit/${id}`, data);
  return response;
}

async function destroy(tid, id) {
  const response = await sendRequest('DELETE', `${API_URL}/maintenance/terms/${tid}/delete/${id}`);
  return response;
}

async function select(tid, id) {
  const response = await sendRequest('GET', `${API_URL}/maintenance/terms/${tid}/select/${id}`);
  return response;
}

async function updateTermFloorplans(tid, data, id) {
  const response = await sendRequest('POST', `${API_URL}/maintenance/terms/${tid}/updateFloorplans/${id}`, data);
  return response;
}

async function fetchCampus(tid, id, url, term) {
  const response = await sendRequest('POST', `${API_URL}/maintenance/terms/${tid}/fetchCampus/${id}`, { url, term });
  return response;
}

export const termsService = {
  get,
  create,
  edit,
  destroy,
  select,
  updateTermFloorplans,
  fetchCampus,
};
