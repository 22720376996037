const defaultState = {
  list: [],
  all: [],
  groups: {},
  loading: false,
  lastSeen: 'init',
}

export function notifications(state = defaultState, action) {
  switch (action.type) {
    case 'GET_NOTIFICATIONS_REQUEST':
      return {
        ...state,
      }

    case 'GET_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        list: action.data.list,
        lastSeen: action.data.lastSeen
      }

    case 'GET_NOTIFICATIONS_FAILURE':
      return {
        ...state,
      }

    case 'GET_NOTIFICATION_GROUPS_REQUEST':
      return {
        ...state,
        loading: true,
      }

    case 'GET_NOTIFICATION_GROUPS_SUCCESS':
      return {
        ...state,
        loading: false,
        groups: action.data,
      }

    case 'GET_NOTIFICATION_GROUPS_FAILURE':
      return {
        ...state,
        loading: false,
      }

    case 'SUBSCRIBE_REQUEST':
      return {
        ...state,
        loading: true
      }

    case 'SUBSCRIBE_FAILURE':
      return {
        ...state,
        loading: false,
      }

    case 'SEEN_SUCCESS':
      return {
        ...state,
        lastSeen: action.data
      }

    case 'GET_ALL_NOTIFICATIONS_REQUEST':
      return {
        ...state,
        loading: true,
      }

    case 'GET_ALL_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        loading: false,
        all: action.data.notifications,
        lastSeen: action.data.lastSeen
      }

    case 'GET_ALL_NOTIFICATIONS_FAILURE':
      return {
        ...state,
        loading: false
      }

    default:
      return state;
  }
}