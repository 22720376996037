export function property(state = {
    specials: {
        default: 'NESTO NESTO NESTO',
        list: [
            {
                text: 'No specials now',
                from: '11/25/2020',
                to: '12/10/2020'
            }
        ]
    },
    gallery: {
        categories: [
            {
                id: 1,
                name: "Apartments",
                images: []
            },
            {
                id: 2,
                name: "Amenities",
                images: []
            }
        ]
    },
    options: {
        images: true,
        description: true,
        prices: true,
        status: true,
        icons: true
    },
    appointments: [
        {
            day: 'monday',
            closed: false
        },
        {
            day: 'tuesday',
            closed: false
        },
        {
            day: 'wednesday',
            closed: false
        },
        {
            day: 'thursday',
            closed: false
        },
        {
            day: 'friday',
            closed: false
        },
        {
            day: 'saturday',
            closed: false
        },
        {
            day: 'sunday',
            closed: false
        },
    ],
    floorplans: [
        {
            id: 1,
            name: 'A1',
            image: 'https://placehold.it/150x100',
            bedrooms: 1,
            bathrooms: 2,
            price_from: 1500,
            price_to: 1700,
            description: 'nesto nesto nesto',
            active: 1
        },
        {
            id: 2,
            name: 'A2',
            image: 'https://placehold.it/150x100',
            bedrooms: 2,
            bathrooms: 3,
            price_from: 1500,
            price_to: 1700,
            description: 'nesto nesto nesto',
            active: 1
        }
    ]
}, action){
    switch (action.type) {
        
        
        case 'ADD_NEW_SPECIAL':
            return {
                ...state,
                specials: {
                    ...state.specials,
                    list: [...state.specials.list, action.data]
                }
            }

        case 'SELECT_PROPERTY':
            return {
                ...state,
                floorplans: state.floorplans.map(el => {
                    if(el.id === action.id){
                        el.status = el.status || 'Viewed';
                    }
                    return el;
                }),
                selected: action.id
            }

        case 'REORDER_FLOORPLANS':
            let i = 0;
            return {
                ...state,
                floorplans: action.floorplans.map(el => {
                    el.weight = i++;
                    return el;
                })
            }

        case 'DESELECT_FLOORPLAN':
            return {
                ...state,
                selected: 0
            }

        case 'CHANGE_OPTIONS':
            return {
                ...state,
                options: {
                    ...state.options,
                    [action.name]: !state.options[action.name]
                }
            }
        

        case 'ADD_NEW_IMAGE':
            return {
                ...state,
                gallery: {
                    ...state.gallery,
                    categories: state.gallery.categories.map(el => {
                        if(el.name === action.data.galleryName){
                            el.images = [...el.images, {name: action.data.name, weight: el.images.length}]
                        }
                        return el
                    })
                }
            }

        case 'REORDER_GALLERY_IMAGES':
            let j = 0;
            return {
                ...state,
                gallery: {
                    ...state.gallery,
                    categories: state.gallery.categories.map(el => {
                        if(el.name === action.data.galleryName){
                            el.images = action.data.images.map(img => {
                                img.weight = j++;
                                return img;
                            })
                        }
                        return el
                    })
                }
            }
        default:
            return state
    }
}