import { reportService } from '../_services';

function forceStop(id) {
  return (dispatch) => {
    dispatch({type: 'FORCE_STOP_REQUEST'});

    reportService.forceStop(id).then(
      (res) => {
        dispatch({type: 'ALERT_SUCCESS', message: res.message});
        dispatch({type: 'FORCE_STOP_SUCCESS', data: res.report});
        dispatch(getRecentReportList());
      },
      (err) => {
        dispatch({type: 'ALERT_ERROR', error: err});
        dispatch({type: 'FORCE_STOP_FAILURE'});
      }
    )
  }
}

function getFields() {
  return (dispatch) => {
    dispatch({ type: 'GET_FIELDS_REQUEST' });

    reportService.getFields().then(
      (data) => {
        dispatch({ type: 'GET_FIELDS_SUCCESS', data });
      },
      (error) => {
        dispatch({ type: 'GET_FIELDS_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function toggleCreateField() {
  return (dispatch) => {
    dispatch({ type: 'TOGGLE_CREATE_NEW_FIELD' });
  };
}

function testTemplate(jsonData) {
  return (dispatch) => {
    dispatch({ type: 'TEST_TEMPLATE_MODULE_REQUEST' });

    reportService.testTemplateField(jsonData).then(
      (result) => {
        dispatch({ type: 'TEST_TEMPLATE_MODULE_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'TEST_TEMPLATE_MODULE_SUCCESS', data: error });
      },
    );
  };
}

function createTemplate(name, jsonData) {
  return (dispatch) => {
    dispatch({ type: 'CREATE_TEMPLATE_REQUEST' });

    reportService.createTemplateField(name, jsonData).then(
      (result) => {
        dispatch({ type: 'CREATE_TEMPLATE_SUCCESS' });
        dispatch(toggleCreateField());
        dispatch(getFields());
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
      },
      (error) => {
        dispatch({ type: 'CREATE_TEMPLATE_FAILURE' });
      },
    );
  };
}

function getRecentReportList() {
  return (dispatch) => {
    dispatch({ type: 'GET_RECENT_REPORT_LIST_REQUEST' });

    reportService.getRecentReportList().then(
      (data) => {
        dispatch({ type: 'GET_RECENT_REPORT_LIST_SUCCESS', data });
      },
      (error) => {
        dispatch({ type: 'GET_RECENT_REPORT_LIST_FAILURE', error });
      },
    );
  };
}

function getReportList() {
  return (dispatch) => {
    dispatch({ type: 'GET_REPORT_LIST_REQUEST' });

    reportService.getReportList().then(
      (data) => {
        dispatch({ type: 'GET_REPORT_LIST_SUCCESS', data });
      },
      (error) => {
        dispatch({ type: 'GET_REPORT_LIST_FAILURE', error });
      },
    );
  };
}

function createReport(data) {
  return (dispatch) => {
    dispatch({ type: 'CREATE_REPORT_REQUEST' });
    reportService.createReport(data).then(
      (result) => {
        dispatch({ type: 'CREATE_REPORT_SUCCESS' });
        dispatch({ type: 'ALERT_SUCCESS', message: result.message });
        dispatch(getReportList());
      },
      (error) => {
        dispatch({ type: 'CREATE_REPORT_FAILURE' });
      },
    );
  };
}

function selectReport(report) {
  return (dispatch) => {
    if (report.status === 'finished') {
      dispatch({ type: 'SELECT_REPORT', report });
    }
  };
}

function deselectReport() {
  return (dispatch) => {
    dispatch({ type: 'DESELECT_REPORT' });
  };
}

function updateReport(data) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_REPORT', data });
  };
}

function exportReport(format, id) {
  return (dispatch) => {
    dispatch({ type: 'REMOVE_HIGHLIGHT' });
    dispatch({ type: 'EXPORT_REPORT_REQUEST' });

    reportService.exportReport(format, id).then(
      (data) => {
        dispatch({ type: 'EXPORT_REPORT_SUCCESS', data });
      },
      (error) => {
        dispatch({ type: 'EXPORT_REPORT_FAILURE' });
        if (error.status === 303) {
          dispatch({
            type: 'HIGHLIGHT_EXPORTED_REPORT',
            exportId: error.data.id,
          });
        } else {
          dispatch({ type: 'ALERT_ERROR', error });
        }
      },
    );
  };
}

function getMaintenanceReport() {
  return (dispatch) => {
    dispatch({ type: 'GET_MAINTENANCE_REPORT_REQUEST' });

    reportService.getMaintenanceReport().then(
      (data) => {
        dispatch({ type: 'GET_MAINTENANCE_REPORT_SUCCESS', data });
      },
      (error) => {
        dispatch({ type: 'GET_MAINTENANCE_REPORT_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function updateExportData(data) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_EXPORT_DATA', data });
  };
}

function getReports() {
  return (dispatch) => {
    dispatch({ type: 'GET_REPORTS_REQUEST' });
    reportService.getReportList().then(
      (data) => {
        dispatch({ type: 'GET_REPORTS_SUCCESS', data });
      },
      (error) => {
        dispatch({ type: 'GET_REPORTS_FAILURE', error });
      },
    );
  };
}

function clearReports() {
  return (dispatch) => {
    dispatch({ type: 'CLEAR_REPORTS' });
  };
}

function reorderReports(data) {
  return (dispatch) => {
    dispatch({ type: 'REORDER_REPORTS', data });
  };
}

function addFolder(name) {
  return (dispatch) => {
    dispatch({ type: 'ADD_FOLDER_REQUEST' });

    reportService.addFolder(name).then(
      (result) => {
        dispatch({ type: 'ALERT_SUCCESS', result });
        dispatch(getReports());
      },
      (error) => {
        dispatch({ type: 'ALERT_FAILURE', error });
        dispatch({ type: 'ADD_FOLDER_FAILURE' });
      },
    );
  };
}

function moveFolderOrReport(id, parentId, isFolder) {
  return (dispatch) => {
    dispatch({ type: 'MOVE_FOLDER_REQUEST' });
    reportService.moveFolderOrReport({ id, parentId, isFolder }).then(
      (result) => {
        dispatch({ type: 'MOVE_FOLDER_SUCCESS' });
      },
      (error) => {
        dispatch({ type: 'ALERT_FAILURE', error });
        dispatch({ type: 'MOVE_FOLDER_FAILURE' });
      },
    );
  };
}

function viewReport(id) {
  return (dispatch) => {
    dispatch({ type: 'VIEW_REPORT_REQUEST' });
    reportService.viewReport(id).then(
      (result) => {
        dispatch({
          type: 'VIEW_REPORT_SUCCESS',
          data: { report: result.data, name: result.name, details: result.details, templates: result.templates },
        });
      },
      (error) => {
        dispatch({ type: 'ALERT_ERROR', error });
        dispatch({ type: 'VIEW_REPORT_FAILURE' });
      },
    );
  };
}

function getScraperDefinitionsReport() {
  return (dispatch) => {
    dispatch({ type: 'GET_SCRAPER_DEFINITIONS_REPORT_REQUEST' });

    reportService.scraperDefinitions().then(
      (result) => {
        dispatch({ type: 'GET_SCRAPER_DEFINITIONS_REPORT_SUCCESS', data: result });
      },
      (error) => {
        dispatch({ type: 'GET_SCRAPER_DEFINITIONS_REPORT_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function getFieldDetails(selected){
  return (dispatch) => {
    dispatch({type: 'GET_FIELD_DETAILS_REQUEST'});

    reportService.getFieldDetails(selected).then(
      (res) => {
        dispatch({type: 'GET_FIELD_DETAILS_SUCCESS', data: res.details});
      },
      (err) => {
        dispatch({type: 'GET_FIELD_DETAILS_FAILURE'});
      }
    )
  }
}

function updateTemplate(data) {
  return (dispatch) => {
    dispatch({type: 'UPDATE_FIELD_DETAILS_REQUEST'});

    reportService.updateTemplate(data).then(
      (res) => {
        dispatch({type: 'ALERT_SUCCESS', message: res.message});
        dispatch({type: 'UPDATE_FIELD_DETAILS_SUCCESS'});
      },
      (err) => {
        dispatch({type: 'UPDATE_FIELD_DETAILS_FAILURE'});
      }
    )
  }
}

function editDetails(name, value) {
  return (dispatch) => {
    dispatch({type: 'EDIT_FIELD_DETAILS', data: {field: name, value}});
  }
}

export const reportActions = {
  getFields,
  toggleCreateField,
  testTemplate,
  createTemplate,
  createReport,
  getReportList,
  selectReport,
  deselectReport,
  updateReport,
  exportReport,
  updateExportData,
  getMaintenanceReport,
  getReports,
  clearReports,
  reorderReports,
  getRecentReportList,
  addFolder,
  moveFolderOrReport,
  viewReport,
  getScraperDefinitionsReport,
  forceStop,
  getFieldDetails,
  updateTemplate,
  editDetails,
};
