import { sendRequest } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function search(query) {
  const response = await sendRequest('GET', `${API_URL}/search/${query}`);
  return response;
}

export const searchService = {
  search,
};
