import { sendRequest } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function getAlerts(filters) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/alerts/list`,
    filters,
  );
  return response;
}

async function addAlertException(id) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/alert/exception/${id}`,
  );
  return response;
}

async function resolve(id) {
  const response = await sendRequest(
    'DELETE',
    `${API_URL}/alert/resolve/${id}`,
  );
  return response;
}

async function getComment(tenantId, alertId) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/alert/get/comment/${tenantId || 0}/${alertId}`,
  );
  return response;
}

async function saveComment(tenantId, alertId, comment) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/alert/save/comment/${tenantId || 0}/${alertId}`,
    { comment },
  );
  return response;
}

async function getExceptionList() {
  const response = await sendRequest(
    'GET',
    `${API_URL}/exceptions`,
  );
  return response;
}

async function deleteException(tenantId, alertId) {
  const response = await sendRequest(
    'DELETE',
    `${API_URL}/delete/exception/${tenantId || 0}/${alertId}`,
  );
  return response;
}

async function resolveList(action, company) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/alert/resolved/list/${action}/${company}`,
  );
  return response;
}

async function resolveMultipleList(action, alertsInfo) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/alert/multiple/resolved/list/${action}/${alertsInfo}`,
  );
  return response;
}

export const alertService = {
  getAlerts,
  addAlertException,
  resolve,
  getComment,
  saveComment,
  getExceptionList,
  deleteException,
  resolveList,
  resolveMultipleList
};
