import { botService } from '../_services';

function select(id) {
  return (dispatch) => {
    dispatch({ type: 'SELECT_BOT_REQUEST' });

    botService.select(id).then(
      (result) => {
        dispatch({ type: 'SELECT_BOT_SUCCESS', data: result.bots });
      },
      (error) => {
        dispatch({ type: 'SELECT_BOT_FAILURE', error });
      },
    );
  };
}

function info(id) {
  return (dispatch) => {
    dispatch({ type: 'BOT_INFO_REQUEST' });

    botService.info(id).then(
      (result) => {
        dispatch({ type: 'BOT_INFO_SUCCESS', data: result.data });
      },
      (error) => {
        dispatch({ type: 'BOT_INFO_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function change(name, value) {
  return (dispatch) => {
    dispatch({ type: 'CHANGE_BOT_DATA', field: { name, value } });
  };
}

function update(data) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_BOT_REQUEST' });

    botService.update(data).then(
      (result) => {
        dispatch({ type: 'ALERT_SUCCESS', message: result.data });
        dispatch({ type: 'UPDATE_BOT_SUCCESS' });
      },
      (error) => {
        dispatch({ type: 'UPDATE_BOT_FAILURE', error });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function deselect() {
  return (dispatch) => {
    dispatch({ type: 'DESELECT_BOT' });
  };
}

function watch(botId, toggle) {
  return (dispatch) => {
    dispatch({ type: 'WATCH_BOT_REQUEST' });

    botService.watch(botId, toggle).then(
      (result) => {
        dispatch({ type: 'ALERT_SUCCESS', message: result.data });
        dispatch({ type: 'WATCH_BOT_SUCCESS', data: { id: botId, toggle } });
      },
      (error) => {
        dispatch({ type: 'WATCH_BOT_FAILURE', error });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function testEmbedded(bot) {
  return (dispatch) => {
    dispatch({ type: 'TEST_EMBEDDED_REQUEST' });

    botService.testEmbedded(bot).then(
      (res) => {
        dispatch({ type: 'ALERT_SUCCESS', message: res.message });
        dispatch({ type: 'TEST_EMBEDDED_SUCCESS' });
      },
      (error) => {
        dispatch({ type: 'ALERT_ERROR', error });
        dispatch({ type: 'TEST_EMBEDDED_FAILURE' });
      },
    );
  };
}

function login(bot) {
  return (dispatch) => {
    dispatch({ type: 'LOGIN_BOT_REQUEST' });
    botService.login(bot).then(
      (res) => {
        dispatch({ type: 'ALERT_SUCCESS', message: res.message });
        dispatch({ type: 'LOGIN_BOT_SUCCESS' });
        window.open(res.url, '_blank').focus();
      },
      (error) => {
        dispatch({ type: 'ALERT_ERROR', error });
        dispatch({ type: 'LOGIN_BOT_FAILURE' });
      },
    );
  };
}

function autoupdates(tid, status) {
  return (dispatch) => {
    console.log(tid, status);
    botService.autoupdate(tid, status).then(
      (res) => {
        dispatch({type: 'ALERT_SUCCESS', message: res.message})
      },
      (err) => {
        dispatch({type: 'ALERT_FAILURE', error: err})
      }
    )
  }
}

export const botActions = {
  select,
  info,
  change,
  update,
  deselect,
  watch,
  testEmbedded,
  login,
  autoupdates
};
