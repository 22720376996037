import { scraperService } from '../_services';

function scrape(tid) {
  return (dispatch) => {
    dispatch({ type: 'SCRAPE_REQUEST' });
    dispatch({ type: 'ALERT_WARNING', message: 'Scraping data...' });

    scraperService.scrape(tid).then(
      (result) => {
        dispatch({ type: 'SETUP_PROPERTY_SCRAPER_DATA', data: result });
        dispatch({ type: 'ALERT_SUCCESS', message: 'Scraping successfull!' });
        dispatch({ type: 'SCRAPE_END' });
      },
      (error) => {
        dispatch({ type: 'ALERT_ERROR', error });
        dispatch({ type: 'SCRAPE_END' });
      },
    );
  };
}

export const scraperActions = {
  scrape,
}
