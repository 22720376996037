import { sendRequest } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function get() {
  const response = await sendRequest('GET', `${API_URL}/sync/list`);
  return response;
}

async function create(tid, action) {
  const response = await sendRequest('POST', `${API_URL}/sync/createOrUpdate`, { tid, action });
  return response;
}

export const syncService = {
  get,
  create,
};
