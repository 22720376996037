const defaultState = {
  loading: false,
  list: [],
  alerts: [],
  comment: '',
  exceptions: [],
  alert: '',
  companies: [],
  filters: {
    type: 'all',
    company: 'all',
    status: 'non-resolved',
  },
};

export function alerts(state = defaultState, action) {
  switch (action.type) {
    case 'GET_ALERTS_LIST_REQUEST':
      return {
        ...state,
        loading: true,
        comment: '',
      };
    case 'GET_ALERTS_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        list: action.data.list,
        alerts: action.data.alerts,
        companies: state.filters.company === 'all' ? action.data.companies : state.companies,
        comment: '',
      };
    case 'GET_ALERTS_LIST_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'ADD_ALERT_EXCEPTION_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'ADD_ALERT_EXCEPTION_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'ADD_ALERT_EXCEPTION_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'RESOLVE_ALERT_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'RESOLVE_ALERT_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'RESOLVE_ALERT_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'GET_ALERT_COMMENT_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'GET_ALERT_COMMENT_SUCCESS':
      return {
        ...state,
        comment: action.data.comment.comment,
        loading: false,
      };
    case 'GET_ALERT_COMMENT_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'ALERT_EDIT_COMMENT':
      return {
        ...state,
        comment: action.comment,
      };

    case 'GET_EXCEPTIONS_LIST_REQUEST':
      return {
        ...state,
        loading: true,
        exceptions: [],
      };
    case 'GET_EXCEPTIONS_LIST_SUCCESS':
      return {
        ...state,
        exceptions: action.data.exceptions,
        alerts: action.data.alertsList,
        loading: false,
      };
    case 'GET_EXCEPTIONS_LIST_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'SAVE_ALERT_COMMENT_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'SAVE_ALERT_COMMENT_SUCCESS':
      return {
        ...state,
        alert: action.data.result,
        loading: false,
      };
    case 'SAVE_ALERT_COMMENT_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'DELETE_EXCEPTION_REQUEST':
      return {
        ...state,
        loading: true,

      };
    case 'DELETE_EXCEPTION_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'DELETE_EXCEPTION_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'GET_RESOLVED_ALERTS_LIST_REQUEST':
      return {
        ...state,
        loading: true,
        comment: '',
      };
    case 'GET_RESOLVED_ALERTS_LIST_SUCCESS':
      return {
        ...state,
        list: action.data.list,
        companies: action.data.updateCompanies ? action.data.companies : state.companies,
        loading: false,
        comment: '',
      };
    case 'GET_RESOLVED_ALERTS_LIST_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'GET_MULTIPLE_RESOLVED_ALERTS_LIST_REQUEST':
      return {
        ...state,
        loading: true,
        comment: '',
      };
    case 'GET_MULTIPLE_RESOLVED_ALERTS_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        comment: '',
      };
    case 'GET_MULTIPLE_RESOLVED_ALERTS_LIST_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'SET_ALERTS_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.data.name]: action.data.value,
        },
      };
    default:
      return state;
  }
}
