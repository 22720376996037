/* eslint-disable import/prefer-default-export */
export function socket(state = {
  connected: false,
}, action) {
  switch (action.type) {
    case 'CONNECT_SOCKET_SUCCESS':
      return {
        ...state,
        connected: true,
      };

    case 'CONNECT_SOCKET_FAILURE':
      return {
        ...state,
        connected: false,
      };

    default:
      return state;
  }
}
