// eslint-disable-next-line import/prefer-default-export
export function search(state = {
  result: {},
  watchList: [],
  loading: false,
}, action) {
  switch (action.type) {
    case 'SEARCH_REQUEST':
      return {
        ...state,
        loading: true,
      };

    case 'SEARCH_SUCCESS':
      return {
        ...state,
        loading: false,
        result: action.result,
        watchList: action.result.watchList,
      };

    case 'SEARCH_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'WATCH_BOT_SUCCESS': {
      let { watchList } = state;
      if (action.data.toggle) {
        watchList = [...watchList, action.data.id];
      } else {
        watchList = watchList.filter((id) => id !== action.data.id);
      }

      console.log(watchList);
      return {
        ...state,
        watchList,
      };
    }

    default:
      return state;
  }
}
