/* eslint-disable import/prefer-default-export */
export function bulk(state = {
  selectedBots: [],
  fields: [],
  jobs: [],
  accountOwners: [],
  accountManagers: [],
  wps: [],
  crm: [],
  loading: false,
}, action) {
  switch (action.type) {
    case 'ADD_BOT_TO_BULK_EDIT_GROUP':
      return {
        ...state,
        selectedBots: [...state.selectedBots, action.data],
      };

    case 'REMOVE_BOT_FROM_BULK_EDIT_GROUP':
      return {
        ...state,
        selectedBots: state.selectedBots.filter((bot) => bot.id !== action.id),
      };

    case 'ADD_ALL_BOTS_TO_BULK_EDIT_GROUP': {
      let botsNotInGroup = action.data.bots.filter(
        (incBot) => !state.selectedBots.filter((curBot) => curBot.id === incBot.id)[0],
      );
      if (!state.selectedBots.length) {
        botsNotInGroup = action.data.bots;
      }
      if (action.data.company) {
        botsNotInGroup.forEach((bot) => {
          bot.company = action.data.company.name;
        });
      }

      return {
        ...state,
        selectedBots: [...state.selectedBots, ...botsNotInGroup],
      };
    }

    case 'REMOVE_ALL_BOTS_FROM_BULK_EDIT_GROUP':
      return {
        ...state,
        selectedBots: [],
      };

    case 'ADD_OR_REMOVE_FIELD': {
      if (!action.data.disabled) {
        return {
          ...state,
          fields: [...state.fields, { ...action.data }],
        };
      }
      return {
        ...state,
        fields: state.fields.filter((field) => field.name !== action.data.name),
      };
    }

    case 'CHANGE_FIELD_VALUE':
      return {
        ...state,
        fields: state.fields.map((field) => {
          if (field.name === action.data.field) {
            return { ...field, value: action.data.value };
          }
          return field;
        }),
      };

    case 'GET_BULK_EDIT_FIELDS_DATA_REQUEST':
      return {
        ...state,
        loading: true,
      };

    case 'GET_BULK_EDIT_FIELDS_DATA_SUCCESS': {
      const aos = [];
      const crm = [];
      const wps = [];
      const ps = [];
      for (let i = 0; i < action.data.accountOwners.length; i += 1) {
        const owner = action.data.accountOwners[i];
        if (!i) {
          aos.push({ value: '', text: ' ' });
        }
        aos.push({ value: owner.id, text: owner.name });
      }

      for (let i = 0; i < action.data.crm.length; i += 1) {
        const item = action.data.crm[i];
        if (!i) {
          crm.push({ value: '', text: ' ' });
        }
        crm.push({ value: item.id, text: item.name });
      }

      for (let i = 0; i < action.data.wps.length; i += 1) {
        const item = action.data.wps[i];
        if (!i) {
          wps.push({ value: '', text: ' ' });
        }
        wps.push({ value: item.id, text: item.name });
      }
      for (let i = 0; i < action.data.propertyStatus.length; i += 1) {
        const status = action.data.propertyStatus[i];
        if (!i) {
          ps.push({ value: '', text: ' ' });
        }
        ps.push({ value: status.id, text: status.name });
      }
      return {
        ...state,
        loading: false,
        accountOwners: aos,
        crm,
        wps,
        propertyStatus: ps,
      };
    }

    case 'GET_BULK_EDIT_FIELDS_DATA_FAILURE':
      return {
        ...state,
        loading: false,
      };

    case 'SEND_BULK_EDIT_FIELDS_SUCCESS':
      return {
        ...state,
        selectedBots: [],
        fields: [],
      };

    case 'GET_CURRENT_BULK_JOBS_SUCCESS':
      return {
        ...state,
        jobs: action.data,
        loading: false,
      };

    default:
      return state;
  }
}
