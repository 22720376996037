import { sendRequest, sendFormData } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function select(defId) {
  const response = await sendRequest('GET', `${API_URL}/definitions/${defId}`);
  return response;
}

async function createInitAction(action, id) {
  let selector = action.value;

  if(action.selector === 'class') {
    selector = '.' + action.value;
  } else if(action.selector === 'id') {
    selector = '#' + action.value;
  }

  const data = {
    type: action.type,
    value: selector,
    definitionId: id,
  };
  const response = await sendRequest('POST', `${API_URL}/actions`, data);
  return response;
}

async function deleteAction(id) {
  const response = await sendRequest('DELETE', `${API_URL}/actions/${id}`);
  return response;
}

async function updateDefinition(data) {
  const params = {
    ...data,
    Actions: null,
    Containers: null,
  };

  let formData = new FormData();
  for (let [key, value] of Object.entries(params)) {
    formData.append(key, value);
  }
  const response = await sendFormData('PUT', `${API_URL}/definitions/${data.id}`, formData);
  return response;
}

async function createContainer(definitionId) {
  const response = await sendRequest('POST', `${API_URL}/containers`, { definitionId });
  return response;
}

async function deleteContainer(id) {
  const response = await sendRequest('DELETE', `${API_URL}/containers/${id}`);
  return response;
}

async function updateContainer(data, id) {
  const response = await sendRequest('PUT', `${API_URL}/containers/${id}`, data);
  return response;
}

async function createContainerAction(data) {
  const response = await sendRequest('POST', `${API_URL}/actions`, data);
  return response;
}

async function test(url, id) {
  const response = await sendRequest('POST', `${API_URL}/definitions/${id}/test`, { url });
  return response;
}
// eslint-disable-next-line import/prefer-default-export
export const definitionService = {
  select,
  createInitAction,
  deleteAction,
  updateDefinition,
  createContainer,
  deleteContainer,
  updateContainer,
  createContainerAction,
  test,
};
