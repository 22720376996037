const defaultState = {
    crons: {},
    loading: false,
};
export function settings(state = defaultState, action){

    switch (action.type) {
        case 'SETTINGS_CRONS_REQUEST':
            return {
                ...state,
                loading: true
            }
            
        case 'SETTINGS_CRONS_SUCCESS':
            return {
                ...state,
                loading: false,
                crons: action.data
            }

        case 'SETTINGS_CRONS_FAILURE':
            return {
                ...state,
                loading: false
            }
        

        case 'CHANGE_SETTINGS': 
            return {
                ...state,
                loading: false,
                crons: {
                    ...state.crons,
                    [action.data.name]: action.data.value
                }
            }
        default:
            return state;
    }
}