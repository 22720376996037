import { sendRequest } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function get(userId, id) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/maintenance/list/${userId || 0}/${id}`,
  );
  return response;
}

async function missedMaintenance() {
  const response = await sendRequest(
    'GET',
    `${API_URL}/maintenance/missed`
  )
  return response;
}

async function select(tid) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/maintenance/select/${tid}`,
  );
  return response;
}

async function getSpecials(tid) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/maintenance/specials/${tid}`,
  );
  return response;
}

async function saveNote(tid, note) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/maintenance/note/${tid}`,
    { note },
  );
  return response;
}

async function lastUpdateDate(tid) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/maintenance/updateDate/${tid}`,
  );
  return response;
}

async function saveSpecials(data, tid) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/maintenance/specials/${tid}/default`,
    data,
  );
  return response;
}

async function updateSpecials(data, tid) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/maintenance/specials/${tid}/update`,
    data,
  );
  return response;
}

async function updateBotInfo(data, tid) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/maintenance/bot-details/${tid}`,
    data,
  );
  return response;
}

async function createSpecial(data, tid) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/maintenance/specials/${tid}/create`,
    data,
  );
  return response;
}

async function deleteSpecial(id, tid) {
  const response = await sendRequest(
    'DELETE',
    `${API_URL}/maintenance/specials/${tid}/delete/${id}`,
  );
  return response;
}

async function getFloorplans(tid) {
  const response = await sendRequest(
    'GET',
    `${API_URL}/maintenance/floorplans/${tid}`,
  );
  return response;
}

async function updateIntegration(tid, integration, shouldUpdate = false) {
  const response = await sendRequest(
    'POST',
    `${API_URL}/bots/${tid}/updateIntegration`,
    { integration, shouldUpdate },
  );
  return response;
}

export const maintenanceService = {
  get,
  lastUpdateDate,
  select,
  saveNote,
  getSpecials,
  saveSpecials,
  updateSpecials,
  updateBotInfo,
  createSpecial,
  deleteSpecial,
  getFloorplans,
  updateIntegration,
  missedMaintenance,
};
