export const wizardActions = {
    reset,
    apply
}

function reset(){
    return dispatch => {
        dispatch({type: 'RESET_WIZARD'});
    }
}

function apply(){
    return dispatch => {
        dispatch({type: 'APPLY_WIZARD'});
    }
}