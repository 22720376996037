import { alertService } from '../_services';

/* eslint-disable import/prefer-default-export */
function success(message) {
  return { type: 'ALERT_SUCCESS', message };
}

function error(message) {
  return { type: 'ALERT_ERROR', message };
}

function warning(message) {
  return { type: 'ALERT_WARNING', message };
}

function clear() {
  return { type: 'ALERT_CLEAR' };
}

function getAlertListReport(filters) {
  return (dispatch) => {
    dispatch({ type: 'GET_ALERTS_LIST_REQUEST' });
    alertService.getAlerts(filters).then(
      (data) => {
        dispatch({ type: 'GET_ALERTS_LIST_SUCCESS', data });
      },
      (error) => {
        dispatch({ type: 'GET_ALERTS_LIST_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function addException(id, filters) {
  return (dispatch) => {
    dispatch({ type: 'ADD_ALERT_EXCEPTION_REQUEST' });
    alertService.addAlertException(id).then(
      (data) => {
        dispatch({ type: 'ADD_ALERT_EXCEPTION_SUCCESS', data });
        dispatch(getAlertListReport(filters));
        dispatch({ type: 'ALERT_SUCCESS', message: 'Alert has been added in the exception list' });
      },
      (error) => {
        dispatch({ type: 'ADD_ALERT_EXCEPTION_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}


function resolve(id, filters) {
  return (dispatch) => {
    dispatch({ type: 'RESOLVE_ALERT_REQUEST' });
    alertService.resolve(id).then(
      (data) => {
        dispatch({ type: 'RESOLVE_ALERT_SUCCESS', data });
        dispatch(getAlertListReport(filters));
        dispatch({ type: 'ALERT_SUCCESS', message: 'Alert resolved successfully' });
      },
      (error) => {
        dispatch({ type: 'RESOLVE_ALERT_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function getComment(tenantId, alertId) {
  return (dispatch) => {
    dispatch({ type: 'GET_ALERT_COMMENT_REQUEST' });
    alertService.getComment(tenantId, alertId).then(
      (data) => {
        dispatch({ type: 'GET_ALERT_COMMENT_SUCCESS', data });
      },
      (error) => {
        dispatch({ type: 'GET_ALERT_COMMENT_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}


function saveComment(tenantId, alertId, comment) {
  return (dispatch) => {
    dispatch({ type: 'SAVE_ALERT_COMMENT_REQUEST' });
    alertService.saveComment(tenantId, alertId, comment).then(
      (data) => {
        dispatch({ type: 'SAVE_ALERT_COMMENT_SUCCESS', data });
        dispatch({ type: 'ALERT_SUCCESS', message: 'Comment updated successfully' });
      },
      (error) => {
        dispatch({ type: 'SAVE_ALERT_COMMENT_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function editComment(comment) {
  return (dispatch) => {
    dispatch({ type: 'ALERT_EDIT_COMMENT', comment });
  };
}

function getExceptions() {
  return (dispatch) => {
    dispatch({ type: 'GET_EXCEPTIONS_LIST_REQUEST' });
    alertService.getExceptionList().then(
      (data) => {
        dispatch({ type: 'GET_EXCEPTIONS_LIST_SUCCESS', data });
      },
      (error) => {
        dispatch({ type: 'GET_EXCEPTIONS_LIST_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function deleteException(tenantId, alertId) {
  return (dispatch) => {
    dispatch({ type: 'DELETE_EXCEPTION_REQUEST' });
    alertService.deleteException(tenantId, alertId).then(
      (data) => {
        dispatch({ type: 'DELETE_EXCEPTION_SUCCESS', data });
        dispatch(getExceptions());
      },
      (error) => {
        dispatch({ type: 'DELETE_EXCEPTION_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}


function getAlerts(filter) {
  return (dispatch) => {
    dispatch({ type: 'GET_ALERTS_LIST_REQUEST' });
    alertService.getAlerts(filter).then(
      (data) => {
        dispatch({ type: 'GET_ALERTS_LIST_SUCCESS', data });
      },
      (error) => {
        dispatch({ type: 'GET_ALERTS_LIST_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function resolveMultipleActions(action, alertsInfo) {
  return (dispatch, state) => {
    if(alertsInfo.length == 0){
      dispatch({ type: 'ALERT_WARNING', message: 'Please check which alerts you would like to resolve or add as an exception' });
    } else {
      dispatch({ type: 'GET_MULTIPLE_RESOLVED_ALERTS_LIST_REQUEST' });
      alertService.resolveMultipleList(action, alertsInfo).then(
        (data) => {
          dispatch({ type: 'GET_MULTIPLE_RESOLVED_ALERTS_LIST_SUCCESS', data });
          const filters = state().alerts.filters;
          dispatch(getAlerts(filters));
          const text = action === 'resolve' ? 'Resolved successfully' : 'Healthcheck added as exception'
          dispatch({ type: 'ALERT_SUCCESS', message: text });
        },
        (error) => {
          dispatch({ type: 'GET_MULTIPLE_RESOLVED_ALERTS_LIST_FAILURE' });
          dispatch({ type: 'ALERT_ERROR', error });
        },
      );
    }
  };
}

function setFilter(name, value) {
  return (dispatch) => {
    dispatch({ type: 'SET_ALERTS_FILTER', data: { name, value } });
  };
}

export const alertActions = {
  success,
  error,
  warning,
  clear,
  addException,
  resolve,
  getComment,
  editComment,
  saveComment,
  getExceptions,
  deleteException,
  getAlerts,
  resolveMultipleActions,
  setFilter
};
