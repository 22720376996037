import { sendRequest, sendFormData } from '../_helpers/request.lib';

export const onboardingService = {
  get,
  select,
  getSpecials,
  saveSpecials,
  getFloorplans,
  createFloorplan,
  updateFloorplan,
  reorderFloorplans,
  deleteFloorplan,
  getAppointments,
  saveAppointments,
  getAmenities,
  deleteAmenityCategory,
  saveAmenities,
  saveGallery,
  saveGeneralInfo
};

const API_URL = process.env.REACT_APP_API_URL

async function get() {
  const response = await sendRequest('GET', API_URL + '/onboarding/list')
  return response;
}

async function select(tid) {
  const response = await sendRequest('GET', API_URL + '/onboarding/select/' + tid)
  return response;
}

async function saveGeneralInfo(data, tid) {
  let formData = new FormData();
  for (let [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  const response = await sendFormData('POST', API_URL + '/onboarding/general/' + tid, formData);
  return response;
}

async function getSpecials(tid) {
  const response = await sendRequest('GET', API_URL + '/onboarding/specials/' + tid);
  return response;
}

async function saveSpecials(data, tid) {
  const response = await sendRequest('POST', API_URL + '/onboarding/specials/' + tid, data);
  return response;
}

async function getFloorplans(tid) {
  const response = await sendRequest('GET', API_URL + '/onboarding/floorplans/' + tid);
  return response;
}

async function createFloorplan(data, tid) {
  let formData = new FormData();
  for (let [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  const response = await sendFormData('POST', API_URL + '/onboarding/floorplan/create/' + tid, formData);
  return response;
}

async function updateFloorplan(data, tid) {
  let formData = new FormData();
  for (let [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  const response = await sendFormData('POST', API_URL + '/onboarding/floorplan/update/' + tid, formData);
  return response;
}

async function reorderFloorplans(data, tid) {
  let requestData = data.map(el => { return { id: el.id, weight: el.weight } });
  const response = await sendRequest('POST', API_URL + '/onboarding/floorplan/reorder/' + tid, requestData);
  return response;
}

async function deleteFloorplan(tid, id) {
  const response = await sendRequest('DELETE', API_URL + '/onboarding/floorplan/delete/' + tid + '/' + id);
  return response;
}

async function getAppointments(tid) {
  const response = await sendRequest('GET', API_URL + '/onboarding/appointments/' + tid);
  return response;
}

async function saveAppointments(data, tid) {
  const response = await sendRequest('POST', API_URL + '/onboarding/appointments/' + tid, data);
  return response;
}

async function getAmenities(tid) {
  const response = await sendRequest('GET', API_URL + '/onboarding/amenities/' + tid);
  return response;
}

async function deleteAmenityCategory(id, tid) {
  const response = await sendRequest('DELETE', API_URL + '/onboarding/amenities/' + id + '/' + tid);
  return response;
}

async function saveAmenities(data, tid) {
  const response = await sendRequest('POST', API_URL + '/onboarding/amenities/' + tid, data);
  return response;
}

async function saveGallery(tid, data) {
  const response = await sendRequest('POST', API_URL + '/onboarding/gallery/' + tid, data);
  return response;
}
