import React, { Fragment, Component } from 'react';

import Slider from 'react-slick';

import { connect } from 'react-redux';
import { authActions } from '../../../_actions';
import { Link } from 'react-router-dom';
import Isemail from 'isemail';
import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Alert,
  Input,
  FormFeedback,
} from 'reactstrap';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      remember_me: false,
      otp: '',
    };

    this.change = this.change.bind(this);
    this.rememberMe = this.rememberMe.bind(this);
    this.submit = this.submit.bind(this);
  }

  change(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  rememberMe() {
    this.setState((prevState) => ({
      remember_me: !prevState.remember_me,
    }));
  }

  async submit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password, remember_me, otp } = this.state;
    let errors = {
      email: '',
      password: '',
    };
    let error = 0;
    if (!email) {
      errors.email = 'Email is required';
      error++;
    } else if (!Isemail.validate(email)) {
      errors.email = 'This email is invalid';
      error++;
    }
    if (password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
      error++;
    }
    this.props.setErrorMessages(errors);
    if (!error) {
      this.props.login(email, password, remember_me, otp);
    }
  }
  render() {
    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 0,
      fade: true,
      initialSlide: 0,
      autoplay: true,
      adaptiveHeight: true,
    };
    return (
      <Fragment>
        <div className="h-100">
          <Row className="h-100 no-gutters">
            <Col lg="8" className="d-none d-lg-block">
              <div className="slider-light">
                <Slider {...settings}>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div className="slide-img-bg" />
                    <div id="cloud-1" />
                    <div id="cloud-2" />
                    <div id="cloud-3" />
                  </div>
                </Slider>
              </div>
            </Col>
            <Col
              lg="4"
              md="12"
              className="h-100 d-flex bg-white justify-content-center align-items-center"
            >
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <div className="app-logo" />
                <h4 className="mb-0">
                  <div>Welcome back,</div>
                  <span>Please sign in to your account.</span>
                </h4>
                <h6 className="mt-3">
                  No account?{' '}
                  <Link to="/register" className="sign-up">
                    Sign up
                  </Link>
                </h6>
                <Row className="divider" />
                {this.props.errorMsg.length > 0 && (
                  <Alert color="danger">{this.props.errorMsg}</Alert>
                )}
                <div>
                  <Form onSubmit={this.submit} noValidate>
                    <Row form>
                      <Col md={12}>
                        <FormGroup className="position-relative form-group">
                          <Label for="email">Email</Label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            className="form-control form-style"
                            autoComplete="email"
                            placeholder="Email here..."
                            onChange={this.change}
                            invalid={
                              this.props.errors.email.length > 0 ? true : false
                            }
                          />
                          <FormFeedback>{this.props.errors.email}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="position-relative form-group">
                          <Label for="password">Password</Label>
                          <Input
                            type="password"
                            name="password"
                            id="password"
                            className="form-control form-style"
                            autoComplete="current-password"
                            placeholder="Password here..."
                            onChange={this.change}
                            invalid={
                              this.props.errors.password.length > 0
                                ? true
                                : false
                            }
                          />
                          <FormFeedback>
                            {this.props.errors.password}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="position-relative form-group">
                          <Label for="otp">Auth code</Label>
                          <Input
                            type="text"
                            name="otp"
                            id="otp"
                            className="form-control form-style"
                            autoComplete="off"
                            placeholder="e.g. 1234 5678 90"
                            onChange={this.change}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup check className="no-padding">
                      <Label for="exampleCheck" className="checkbox" check>
                        <Input
                          type="checkbox"
                          name="remember_me"
                          onClick={this.rememberMe}
                          value={1}
                          id="exampleCheck"
                        />
                        Keep me logged in <span className="checkmark"></span>
                      </Label>
                    </FormGroup>
                    <Row className="space" />
                    <div className="d-flex align-items-center">
                      <div className="ml-auto">
                        <Link
                          to="/forgot_password"
                          className="btn-lg btn btn-link p-forgot"
                        >
                          Recover Password
                        </Link>{' '}
                        <LaddaButton
                          className="btn btn-primary-custom btn-lg pull-right"
                          loading={this.props.loggingIn}
                          data-style={ZOOM_OUT}
                        >
                          Login to dashboard
                        </LaddaButton>
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
              <div className="col-lg-12 footer-login">
                <Row className="divider" />
                <p align="center">BetterBot.ai, Atlanta GA</p>
              </div>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loggingIn: state.authentication.loggingIn,
  errorMsg: state.authentication.errorMsg,
  errors: state.authentication.fieldErrors,
});

const actionCreators = {
  login: authActions.login,
  setErrorMessages: authActions.setLoginErrors,
};

const connectedLoginPage = connect(mapStateToProps, actionCreators)(Login);
export { connectedLoginPage as Login };
