import { searchService } from '../_services';

function search(query) {
  return (dispatch) => {
    dispatch({ type: 'SEARCH_REQUEST' });

    searchService.search(query).then(
      (result) => {
        dispatch({ type: 'SEARCH_SUCCESS', result });
      },
      (error) => {
        dispatch({ type: 'SEARCH_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

export const searchActions = {
  search
}
