const data = JSON.parse(localStorage.getItem('user'));
let user;
if (data) {
  user = data;
}

const initialState = {
  user: data ? user : null,
  loggedIn: data ? true : false,
  errorMsg: '',
  fieldErrors: {
    email: '',
    password: '',
  },
  qrcode: '',
  qrLoading: false,
  verified: false,
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loggingIn: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        errorMsg: '',
        loggedIn: true,
        loggingIn: false,
        user: action.user,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        loggingIn: false,
        errorMsg: action.error.data.message,
      };

    case 'SET_LOGIN_ERROR':
      return {
        ...state,
        fieldErrors: {
          email: action.data.email,
          password: action.data.password,
        },
        errorMsg: '',
      };

    case 'GENERATE_QR_CODE_REQUEST':
      return {
        ...state,
        qrLoading: true,
      };

    case 'GENERATE_QR_CODE_SUCCESS':
      return {
        ...state,
        qrLoading: false,
        qrcode: action.data.qr,
        secret: action.data.token,
      };

    case 'GENERATE_QR_CODE_FAILURE':
      return {
        ...state,
        qrLoading: false,
      };

    case 'VERIFY_QR_CODE_SUCCESS':
      return {
        ...state,
        verified: true,
      }

    case 'VERIFY_QR_CODE_FAILURE':
      return {
        ...state,
        verified: false,
        verifiedMessage: action.message
      }
    default:
      return state;
  }
}
