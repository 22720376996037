import { jobsService } from '../_services';

export const jobActions = {
    get,
    create,
    destroy,
    receiveJob,
    receiveDeleteJob
}

function get(){
    return dispatch => {
        dispatch({type: 'GET_JOBS_REQUEST'});

        jobsService.get().then(
            result => {
                dispatch({type: 'GET_JOBS_SUCCESS', jobs: result});
            },
            error => {
                dispatch({type: 'GET_JOBS_FAILURE', error: error});
            }
        )
    }
}

function create(job){
    return dispatch => {
        dispatch({ type: 'CREATE_REQUEST' });
        
        jobsService.create(job)
            .then(
                job => { 
                    dispatch({ type: 'CREATE_SUCCESS', job });
                },
                error => {
                    dispatch({ type: 'CREATE_FAILURE', error: error });
                }
            );
    };
}

function receiveJob(data){
    return dispatch => {
        dispatch({type: 'RECEIVE_JOB_SOCKET', job:data});
    }
}

function destroy(id){
    return dispatch => {
        dispatch({type: 'DELETE_JOB_REQUEST'});

        jobsService.destroy(id).then(
            result => {
                dispatch({type: 'DELETE_JOB_SUCCESS', id: id});
            },
            error => {
                dispatch({type: 'DELETE_JOB_FAILURE'});
            }
        )
    }
}

function receiveDeleteJob(id){
    return dispatch => {
        dispatch({type: 'DELETE_JOB_RECEIVED_FROM_SOCKET', id: parseInt(id)});
    }
}