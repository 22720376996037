import {
  notificationsService
} from '../_services';

function get(){
  return (dispatch) => {
    dispatch({type: 'GET_NOTIFICATIONS_REQUEST'});

    notificationsService.get().then(
      (res) => {
        dispatch({type: 'GET_NOTIFICATIONS_SUCCESS', data: {list: res.notifications, lastSeen: res.lastSeen}});
      },
      (err) => {
        dispatch({type: 'ALERT_ERROR', error: err});
      }
    )
  };
}

function getGroups () {
  return (dispatch) => {
    dispatch({type: 'GET_NOTIFICATION_GROUPS_REQUEST'});
    notificationsService.getGroups().then(
      (res) => {
        dispatch({type: 'GET_NOTIFICATION_GROUPS_SUCCESS', data: res.groups});
      },
      (err) => {
        dispatch({type: 'GET_NOTIFICATION_GROUPS_FAILURE'});
        dispatch({type: 'ALERT_ERROR', message: err});
      }
    )
  }
}

function subscribe(id, subscribed) {
  return (dispatch) => {
    dispatch({type: 'SUBSCRIBE_REQUEST'});
    notificationsService.subscribe(id, subscribed).then(
      (res) => {
        dispatch(getGroups());
        dispatch({type: 'ALERT_SUCCESS', message: res.message});
      },
      (err) => {
        dispatch({type: 'SUBSCRIBE_FAILURE', error: err});
      }
    )
  }
}

function seen() {
  return (dispatch) => {
    notificationsService.seen().then(
      (res) => {
        dispatch({type: 'SEEN_SUCCESS', data: res.date})
      },
    )
  }
}

function getAll() {
  return (dispatch) => {
    dispatch({type: 'GET_ALL_NOTIFICATIONS_REQUEST'});
    notificationsService.getAll().then(
      (res) => {
        dispatch({type: 'GET_ALL_NOTIFICATIONS_SUCCESS', data: res});
      },
      (err) => {
        dispatch({type: 'GET_ALL_NOTIFICATIONS_FAILURE'});
        dispatch({type: 'ALERT_ERROR', error: err});
      }
    )
  }
}

export const notificationsActions = {
  get,
  getGroups,
  subscribe,
  seen,
  getAll
};