import { sendRequest } from '../_helpers/request.lib';

const API_URL = process.env.REACT_APP_API_URL;

async function getAll() {
  const response = await sendRequest('GET', `${API_URL}/wps`);
  return response;
}

async function create(name) {
  const response = await sendRequest('POST', `${API_URL}/wps`, { name });
  return response.wp;
}

async function update(name, id) {
  const response = await sendRequest('PUT', `${API_URL}/wps/${id}`, { name });
  return response;
}

async function destroy(id) {
  const response = await sendRequest('DELETE', `${API_URL}/wps/${id}`);
  return response;
}

async function createDef(name, wpId) {
  const response = await sendRequest('POST', `${API_URL}/definitions`, { name, wpId });
  return response;
}

async function deleteDef(id) {
  const response = await sendRequest('DELETE', `${API_URL}/definitions/${id}`);
  return response;
}

async function listDefinitions(wpId) {
  const response = await sendRequest('GET', `${API_URL}/wps/${wpId}`);
  return response;
}

export const wpsService = {
  getAll,
  create,
  update,
  destroy,
  createDef,
  deleteDef,
  listDefinitions,
};
