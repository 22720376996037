const defaultState = {
  updateConversation: 0,
  intents: [],
  preview: false,
  hover: '',
  inspector: {
    nodeId: false,
    data: {},
  },
  xray: {
    nodes: [],
    variables: [],
    flags: [],
    issues: [],
  },
  flow: {
    edges: [],
    nodes: [],
  },
};
export function conversation(state = defaultState, action){
  switch (action.type) {
    case 'SET_XRAY_NODES': {
      let data = action.data.list;
      if (!action.data.overwrite){
        if (action.data.target == 'flags' || action.data.target == 'variables') {
          data = state.xray[action.data.target];
          const newItems = [];
          action.data.list.forEach((el) => {
            let found = 0;

            data.forEach((el1) => {
              if (el1.variable == el.variable) {
                found = 1;
                newItems.push(el);
              }
            });
            if (!found) {
              newItems.push(el);
            }
          });

          data = newItems;
        }
        if (action.data.target == 'issues') {
          data = state.xray.issues;
          action.data.list.forEach((el) => {
            if(!data.includes(el)){
              data.push(el);
            }
          });
        };
      }
      return {
        ...state,
        xray: {
          ...state.xray,
          [action.data.target]: data,
        },
      };
    }
    case 'DESELECT_NODE':
      return {
        ...state,
        inspector: {
          ...state.inspector,
          nodeId: false,
          data: {},
        },
      };
    case 'SELECT_NODE':
      return {
        ...state,
        inspector: {
          ...state.inspector,
          nodeId: action.data.id,
          data: action.data.data,
        },
      };

    case 'UPDATE_NODE':
      return {
        ...state,
        inspector: {
          ...state.inspector,
          data: {
            ...state.inspector.data,
            data: action.data,
          },
        },
      };

    case 'REQUEST_UPDATE_CONVERSATION_STATE':
      return {
        ...state,
        updateConversation: 1,
      };

    case 'UPDATE_CONVERSATION_STATE':
      return {
        ...state,
        updateConversation: 0,
        flow: {
          edges: action.data.edges,
          nodes: action.data.nodes,
        },
      };

    case 'PREVIEW_CONVERSATION':
      return {
        ...state,
        preview: action.data,
      };

    case 'SET_CB_HOVER_ID':
      return {
        ...state,
        hover: action.data,
      };

    default:
      return state;
  }
}
