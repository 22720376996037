function select(id, data) {
  return (dispatch) => {
    dispatch({
      type: 'DESELECT_NODE',
    });
    dispatch({
      type: 'SELECT_NODE',
      data: {
        id,
        data,
      },
    });
  };
}

function edit(data) {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_NODE',
      data,
    });
  };
}

function requestStateUpdate() {
  return (dispatch) => {
    dispatch({
      type: 'REQUEST_UPDATE_CONVERSATION_STATE',
    });
  };
}

function updateState(nodes, edges) {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_CONVERSATION_STATE',
      data: {
        edges,
        nodes,
      },
    });
  };
}

function setPreview(st) {
  return (dispatch) => {
    dispatch({
      type: 'PREVIEW_CONVERSATION',
      data: st
    });
  };
}

function xray(list, target, overwrite = 0) {
  return (dispatch) => {
    dispatch({
      type: 'SET_XRAY_NODES',
      data: {
        list,
        target,
        overwrite,
      },
    });
  };
}

function setHover(id) {
  return (dispatch) => {
    dispatch({ type: 'SET_CB_HOVER_ID', data: id });
  };
}

export const conversationActions = {
  select,
  edit,
  requestStateUpdate,
  updateState,
  setPreview,
  xray,
  setHover,
};
