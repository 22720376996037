import moment from 'moment';

let defaultState = {
    selected: {
        info: {},
        map: {},
        settings: [],
        specials: {
            default: '',
            list: []
        },
        floorplans: {
            createModalReset: false,
            createModal: false,
            editModalReset: false,
            editModal: 0,
            editSelected: {},
            list: [],
            options: {
                images: true,
                description: true,
                prices: true,
                status: true,
                icons: true
            }
        },
        appointments: [],
        amenities: [],
        gallery: []
    },
    list: []
};

export function onboarding(state = defaultState, action){
    switch (action.type) {
        case 'GET_ONBOARDING_LIST_REQUEST':
            return {
                ...state,
                loadingList: true
            }
        case 'GET_ONBOARDING_LIST_SUCCESS':
            return {
                ...state,
                list: action.list,
                loadingList: false
            }
        case 'GET_ONBOARDING_LIST_FAILURE':
            return {
                ...state,
                loadingList: false,
                error: action.error
            }
            


        case 'SELECT_ONBOARDING_BOT_REQUEST':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    info: {},
                    settings: []
                },
                dataLoading: true
            }
        
        case 'SELECT_ONBOARDING_BOT_SUCCESS':
            return {
                ...state,
                dataLoading: false,
                selected: {
                    ...state.selected,
                    info: action.data.bot,
                    settings: action.data.settings,
                    map: {
                        map_address: action.data.settings.map_address.value,
                        map_longitude: action.data.settings.map_longitude.value,
                        map_latitude: action.data.settings.map_latitude.value,
                    }
                },
            }

        case 'SELECT_ONBOARDING_BOT_FAILURE':
            return {
                ...state,
                dataLoading: false,
                error: action.error
            }

        case 'CHANGE_MAP_DATA':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    map: {
                        ...state.selected.map,
                        map_longitude: action.data.lng,
                        map_latitude: action.data.lat,
                    }
                } 
            }

        case 'CHANGE_MAP_INPUT':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    map: {
                        ...state.selected.map,
                        map_address: action.text
                    }
                }
            }
        
        case 'CHANGE_GENERAL_INFO':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    settings:{
                        ...state.selected.settings,
                        [action.data.name]: {
                            ...state.selected.settings[action.data.name],
                            newValue: action.data.value
                        }
                    }
                }
            }

        case 'SAVE_GENERAL_INFO_REQUEST':
            return {
                ...state,
                loadingGeneral: true
            }
        case 'SAVE_GENERAL_INFO_SUCCESS':
            return {
                ...state,
                loadingGeneral: false,
            }
        case 'SAVE_GENERAL_INFO_FAILURE':
            return {
                ...state,
                loadingGeneral: false,
                generalAlert: {
                    class: 'danger',
                    text: 'Something went wrong, general data was not updated.'
                }
            }
        
        case 'GET_SPECIALS_REQUEST':
            return {
                ...state,
                loadingSpecials: true
            }

        case 'GET_SPECIALS_SUCCESS':
            return {
                ...state,
                loadingSpecials: false,
                selected: {
                    ...state.selected,
                    specials: {
                        default: action.data.default,
                        list: action.data.list
                    }
                }
            }

        case 'GET_SPECIALS_FAILURE':
            return {
                ...state,
                loadingSpecials: false
            }
        case 'UPDATE_DEFAULT_SPECIAL': 
            return {
                ...state,
                selected: {
                    ...state.selected,
                    specials: {
                        ...state.selected.specials,
                        default: action.text
                    }
                }
            }
        case 'ADD_NEW_SPECIAL':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    specials: {
                        ...state.selected.specials,
                        list: [...state.selected.specials.list, action.data]
                    }
                }
            }
        case 'SAVE_SPECIALS_REQUEST': 
            return {
                ...state,
                loadingSpecials: true
            }
        case 'SAVE_SPECIALS_SUCCESS': 
            return {
                ...state,
                loadingSpecials: false,
                specialsAlert: {
                    class: 'success',
                    text: action.data.message
                }
            }
        case 'SAVE_SPECIALS_FAILURE': 
            return {
                ...state,
                loadingSpecials: false,
                specialsAlert: {
                    class: 'danger',
                    text: 'Something went wrong when saving specials.'
                }
            }

        case 'DELETE_SPECIAL':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    specials: {
                        ...state.selected.specials,
                        list: state.selected.specials.list.map(special => {
                            if(special.text === action.text){
                                special.action = 'delete';
                            }
                            return special;
                        })
                    }
                }
            }









        case 'GET_FLOORPLANS_REQUEST':
            return {
                ...state,
                loadingFloorplans: true,
                selected: {
                    ...state.selected,
                    floorplans: {
                        ...state.selected.floorplans,
                        list: [],
                    }
                }
            }

        case 'GET_FLOORPLANS_SUCCESS':
            return {
                ...state,
                loadingFloorplans: false,
                selected: {
                    ...state.selected,
                    floorplans: {
                        ...state.selected.floorplans,
                        list: action.data.list,
                    }
                }
            }

        case 'GET_FLOORPLANS_FAILURE':
            return {
                ...state,
                loadingFloorplans: false
            }

        case 'TOGGLE_CREATE_FLOORPLAN':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    floorplans: {
                        ...state.selected.floorplans,
                        createModal: action.value
                    }
                }
            }
        case 'TOGGLE_FLOORPLANS_OPTIONS':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    floorplans: {
                        ...state.selected.floorplans,
                        options: {
                            ...state.selected.floorplans.options,
                            [action.name]: !state.selected.floorplans.options[action.name]
                        }
                    }
                }
            }
        case 'RESET_FLOORPLANS_MODAL':
            return { 
                ...state,
                selected: {
                    ...state.selected,
                    floorplans: {
                        ...state.selected.floorplans,
                        createModal: false,
                        createModalReset: false,
                    }
                }
            }

        case 'MOVE_FLOORPLAN':
            const dragFloorplans = state.selected.floorplans.list[action.data.dragIndex]
            let list = state.selected.floorplans.list.slice();
            list.splice(action.data.dragIndex, 1);
            list.splice(action.data.hoverIndex, 0, dragFloorplans);
            list.map((el, key) => {
                return el.weight = key
            })
            return {
                ...state,
                selected: {
                    ...state.selected,
                    floorplans: {
                        ...state.selected.floorplans,
                        list: list
                    }
                }
            }


        case 'EDIT_FLOORPLAN': 
            return { 
                ...state,
                selected: {
                    ...state.selected,
                    floorplans: {
                        ...state.selected.floorplans,
                        editModal: action.id,
                        editSelected: state.selected.floorplans.list.filter(el => el.id === action.id)[0]
                    }
                }
            }
        case 'SAVE_EDIT_FLOORPLAN_DATA':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    floorplans: {
                        ...state.selected.floorplans,
                        editSelected: {
                            ...state.selected.floorplans.editSelected,
                            [action.data.name]: action.data.value
                        }
                    }
                }
            }
        
        case 'CREATE_FLOORPLAN_REQUEST':
            return {
                ...state,
                loadingCreateFloorplan: true
            }
        case 'TOGGLE_EDIT_FLOORPLAN':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    floorplans: {
                        ...state.selected.floorplans,
                        editModal: 0,
                        editSelected: {}
                    }
                }
            }

        case 'UPDATE_FLOORPLAN_REQUEST':
            return {
                ...state,
                loadingUpdateFloorplan: true
            }

        case 'UPDATE_FLOORPLAN_SUCCESS':
            return {
                ...state,
                loadingUpdateFloorplan: false,
                selected: {
                    ...state.selected,
                    floorplans: {
                        ...state.selected.floorplans,
                        list: state.selected.floorplans.list.map(el => {
                            if(el.id === action.data.id){
                                el = action.data;
                            }
                            return el;
                        })
                    }
                }
            }
        case 'REORDER_FLOORPLAN_REQUEST':
            return {
                ...state,
                loadingReorderFloorplan: true
            }
        case 'REORDER_FLOORPLAN_SUCCESS':
            return {
                ...state,
                loadingReorderFloorplan: false
            }
        
        case 'DELETE_FLOORPLAN_SUCCESS':
            return {
                ...state,
                loadingCreateFloorplan: false,
                selected: {
                    ...state.selected,
                    floorplans: {
                        ...state.selected.floorplans,
                        list: state.selected.floorplans.list.filter(el => el.id !== action.id)
                    }
                }
            }





        case 'GET_APPOINTMENTS_REQUEST':
            return {
                ...state,
                loadingAppointments: true
            }
        
        case 'GET_APPOINTMENTS_SUCCESS':
            action.data.map(el => {
                return el.closed = el.time_to === 'Closed'
            })
            return {
                ...state,
                loadingAppointments: false,
                selected: {
                    ...state.selected,
                    appointments: action.data
                }
            }

        case 'GET_APPOINTMENTS_FAILURE':
            return {
                ...state,
                loadingAppointments: false
            }
        

        case 'CHANGE_APPOINTMENT_TIME':
            let appointments = state.selected.appointments.slice();
            appointments[action.data.key] = {
                ...appointments[action.data.key],
                [action.data.type]: action.data.time
            }
            let from = moment(appointments[action.data.key].time_from, "H:mm A")
            let to = moment(appointments[action.data.key].time_to, "H:mm A")

            if(from.isAfter(to)){
                appointments[action.data.key].time_to = appointments[action.data.key].time_from;
            }
            return {
                ...state,
                selected: {
                    ...state.selected,
                    appointments: appointments
                }
            }
        
        case 'SAVE_APPOINTMENTS_REQUEST':
            return {
                ...state,
                loadingAppointments: true,
            }

        case 'SAVE_APPOINTMENTS_SUCCESS':
            return {
                ...state,
                loadingAppointments: false,
                appointmentsAlert: {
                    class: 'success',
                    text: action.data.message
                }
            }
        
        case 'SAVE_APPOINTMENTS_FAILURE':
            return {
                ...state,
                loadingAppointments: false,
                appointmentsAlert: {
                    class: 'success',
                    text: 'Something went wrong when saving appointments.'
                }

            }




            
        case 'GET_AMENITIES_REQUEST':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    amenities: []
                },
                loadingAmenities: true
            }
        
        case 'GET_AMENITIES_SUCCESS':
            return {
                ...state,
                loadingAmenities: false,
                selected: {
                    ...state.selected,
                    amenities: action.data
                }
            }
        
        case 'GET_AMENITIES_FAILURE':
            return {
                ...state,
                loadingAmenities: false,
            }
        
        case 'ADD_AMENITIES_CATEGORY':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    amenities: [...state.selected.amenities, action.data]
                }
            }


        case 'DELETE_AMENITY_CATEGORY_SUCCESS':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    amenities: state.selected.amenities.filter((el, key) => key !== action.data)
                }
            }






        case 'EDIT_AMENITIES':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    amenities: state.selected.amenities.map((el, key) => {
                        if(key === action.data.key){
                            el.amenitiesStr = action.data.text
                        }
                        return el;
                    })
                }
            }
        case 'EDIT_AMENITIES_TITLE':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    amenities: state.selected.amenities.map((el, key) => {
                        if(key === action.data.key){
                            el.name = action.data.text
                        }
                        return el;
                    })
                }
            }






        case 'GET_GALLERY_REQUEST':
            return {
                ...state,
                loadingGallery: true,
            }
        case 'GET_GALLERY_SUCCESS':
            return {
                ...state,
                loadingGallery: false,
                selected: {
                    ...state.selected,
                    gallery: action.data
                }
            }
        
        case 'MOVE_IMAGE':
            let gallery = state.selected.gallery[action.data.galleryIndex];
            const dragImage = gallery.photos[action.data.dragIndex];
            let photoList = gallery.photos.slice();
            photoList.splice(action.data.dragIndex, 1);
            photoList.splice(action.data.hoverIndex, 0, dragImage);
            return {
                ...state,
                selected: {
                    ...state.selected,
                    gallery: state.selected.gallery.map((el, key) => {
                        if(key === action.data.galleryIndex){
                            el = {
                                ...el,
                                photos: photoList
                            }
                            return el;
                        }else{
                            return el;
                        }
                    })
                }
            }
            

        case 'CREATE_GALLERY_CATEGORY':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    gallery: [...state.selected.gallery, {name: action.data, photos: []}]
                },
            }

        case 'ADD_NEW_IMAGE':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    gallery: state.selected.gallery.map(el => {
                        if(el.name === action.data.galleryName){
                            el.photos = [...el.photos, {id: Math.random(), photo: action.data.name, weight: el.photos.length, gallery_category_id: el.id}]
                        }
                        return el;
                    })
                }
            }
        
        case 'DELETE_IMAGE':
            return {
                ...state,
                selected: {
                    ...state.selected,
                    gallery: state.selected.gallery.map(el => {
                        if(el.id === action.data.gallery_category_id){
                            el.photos = el.photos.filter(photo => photo !== action.data);
                        }
                        return el;
                    })
                }
            }



        case 'RESET_ONBOARDING_SELECTED':
            return {
                ...state,
                selected: {
                    info: {},
                    settings: [],
                    specials: {
                        default: '',
                        list: []
                    },
                    floorplans: {
                        list: [],
                        options: {
                            ...state.selected.floorplans.options
                        }
                    },
                    appointments: [],
                    gallery: []
                },
            }
        default:
            return state;
    }
}