import { syncService } from '../_services';

function get() {
  return (dispatch) => {
    dispatch({ type: 'GET_SYNC_LIST_REQUEST' });
    syncService.get().then(
      (res) => {
        dispatch({ type: 'GET_SYNC_LIST_SUCCESS', data: res });
      },
      (error) => {
        dispatch({ type: 'GET_SYNC_LIST_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

function createOrUpdate(tid, action) {
  return (dispatch) => {
    dispatch({ type: 'SYNC_BOT_REQUEST' });

    syncService.create(tid, action).then(
      (res) => {
        dispatch({ type: 'SYNC_BOT_SUCCESS', tid });
        dispatch({ type: 'ALERT_SUCCESS', message: res.message });
      },
      (error) => {
        dispatch({ type: 'SYNC_BOT_FAILURE' });
        dispatch({ type: 'ALERT_ERROR', error });
      },
    );
  };
}

export const syncActions = {
  get,
  createOrUpdate,
};
